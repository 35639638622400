import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from '../../axios'
import { errorResponse } from '../../helpers/errorConfig'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import toastr from 'toastr'
import { connect } from 'react-redux'
import { responseWallet } from '../../store/wallet/action'
import SweetAlert from '../SweetAlert'
import 'toastr/build/toastr.min.css'
import { colors } from '../../helpers/color'
import { Upload, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class Topup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pin: false,
      postData: null,
      alert: false,
      userDetails: null,
      document: { upload: false, image: '' },
    }
  }
  handleSubmit = async (e, values) => {
    e.preventDefault()
    if (this.state.document.image === '') {
      message.error(`Please upload your voucher document`)
      return
    }

    this.setState({
      postData: values,
      alert: true,
    })
  }

  requestFunds = async () => {
    this.setState({
      loading: true,
    })
    try {
      await axios.post(
        `api/merchant/merchant-fund-documentation`,
        {
          image: this.state.document.image,
          amount: this.state.postData.amount * 100,
          remark: this.state.postData.remark,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      this.reset()
      toastr.success('Balance request sent successfully', 'Success')
      this.props.history.push('/request-history')
    } catch (e) {
      errorResponse(e)
    }
    this.setState({
      loading: false,
    })
  }

  reset = () => {
    this.form.reset()
    this.setState({
      pin: false,
      postData: null,
      alert: false,
      userDetails: null,
      document: { upload: false, image: '' },
    })
  }

  handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
      let temp = this.state.document
      temp.upload = true
      temp.image = info.file.response.filename
      this.setState({
        document: temp,
      })
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }
  render() {
    const onPreview = async (file) => {
      let src = file.url
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow.document.write(image.outerHTML)
    }

    const uploadButton = (type) => {
      return (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">{type}</div>
        </div>
      )
    }

    const img_props = {
      name: 'image',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'App-Authorizer': 647061697361,
      },
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Request Funds" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2" style={{ marginTop: 10 }}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleSubmit}
                          ref={(c) => (this.form = c)}
                        >
                          <div className="form-group">
                            <AvField
                              name="amount"
                              label="Amount"
                              className="form-control"
                              placeholder="Enter Amount to Request"
                              type="text"
                              disabled={this.state.postData ? true : false}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    'Please enter the amount to request funds',
                                },
                                min: {
                                  value: 10,
                                  errorMessage:
                                    'Amount to sent must be greater or equal to Rs. 10',
                                },
                                pattern: {
                                  value: '^[0-9]+$',
                                  errorMessage:
                                    'Amount must be a natural number',
                                },
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <AvField
                              name="remark"
                              label="Request Remarks"
                              className="form-control"
                              placeholder="Enter Request Remark"
                              type="text"
                              disabled={this.state.postData ? true : false}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter your remarks',
                                },
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <Upload
                              {...img_props}
                              onChange={(info) => this.handleChange(info)}
                              action={`${process.env.REACT_APP_API_URL}api/upload-image/user`}
                              listType={'picture-card'}
                              onRemove={() => {
                                let temp = this.state.document
                                temp.upload = false
                                temp.image = ''
                                this.setState({ document: temp })
                              }}
                              onPreview={onPreview}
                            >
                              {this.state.document.upload === false
                                ? uploadButton('Deposit Voucher')
                                : null}
                            </Upload>
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              style={{
                                backgroundImage:
                                  localStorage.getItem('agent') === 'ACCEPTED'
                                    ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                    : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                borderColor: '#ffff',
                              }}
                            >
                              {this.state.postData
                                ? 'Request Balance'
                                : 'Continue'}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
              </Col>
            </Row>
            {this.state.alert ? (
              <SweetAlert
                confirm={() => {
                  this.setState({
                    alert: false,
                  })
                  this.requestFunds()
                }}
                cancel={() => {
                  this.setState({
                    alert: false,
                  })
                }}
                message={`Request balance of Rs.${this.state.postData.amount} ?`}
              />
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    wallet: state.Wallet.wallet,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    responseWallet: () => dispatch(responseWallet()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topup)
