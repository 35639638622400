export const numbersOnly = (value) => {
  return /^\d+$/.test(value.toString())
}

export const phoneNumberValidation = value => {
  // return true;
  // In the following order: ncell -> ntcprepaid -> ntcpostpaid -> ntccdma -> utl -> smartcell
  return /^\b((98(0|1|2)[0-9]{7})|(9(7|8)(4|6)[0-9]{7})|(985[0-9]{7})|(97(4|5)[0-9]{7})|(9(88|(6(1|2)))[0-9]{7})|(972[0-9]{7}))\b$/.test(
    value.toString(),
  ); // -> utl |(972[0-9]{7})
};

export const emailValidation = (value) => {
  // return true;
  const result = /^"?[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*"?@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)(.[A-Za-z]{2})?\b$/.test(
    value.toString(),
  )
  //console.log("Email Validation Result: ", result);
  return result
}

export const passwordValidation = (value) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[\w\d\s#?!,.;:"'|`~=_/()@$%^&*+-]{6,}$/.test(
    value.toString(),
  )
}

export const singleNameValidation = (value) => {
  return /^[a-zA-Z]+$/.test(value.toString())
}
