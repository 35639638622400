// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

// Dashboard
import Dashboard from '../pages/Dashboard/index'
// import PhoneVerification from '../pages/PhoneVerification/index';
import EmailVerification from '../pages/EmailVerification/index'
// import Ebanking from '../pages/LoadFunds/Ebanking/index';
// import Mbanking from '../pages/LoadFunds/MobileBanking/index';
// import EnterAmount from '../pages/LoadFunds/EnterAmount/index';
//import Topup from '../pages/Topup/index';
import Settings from '../pages/Settings/index'
import KYC from '../pages/KYC/index'
import ChangePassword from '../pages/ChangePassword/index'
//import Referral from '../pages/Referral/index';
import Profile from '../pages/Profile/index'
import Invoice from '../pages/Invoice/index'
//import Landline from '../pages/Landline/index';
//import Vianet from '../pages/Vianet/index';
//import WebSurfer from '../pages/WebSurfer/index';
//import Adsl from '../pages/Adsl/index';
//import Wimax from '../pages/Wimax/index';
//import Ftth from '../pages/Ftth/index';
//import Arrownet from '../pages/Arrownet/index';
//import Sky from '../pages/Sky/index';
//import Subisu from '../pages/Subisu/index';
//import WorldLink from '../pages/WorldLink/index';
//import SimTv from '../pages/SimTv';
//import DishHome from '../pages/DishHome';
//import MeroTV from '../pages/MeroTV';
//import NepalWater from '../pages/NepalWater';
//import Khanepani from '../pages/Khanepani';
import SendFunds from '../pages/SendFunds'
import RequestFunds from '../pages/RequestFunds'
import RequestHistory from '../pages/RequestFunds/request_history'
import Transactions from '../pages/Transactions'
import SingleTransaction from '../pages/SingleTransaction'
//import NtcEpin from '../pages/NtcEpin';
//import SmartcellEpin from '../pages/SmartcellEpin';
//import DishHomeEpin from '../pages/DishHomeEpin';
//import NcellData from '../pages/NcellData';
//import Electricity from '../pages/Electricity';
import QRCode from '../pages/QRCode'
//import BounceCheck from '../pages/BounceCheck';
//import Notifications from '../pages/Notifications';
import BankDeposit from '../pages/BankDeposit'
import ConnectIps from '../pages/ConnectIps'
//import InternetPayments from '../pages/InternetPayments';
//import TVPayments from '../pages/TVPayments';
//import WaterPayments from '../pages/WaterPayments';
//import AgentApply from '../pages/AgentApply';
import LoadFunds from '../pages/LoadFunds/index'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  // { path: '/e-banking', component: Ebanking },
  // { path: '/m-banking', component: Mbanking },
  // { path: '/enterAmount/:bank', component: EnterAmount },
  //{ path: '/phone-verification', component: PhoneVerification },
  { path: '/email-verification', component: EmailVerification },
  { path: '/settings', component: Settings },
  { path: '/KYC', component: KYC },
  { path: '/change-password', component: ChangePassword },
  { path: '/profile', component: Profile },
  { path: '/invoice/:id', component: Invoice },
  { path: '/transactions', component: Transactions },
  { path: '/single-transaction/:id', component: SingleTransaction },
  { path: '/qr-code', component: QRCode },
  { path: '/bank-deposit', component: BankDeposit },
  { path: '/salary-distribution', component: SendFunds },
  { path: '/request-balance', component: RequestFunds },
  { path: '/request-history', component: RequestHistory },
  { path: '/connect-ips', component: ConnectIps },
  { path: '/load-funds', component: LoadFunds },
]

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
]

export { authProtectedRoutes, publicRoutes }
