import React, { Component } from 'react'
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from '../../axios'
// import images
import logoImg from '../../assets/images/i-cash-logo.svg'
import { phoneNumberValidation } from '../../helpers/validations'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class MobileRegister extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      phase: 0,
      error: null,
      success: null,
      mobile_no: null,
    }
  }

  // handleValidSubmit
  handleValidSubmit = async (e, values) => {
    e.preventDefault()
    this.setState({
      loading: true,
      error: null,
      success: null,
    })
    try {
      await axios.post(`api/otp/mobile/generate`, {
        mobile_no: values.mobile_no,
      })
      this.setState({
        success: 'An OTP has been sent to your mobile number.',
        phase: 1,
        mobile_no: values.mobile_no,
      })
      this.form.reset()
    } catch (e) {
      console.log(e)
      if (e.response.data && e.response.data.message) {
        this.setState({
          error: e.response.data.message,
        })
      } else {
        this.setState({
          error: 'Something went wrong, please try again later',
        })
      }
      console.log('e', e.response)
    }
    this.setState({
      loading: false,
    })
  }

  verifyOtp = async (e, values) => {
    e.preventDefault()
    this.setState({
      loading: true,
      success: null,
      error: null,
    })
    try {
      await axios.post('api/otp/mobile/validate', {
        code: values.otp_code,
        mobile_no: this.state.mobile_no,
      })
      this.setState({
        success: 'OTP Verified successfully',
      })
      this.props.completeMobileVerification(this.state.mobile_no)
    } catch (e) {
      console.log('e', e.response)
      if (e.response.data && e.response.data.message) {
        this.setState({
          error: e.response.data.message,
        })
      } else {
        this.setState({
          error: 'Password could not be changed, please try again later',
        })
      }
    }
    this.setState({
      loading: false,
    })
  }

  render() {
    const phoneValid = (value) => {
      if (phoneNumberValidation(value)) {
        return true
      } else {
        return 'Invalid phone number'
      }
    }
    return (
      <React.Fragment>
        <div className="account-pages my-3 pt-sm-3">
          <Container>
            <center>
              <a href="/">
                <img
                  src={logoImg}
                  alt={'icash_logo'}
                  style={{ marginBottom: 30, width: 160 }}
                />
              </a>
            </center>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Spin spinning={this.state.loading} indicator={antIcon}>
                  <Card className="overflow-hidden">
                    <CardBody>
                      <CardTitle>
                        <h4>Register</h4>
                        <p>
                          {this.state.phase === 0
                            ? 'Enter your mobile number to register.'
                            : 'Verify your OTP'}
                        </p>
                      </CardTitle>

                      <div className="p-2">
                        {this.state.error ? (
                          <Alert color="danger" style={{ marginTop: '13px' }}>
                            {this.state.error}
                          </Alert>
                        ) : null}
                        {this.state.success ? (
                          <Alert color="success" style={{ marginTop: '13px' }}>
                            {this.state.success}
                          </Alert>
                        ) : null}

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={
                            this.state.phase === 0
                              ? this.handleValidSubmit
                              : this.verifyOtp
                          }
                          ref={(c) => (this.form = c)}
                        >
                          {this.state.phase === 0 ? (
                            <div className="form-group">
                              <AvField
                                name="mobile_no"
                                label="Mobile Number"
                                type="text"
                                placeholder="Enter Mobile Number"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      'Please enter a Mobile Number',
                                  },
                                  pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage:
                                      'Mobile number must be composed only with numbers',
                                  },
                                  minLength: {
                                    value: 10,
                                    errorMessage:
                                      'Mobile number must be of 10 digits',
                                  },
                                  maxLength: {
                                    value: 10,
                                    errorMessage:
                                      'Mobile number must be of 10 digits',
                                  },
                                  phoneValid,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="form-group">
                              <AvField
                                name="otp_code"
                                label="OTP (One Time Password)"
                                type="text"
                                placeholder="Enter OTP"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please enter an OTP code',
                                  },
                                  pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage:
                                      'OTP must be composed only with numbers',
                                  },
                                }}
                              />
                            </div>
                          )}

                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                                style={{
                                  backgroundImage:
                                    'linear-gradient(to right,#006BCF,  #00D0F5 )',
                                  borderColor: '#ffff',
                                }}
                              >
                                {this.state.phase === 0
                                  ? 'Send OTP'
                                  : 'Verify OTP'}
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>

                <div className="mt-5 text-center">
                  <p>
                    Go back to{' '}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                    </Link>{' '}
                  </p>
                  <p>
                    © {new Date().getFullYear()} iCash
                    {/* Powered by{' '}
                                        <b>Galaxy International Pvt. Ltd.</b> */}
                    {/* <a
											href="http://silkinnovation.com.np"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#7967ad' }}
										>
											Silk Innovation
										</a> */}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(connect()(MobileRegister))
