import React, { Component } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col} from 'reactstrap';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { updateKYCForm } from '../../store/kyc/action';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      company_name: null,
      company_address: null,
      company_vat_pin_number: null,
      fileList: [],
      company_document: {
        upload: false,
        image: '',
      },
      company_logo: {
        upload: false,
        image: '',
      },
      company_vat_document: {
        upload: false,
        image: '',
      },
      company_tax_clearance_document: {
        upload: false,
        image: '',
      },
    };
  }

  handleChange = (info, type) => {
    if (info.file.status !== 'uploading') {
      console.log(info, 'uploading');
      console.log('uploading');
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      if (type === 'company_document') {
        let temp = this.state.company_document;
        temp.upload = true;
        temp.image = info.file.response.filename;
        this.setState({
          company_document: temp,
        });
      } else if (type === 'company_logo') {
        let temp = this.state.company_logo;
        temp.upload = true;
        temp.image = info.file.response.filename;
        this.setState({
          company_logo: temp,
        });
      } else if (type === 'company_vat_document') {
        let temp = this.state.company_vat_document;
        temp.upload = true;
        temp.image = info.file.response.filename;
        this.setState({
          company_vat_document: temp,
        });
      } else if (type === 'company_tax_clearance_document') {
        let temp = this.state.company_tax_clearance_document;
        temp.upload = true;
        temp.image = info.file.response.filename;
        this.setState({
          company_tax_clearance_document: temp,
        });
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      console.log('error');
    }
  };

  checkFile = (rule, value, callback) => {
    if (value && value.fileList.length === 0) {
      callback('Please Upload your Document!');
    } else {
      callback();
    }
  };

  handleSubmit = async (values) => {
	  console.log(values)
    if (
      this.state.company_document.image === '' ||
      this.state.company_logo.image === '' ||
      this.state.company_vat_document.image === ''
      // this.state.company_tax_clearance_document.image === ''
    ) {
      alert('Please Upload all of your files!');
    } else {
      values.company_document = this.state.company_document.image;
      values.company_logo = this.state.company_logo.image;
      values.company_vat_document = this.state.company_vat_document.image;
      values.company_tax_clearance_document = this.state.company_tax_clearance_document.image;
	  await this.props.updateKYCForm(values);
      let r = window.confirm('Submit your KYC?');
      if (r === true) {
        this.props.uploadKYC(values);
      }
    }
  };

  submit = (e, values) => {
    e.preventDefault();
    if (
      this.state.company_document.image === '' ||
      this.state.company_logo.image === '' ||
      this.state.company_vat_document.image === ''
    ) {
      if (this.state.company_document.image === '') {
        message.error(`Please upload your Company Document`);
      } else if (this.state.company_logo.image === '') {
        message.error(`Please upload the Company Logo`);
      } else if (this.state.company_vat_document.image === '') {
        message.error(`Please upload the Company Vat Document`);
      }
    } else {
      this.handleSubmit(values);
    }
  };

  render() {

    const props = {
      name: 'image',
      headers: {
		Authorization: 'Bearer ' + localStorage.getItem('access_token'),
		'App-Authorizer': 647061697361,
      },
    };
    const uploadButton = (type) => {
      return (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">{type}</div>
        </div>
      );
    };
    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };
    return (
      <div>
        <AvForm className="form-horizontal" onValidSubmit={this.submit}>
          <Row>
            <Col lg="4">
              <div>
                <AvField
                  name="company_name"
                  label="Company Name"
                  type="text"
                  value={localStorage.getItem('name')}
                  placeholder="Enter your Company Name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Company Name is required',
                    },
                    pattern: {
                      value: '^[a-zA-Z0-9 .]+$',
                      errorMessage:
                        'Invalid Company Name',
                    },
                  }}
                />
              </div>
            </Col>

            <Col lg="4">
              <div>
                <AvField
                  name="company_address"
                  label="Company Address"
                  type="text"
                  placeholder="Enter your Company Address"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Company Address is required',
                    },
                    pattern: {
                      value: '^[[a-zA-Z0-9 ,.-]+$',
                      errorMessage:
                        'Invalid Company Address',
                    },
                  }}
                />
              </div>
            </Col>
            <Col lg="4">
              <div>
                <AvField
                  name="company_vat_pin_number"
                  label="Company Vat/Pan Number"
                  type="text"
                  placeholder="Enter your Company Vat/Pan Number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please enter your Company Vat/Pan Number',
                    },
                    pattern: {
                      value: '^[0-9]+$',
                      errorMessage:
                        'Vat/Pan Number must be composed only with numbers',
                    },
                    maxLength: {
                      value: 10,
                      errorMessage: 'Vat/Pan Number must be of maximum 10 digits',
                    },
                  }}
                />
              </div>
            </Col>
          </Row>

          {/* images */}
          <Row>
            <Col lg="3">
              <center>
                <Upload
                  {...props}
                  onChange={(info) => this.handleChange(info, 'company_document')}
                  action={`${process.env.REACT_APP_API_URL}api/kyc/company-document`}
                  listType={'picture-card'}
                  onRemove={() => {
                    let temp = this.state.company_document;
                    temp.upload = false;
                    temp.image = '';
                    this.setState({
                      company_document: temp,
                    });
                  }}
                  onPreview={onPreview}
                >
                  {this.state.company_document.upload === false
                    ? uploadButton('Company Official Document')
                    : null}
                </Upload>
              </center>
            </Col>
            <Col lg="3">
              <center>
                <ImgCrop>
                  <Upload
                    {...props}
                    onChange={(info) => this.handleChange(info, 'company_logo')}
                    action={`${process.env.REACT_APP_API_URL}api/kyc/company-document`}
                    listType={'picture-card'}
                    onRemove={() => {
                      let temp = this.state.company_logo;
                      temp.upload = false;
                      temp.image = '';
                      this.setState({
                        company_logo: temp,
                      });
                    }}
                    onPreview={onPreview}
                  >
                    {this.state.company_logo.upload === false
                      ? uploadButton('Company Logo')
                      : null}
                  </Upload>
                </ImgCrop>
              </center>
            </Col>
            <Col lg="3">
              <center>
                <Upload
                  {...props}
                  onChange={(info) => this.handleChange(info, 'company_vat_document')}
                  action={`${process.env.REACT_APP_API_URL}api/kyc/company-document`}
                  listType={'picture-card'}
                  onRemove={() => {
                    let temp = this.state.company_vat_document;
                    temp.upload = false;
                    temp.image = '';
                    this.setState({
                      company_vat_document: temp,
                    });
                  }}
                  onPreview={onPreview}
                >
                  {this.state.company_vat_document.upload === false
                    ? uploadButton('Company Vat Document')
                    : null}
                </Upload>
              </center>
            </Col>
            <Col lg="3">
              <center>
                <Upload
                  {...props}
                  onChange={(info) => this.handleChange(info, 'company_tax_clearance_document')}
                  action={`${process.env.REACT_APP_API_URL}api/kyc/company-document`}
                  listType={'picture-card'}
                  onRemove={() => {
                    let temp = this.state.company_tax_clearance_document;
                    temp.upload = false;
                    temp.image = '';
                    this.setState({
                      company_tax_clearance_document: temp,
                    });
                  }}
                  onPreview={onPreview}
                >
                  {this.state.company_tax_clearance_document.upload === false
                    ? uploadButton('Company Tax Clearance Document (Optional)')
                    : null}
                </Upload>
              </center>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mt-4">
                <span
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={this.props.previous}
                  style={{
					backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
					borderColor: '#ffff',
				}}
                >
                  Previous
                </span>
              </div>
            </Col>
            <Col lg="6"></Col>
            <Col lg="3">
              <div className="mt-4">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
				  style={{
					backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
					borderColor: '#ffff',
				}}
                  type="submit"
                >
                  Submit KYC
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateKYCForm: (values) => dispatch(updateKYCForm(values)),
	};
};

export default connect('',mapDispatchToProps)(Company);
