import React, { Component } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Alert,
  CardTitle,
} from 'reactstrap'
import axios from '../../axios'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
// Redux
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'
// actions
import { loginUser } from '../../store/actions'
// import images
import logoImg from '../../assets/images/i-cash-logo.svg'

//validation helpers

import {
  emailValidation,
  phoneNumberValidation,
} from '../../helpers/validations'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      type: null,
      emailError: false,
    }
  }

  // handleValidSubmit
  handleValidSubmit = async (e, values) => {
    e.preventDefault()
    //this.props.loginUser(values, this.props.history);
    values.desktop_fcm = '123123'
    this.setState({
      loading: true,
    })
    let final
    if (emailValidation(values.email)) {
      if (values.password.length === 4 && !isNaN(values.password)) {
        final = {
          email: values.email,
          pin: values.password,
          desktop_fcm: '1231234',
        }
      } else {
        final = {
          email: values.email,
          password: values.password,
          desktop_fcm: '1231234',
        }
      }
    } else if (phoneNumberValidation(values.email)) {
      if (values.password.length === 4 && !isNaN(values.password)) {
        final = {
          mobile_no: values.email,
          pin: values.password,
          desktop_fcm: '1231234',
        }
      } else {
        final = {
          mobile_no: values.email,
          password: values.password,
          desktop_fcm: '1231234',
        }
      }
    }
    try {
      let login = await axios.post(`api/merchant/login`, final, {})
      console.log('login', login.data)
      this.props.loginUser(login.data, this.props.history)
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        this.setState({
          error: e.response.data.message,
        })
      } else {
        this.setState({
          error: 'Invalid login credentials',
        })
      }
    }
    this.setState({
      loading: false,
    })
  }

  render() {
    if (localStorage.getItem('access_token')) {
      return <Redirect to={'/dashboard'} />
    }
    const fieldChange = (value) => {
      if (emailValidation(value)) {
        return true
      } else if (phoneNumberValidation(value)) {
        return true
      } else {
        return 'Invalid email or phone'
      }
    }
    return (
      <React.Fragment>
        <div className="account-pages my-3 pt-sm-3">
          <Container>
            <center>
              <a href="/">
                <img
                  src={logoImg}
                  alt={'icash_logo'}
                  style={{ marginBottom: 30, width: 160 }}
                />
              </a>
            </center>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                  <Card className="overflow-hidden">
                    <CardBody>
                      <CardTitle>
                        <h4>Login to your Merchant account</h4>
                      </CardTitle>
                      <div className="p-2" style={{ marginTop: 30 }}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.state.error ? (
                            <Alert color="danger">{this.state.error}</Alert>
                          ) : null}
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email/ Phone Number"
                              className="form-control"
                              placeholder="Enter Email or Phone Number"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'This field is required',
                                },
                                fieldChange,
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password/ PIN"
                              type="password"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'This field is required',
                                },
                              }}
                              placeholder="Enter your Password or PIN"
                            />
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              style={{
                                backgroundImage:
                                  'linear-gradient(to right,#006BCF,  #00D0F5 )',
                                borderColor: '#ffff',
                              }}
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </Link>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
                <div className="mt-5 text-center">
                  <p>
                    Don't have an account ?{' '}
                    <Link
                      to="register"
                      className="font-weight-medium text-primary"
                    >
                      {' '}
                      Signup now{' '}
                    </Link>{' '}
                  </p>
                  <p>
                    © {new Date().getFullYear()} iCash
                    {/* . Powered by{' '}
										<b>Galaxy International Pvt. Ltd.</b> */}
                    {/* <a
											href="http://silkinnovation.com.np"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#7967ad' }}
										>
											Silk Innovation
										</a> */}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login
  return { error }
}

export default withRouter(connect(mapStatetoProps, { loginUser })(Login))
