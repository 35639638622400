import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import KYC from './kyc/reducer';
import Wallet from './wallet/reducer';
import Cashbacks from './cashbacks/reducer';
import Notifications from './notifications/reducer';

const rootReducer = combineReducers({
	// public
	Layout,
	Login,
	Account,
	ForgetPassword,
	KYC,
	Wallet,
	Cashbacks,
	Notifications
});

export default rootReducer;
