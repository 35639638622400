import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { colors } from '../../helpers/color';
export default class index extends Component {
	render() {
		return (
			<SweetAlert
				title="Are you sure?"
				warning
				onConfirm={this.props.confirm}
				onCancel={this.props.cancel}
				customButtons={
					<React.Fragment>
						<button
							className="btn btn-primary btn-block waves-effect waves-light"
							style={{
								backgroundImage:
									localStorage.getItem('agent') === 'ACCEPTED'
										? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
										: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
								borderColor: '#4aacaa',
								marginBottom: 10,
							}}
							onClick={this.props.confirm}
						>
							Confirm
						</button>
						<button
							className="btn btn-secondary btn-block waves-effect waves-light"
							onClick={this.props.cancel}
							autoFocus
						>
							Cancel
						</button>
					</React.Fragment>
				}
			>
				{this.props.message}
			</SweetAlert>
		);
	}
}
