export const vendorErrorData = {
  "001": "Some internal system error occurred",
  "002": "Some internal system error occurred",
  "003": "Request authentication failed",
  "004":
    "A time-out occurred while operation processing. Please check your internet connection and try again.",
  "005": "Too many consecutive requests. Try again later",
  "006": "Too many consecutive requests. Try again later",
  "007": "Service is temporarily unavailable. Please try again later.",
  "008": "Technical error, try again later",
  "009": "No account/invoice/subscriber found within service provider’s system",
  "010": "Too many consecutive requests. Try again later",
  "011": "Service is temporarily unavailable. Please try again later.",
  "012":
    "Connection problem with service provider occured. No response got for payment attempt.",
  "013": "The service is temporarily unavailable. Please try again later.",
  "014":
    "Request parameters did not pass validation. Please make sure all fields are filled.",
  "015":
    "The account/invoice/subscriber is not active within service provider’s system",
  "016": "The amount provided in the request is too low",
  "017": "The amount provided in the request is too high",
  "018": "The service is temporarily unavailable. Please try again later.",
  "019": "The service is temporarily unavailable. Please try again later.",
  "020": "Technical error, try again later",
  "021": "Technical error, try again later",
  "022": "Payment for the account and service specified is not allowed",
  "023":
    "The amount provided does not follow service provider's conditions. Invalid amount entered.",
  "024": "Technical error, try again later.",
  "025": "Technical error, try again later.",
  "026": "No account/invoice/subscriber found within service provider’s system",
  "027": "The service is temporarily locked",
  "028": "The service is not available for the moment. Please try again later.",
  "029": "Wrong salepointType value provided",
  "030": "The service is not available.",
  "037":
    "This service only allows one transaction per day. Please try again tomorrow.",
  "038":
    "This service only allows one transaction per day. Please try again tomorrow.",
  "048": "Service temporarily unavailable. Please try again later",
  "062": "Request authentication failed. Try again later.",
  "063": "Request authentication failed. Try again later.",
  "064": "You do not have any pending bills or vouchers are out of stock",
  "065": "Request authentication failed. Try again later.",
  "068": "Request authentication failed. Try again later.",
  "099":
    "The request is still being processed or no clear response returned by the service provider",
  "100": "Some critical error occurred",
  "101": "Service temporarily unavailable. Please try again later",
  "602":
    "Some critical error occurred on the service provider’s side. Please try again later.",
};

export const oneStepPayment = `api/topup/pp_one_step`;
export const twoStepPaymentCheck = `api/topup/pp_two_step_check`;
export const twoStepPaymentExecute = `api/topup/pp_two_step_execute`;
