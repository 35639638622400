import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Spin } from "antd";
import { connect } from "react-redux";
import axios from "../../axios";
import moment from "moment";
import { AvField, AvForm } from "availity-reactstrap-validation";

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: null,
      mobile: localStorage.getItem("mobile_no"),
      current_date: moment().format("YYYY-MM-DD"),
      loading: false,
    };
  }

  componentDidMount() {
    this.getWalletBalance();
  }

  getWalletBalance = () => {
    this.setState({
      loading: true,
    });
    let day =
      this.state.mobile === "9802011009"
        ? moment(this.state.current_date)
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        : this.state.current_date;
    axios
      .post(
        `api/merchant/balance/check?date=${day}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then(({ data }) => {
        this.setState({
          balance: data,
        });
      })
      .catch((e) => console.log("e", e))
      .finally(() =>
        this.setState({
          loading: false,
        })
      );
  };

  searchTransactions = async (e, values) => {
    e.persist();
    console.log("values", values);
    if (!values.date) {
      return;
    }

    this.setState({
      current_date: moment(values.date).format("YYYY-MM-DD"),
    });

    this.getWalletBalance();
  };

  render() {
    const { balance } = this.state;
    return (
      <>
        {balance ? (
          <Row>
            <Col md="12">
              <Card>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={this.searchTransactions}
                  ref={(c) => (this.form = c)}
                >
                  <Card outline className="border" style={{ padding: 20 }}>
                    {this.state.mobile === "9802011009" && (
                      <Row className="justify-content-center">
                        <Col lg="8">
                          <div>
                            <AvField
                              name="date"
                              label="Date"
                              type="date"
                              value={this.state.current_date}
                            />
                          </div>
                        </Col>
                        <Col lg="4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            style={{
                              backgroundImage:
                                "linear-gradient(to right,#006BCF,  #00D0F5 )",
                              borderColor: "#ffff",
                              marginTop: 30,
                            }}
                          >
                            Search
                          </button>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </AvForm>
                <Spin spinning={this.state.loading}>
                  <CardBody>
                    <CardTitle className="mb-4">iCash Wallet Balance</CardTitle>
                    <div className="table-responsive">
                      <table className="table table-nowrap table-centered mb-0">
                        <tbody>
                          <tr>
                            <th>
                              <h5 className="text-truncate font-size-14 m-0">
                                Main Balance
                              </h5>
                            </th>
                            <th>
                              <h5 className="text-truncate font-size-14 m-0">
                                Bonus Balance
                              </h5>
                            </th>
                            <th>
                              <h5 className="text-truncate font-size-14 m-0">
                                Total Balance
                              </h5>
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <h5>
                                Rs. {parseFloat(balance[0].balance).toFixed(2)}
                              </h5>
                            </td>
                            <td>
                              <h5>
                                Rs.{" "}
                                {parseFloat(balance[0].bonus_balance).toFixed(
                                  2
                                )}
                              </h5>
                            </td>
                            <td>
                              <h5>
                                Rs.{" "}
                                {(
                                  parseFloat(balance[0].balance) +
                                  parseFloat(balance[0].bonus_balance)
                                ).toFixed(2)}
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Spin>
              </Card>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}

export default connect()(Balance);
