const INIT_STATE = {
    cashbacks: [],
  };
  
  const Cashbacks = (state = INIT_STATE, action) => {
    switch (action.type) {
      case "GET_CASHBACKS":
        return {
          ...state,
          cashbacks: action.values,
        };
      default:
        return state;
    }
  };
  
  export default Cashbacks;
  