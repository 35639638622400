const INIT_STATE = {
  kyc: {},
};

const KYC = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPDATE_KYC_FORM":
      let temp = { ...state.kyc, ...action.values };
      return {
        ...state,
        kyc: temp,
      };
    default:
      return state;
  }
};

export default KYC;
