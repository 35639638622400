import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
// Import menuDropdown
import { Dropdown, DropdownToggle } from 'reactstrap';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
// import logo from '../../assets/images/icash_fav.png';
import logoLight from '../../assets/images/i-cash-logo.svg';
// import logoLightSvg from '../../assets/images/logo.svg';
// import logoDark from '../../assets/images/icash.svg';
import avatarImg from '../../assets/person.png';
// Redux Store
import { toggleRightSidebar } from '../../store/actions';

//i18n
import { withNamespaces } from 'react-i18next';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { isSearch: false, searchBox: false, found: false, list: [] };
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightbar = this.toggleRightbar.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);
	}

	/**
	 * Toggle sidebar
	 */
	toggleMenu() {
		this.props.openLeftMenuCallBack();
	}

	/**
	 * Toggles the sidebar
	 */
	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			/* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<header id="page-topbar" style={{ backgroundColor: '#f1f3f6' }}>
					<div className="navbar-header">
						<div className="d-flex">
							<div className="navbar-brand-box">
								<Link to="/" className="logo logo-dark">
									<span className="logo-sm">
										<img src={logoLight} alt="" height="50" />
									</span>
									<span className="logo-lg">
										<img src={logoLight} alt="" height="50" />
									</span>
								</Link>

								<Link to="/" className="logo logo-light">
									<span className="logo-sm">
										<img src={logoLight} alt="" height="50" />
									</span>
									<span className="logo-lg">
										<img src={logoLight} alt="" height="50" />
									</span>
								</Link>
							</div>
						</div>

						<div className="d-flex">
							<React.Fragment>
								<Dropdown className="d-inline-block">
									<DropdownToggle
										className="btn header-item waves-effect"
										id="page-header-user-dropdown"
										tag="button"
									>
										<span className="d-xl-inline-block ml-2 mr-1">
											<b style={{ color: '#555B6D' }}>Merchant Balance</b>
										</span>
										<br />
										<span className="d-xl-inline-block ml-2 mr-1" style={{ float: 'right' }}>
											<b style={{ color: '#555B6D' }}>Rs. {this.props.wallet}</b>
										</span>
									</DropdownToggle>
								</Dropdown>
							</React.Fragment>
							<ProfileMenu
								avatar={
									localStorage.getItem('avatar') !== 'null'
										? localStorage.getItem('avatar')
										: avatarImg
								}
								name={localStorage.getItem('name')}
							/>
						</div>
					</div>
				</header>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { layoutType } = state.Layout;
	return {
		layoutType,
		wallet: state.Wallet.wallet,
	};
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
