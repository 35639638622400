import axios from 'axios';
import store from '../store/index';
import { logoutUser } from '../store/auth/login/actions';

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
	baseURL: API_URL,
	headers: { Accept: 'application/json', 'App-Authorizer': 647061697361 },
});

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response.status && (error.response.status === 401 || error.response.status === 403)) {
		
			//localStorage.removeItem("access_token")
			await store.dispatch(logoutUser());
			//Notification("error", error.response.status, error.response.data.message);
		} else if (error.response.data.status && error.response.data.message && error.response.data.status === 7004) {
			Notification('error', error.response.data.status, error.response.data.message);
		}
		throw error;
	}
);
export default axiosInstance;
