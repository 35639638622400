import React, { Component } from "react";
import { Container, CardBody, Card, Row, Col } from "reactstrap";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import PrintComponent from "./PrintComponent";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux";

class PagesStarter extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="QR CODE" />
            <Row className="justify-content-center">
              <Col md={10} lg={10} xl={10}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <center>
                      <h4 style={{ marginTop: 10 }}>You Mobile Number is</h4>
                      <h5>{localStorage.getItem("mobile_no")}</h5>
                      <h6>Show your QR Code to accept payments</h6>
                      <hr />
                      <div className="p-2" style={{ marginTop: 10 }}>
                        <QRCode
                          value={`{"number":"${localStorage.getItem(
                            "mobile_no"
                          )}", "service":"iCash", "type":"merchant"}`}
                        />
                      </div>
                    </center>
                    <ReactToPrint
                      style={{ marginTop: 20 }}
                      trigger={() => (
                        <Link
                          to="#"
                          //onClick={this.printInvoice}
                          className="btn btn-success waves-effect waves-light mr-2"
                        >
                          <i className="fa fa-print"></i>
                        </Link>
                      )}
                      content={() => this.componentRef}
                    />
                    {/* <PrintComponents
											style={{ marginTop: 20 }}
											trigger={
												<Link
													to="#"
													//onClick={this.printInvoice}
													className="btn btn-success waves-effect waves-light mr-2"
												>
													<i className="fa fa-print"></i>
												</Link>
											}
										>
											
											<Row className="justify-content-center" style={{marginTop: 400}}>
												<Col md={10} lg={10} xl={10}>
													<Card className="overflow-hidden" style={{padding: 100}}>
														<CardBody className="pt-0">
															<center>
																<h4 style={{ marginTop: 10 }}>You Mobile Number is</h4>
																<h5>{localStorage.getItem('mobile_no')}</h5>
																<h6>Show your QR Code to accept payments</h6>
																<hr />
																<div className="p-2" style={{ marginTop: 10 }}>
																	<QRCode value={localStorage.getItem('mobile_no')} />
																</div>
															</center>
														</CardBody>
													</Card>
												</Col>
											</Row>
										</PrintComponents> */}
                    <div className="d-none">
                      <PrintComponent
                        user={this.props.user}
                        ref={(el) => (this.componentRef = el)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    user: state.Login.user,
  };
};

export default connect(mapStatetoProps)(PagesStarter);
