import React, { Component } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { updateKYCForm } from '../../store/kyc/action';

class Relationship extends Component {
	handleSubmit = (e, values) => {
		e.preventDefault();
		this.props.updateKYCForm(values);
		this.props.next();
	};
	render() {
		return (
			<AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
				<Row>
					<Col lg="6">
						<div>
							<AvField
								name="fathers_name"
								label="Father's Name"
								type="text"
								placeholder="Enter your Father's Name"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your Father's Name",
									},
									pattern: {
										value: '^[A-Za-z ]+$',
										errorMessage: "Father's Name should only contain alphabetical characters",
									},
								}}
							/>
						</div>
					</Col>
					<Col lg="6">
						<div>
							<AvField
								name="mothers_name"
								label="Mother's Name"
								type="text"
								placeholder="Enter your Mother's Name"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your Mother's Name",
									},
									pattern: {
										value: '^[A-Za-z ]+$',
										errorMessage: "Mother's Name should only contain alphabetical characters",
									},
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="6">
						<div>
							<AvField
								name="grand_fathers_name"
								label="Grand Father's Name"
								type="text"
								placeholder="Enter your Grand Father's Name"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter your Grand Father's Name",
									},
									pattern: {
										value: '^[A-Za-z ]+$',
										errorMessage: "Grand Father's Name should only contain alphabetical characters",
									},
								}}
							/>
						</div>
					</Col>
					<Col lg="6">
						<div>
							<AvField
								name="spouse_name"
								label="Spouse's Name (optional)"
								type="text"
								placeholder="Enter your Spouse's Name (optional)"
								validate={{
									pattern: {
										value: '^[A-Za-z ]+$',
										errorMessage: "Spouse's Name should only contain alphabetical characters",
									},
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="3">
						<div className="mt-4">
							<button
								className="btn btn-primary btn-block waves-effect waves-light"
								style={{
									backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
									borderColor: '#ffff',
								}}
								disabled={this.props.activeTabProgress === 1 ? true : false}
								onClick={this.props.previous}
							>
								Previous
							</button>
						</div>
					</Col>
					<Col lg="6"></Col>
					<Col lg="3">
						<div className="mt-4">
							<button
								className="btn btn-primary btn-block waves-effect waves-light"
								type="submit"
								style={{
									backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
									borderColor: '#ffff',
								}}
								disabled={this.props.activeTabProgress === 4 ? true : false}
							>
								Next
							</button>
						</div>
					</Col>
				</Row>
			</AvForm>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateKYCForm: (values) => dispatch(updateKYCForm(values)),
	};
};

export default connect('', mapDispatchToProps)(Relationship);
