import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert,
} from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from '../../axios'
import { errorResponse } from '../../helpers/errorConfig'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import toastr from 'toastr'
import { connect } from 'react-redux'
import { responseWallet } from '../../store/wallet/action'
import TransactionPin from '../TransactionPin'
import SweetAlert from '../SweetAlert'
import 'toastr/build/toastr.min.css'
import { colors } from '../../helpers/color'
import collection from '../../helpers/staticList/staticList'
import { withNamespaces } from 'react-i18next'

const purposeList = [
  {
    label: 'Bill Sharing',
    value: 'Bill Sharing',
  },
  {
    label: 'Family Expenses',
    value: 'Family Expenses',
  },
  {
    label: 'Lend or Borrow',
    value: 'Lend or Borrow',
  },
  {
    label: 'Personal Use',
    value: 'Personal Use',
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class Topup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pin: false,
      postData: null,
      alert: false,
      stage: 'check',
      checkData: null,
      packageIndex: null,
      bankList: collection.bankTransferList,
      checked: false,
      savedBanks: [],
      savedBank: null,
      savedNumber: null,
      amount: null,
      commission: null,
    }
  }

  async componentDidMount() {
    await this.getBankList()
    await this.savedBank()
    await this.setState({
      loading: false,
    })
  }

  getBankList = async () => {
    try {
      let list = await axios.post(
        `api/nchl-bank-list`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      this.setState({
        bankList: list.data,
      })
    } catch (e) {
      errorResponse(e)
    }
  }

  savedBank = async () => {
    try {
      let list = await axios.get(`api/user-bank-account`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      this.setState({
        savedBanks: list.data.data,
      })
    } catch (e) {
      errorResponse(e)
    }
  }

  handleSubmit = async (values) => {
    //e.preventDefault();
    this.setState({
      loading: true,
    })

    console.log('values', values)

    try {
      const bankFind = this.state.bankList.find(
        (bank) => parseInt(bank.bankId) === parseInt(values.bank_id),
      )
      console.log(values.bank_id, 'find')
      if (bankFind) {
        values.bank_name = bankFind.bankName
      }
      values.amount = parseFloat(values.amount) * 100

      const additional = [
        { bank_name: values.bank_name },
        { account_holder: values.account_name },
        { account_number: values.account_number },
        { utility_payment: 'Bank Transfer' },
      ]

      values.additional = additional

      let deposit = await axios.post(`api/nchl/process-bank-transfer`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      // if (this.state.checked) {
      //   let saveData = {
      //     bank_id: values.bank_id,
      //     account_name: values.account_name,
      //     account_number: values.account_number,
      //     bank_name: values.bank_name,
      //   }
      //   let save = await axios.post('api/user-bank-account', saveData, {
      //     headers: {
      //       Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      //     },
      //   })
      //   console.log('save', save.data)
      // }
      this.props.responseWallet()
      toastr.success('Bank Deposit Successful', 'Success')
      this.reset()
      console.log(deposit.data)
      this.props.history.push(
        '/single-transaction/' + deposit.data.transactionId,
      )
    } catch (e) {
      console.log('err', e)
      errorResponse(e)
    }
    this.setState({
      loading: false,
    })
  }

  reset = () => {
    this.form.reset()
    this.setState({
      pin: false,
      postData: null,
      alert: false,
      stage: 'check',
      checkData: null,
      packageIndex: null,
      checked: false,
      savedBank: null,
      savedNumber: null,
      savedName: null,
    })
  }

  checkPin = (result) => {
    if (result) {
      this.setState({
        pin: false,
      })
      this.handleSubmit(this.state.postData)
    }
  }

  submit = (e, values) => {
    e.preventDefault()
    if (localStorage.getItem('transaction_pin') === 'true') {
      this.setState({
        postData: values,
        pin: true,
      })
    } else {
      this.setState({
        alert: true,
        postData: values,
      })
    }
  }

  checked = async () => {
    await this.setState({
      checked: !this.state.checked,
    })
  }

  setSaved = async (index) => {
    await this.reset()
    await this.setState({
      loading: true,
    })
    await this.setState({
      savedBank: this.state.savedBanks[index].bank_id,
      savedNumber: this.state.savedBanks[index].account_number,
      savedName: this.state.savedBanks[index].account_name,
      loading: false,
    })
  }

  amountChange = (value) => {
    this.setState({
      amount: value,
    })
    if (!isNaN(value) && parseFloat(value) >= 10) {
      let temp = this.props.bank_commission
      temp &&
        temp.forEach((com, index) => {
          if (index === 0) {
            if (parseFloat(value) <= com.to / 100) {
              this.setState({
                commission: parseFloat(com.amount) / 100,
              })
            }
          } else if (index === temp.length - 1) {
            if (parseFloat(value) > com.from / 100) {
              this.setState({
                commission: parseFloat(com.amount) / 100,
              })
            }
          } else {
            if (
              parseFloat(value) > com.from / 100 &&
              parseFloat(value) <= com.to / 100
            ) {
              this.setState({
                commission: parseFloat(com.amount) / 100,
              })
            }
          }
        })
    } else {
      this.setState({
        commission: null,
      })
    }
  }

  render() {
    const selectValid = (value) => {
      if (value === 'invalid') {
        return this.props.t('This field is required')
      } else {
        return true
      }
    }

    let bankList =
      this.state.bankList &&
      this.state.bankList.map((bank) => {
        return <option value={bank.bankId}>{bank.bankName}</option>
      })

    let saved =
      this.state.savedBanks &&
      this.state.savedBanks.map((saved, index) => {
        return (
          <tr>
            <td>
              <h5>{index + 1}.</h5>
            </td>
            <td>
              <h5 className="text-truncate font-size-14 m-0">
                {this.props.t('Account Name')}: {saved.account_name}{' '}
              </h5>
              <h5 className="text-truncate font-size-14 m-0">
                {this.props.t('Account Number')}: {saved.account_number}{' '}
              </h5>
              <h5 className="text-truncate font-size-14 m-0">
                {this.props.t('Bank Name')}: {saved.bank_name}{' '}
              </h5>
            </td>
            <td>
              <div className="text-center">
                <button
                  className="btn badge badge-pill badge-soft-success font-size-13"
                  style={{
                    backgroundImage:
                      localStorage.getItem('agent') === 'ACCEPTED'
                        ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                        : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                    borderColor: '#ffff',
                    color: '#ffff',
                  }}
                  onClick={() => {
                    this.setSaved(index)
                  }}
                >
                  {this.props.t('Select')}
                </button>
              </div>
            </td>
          </tr>
        )
      })

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t('Home')}
              breadcrumbItem={this.props.t('Bank Deposit')}
            />

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Alert color="info" role="alert">
                  <center>
                    <h3>Rs. {this.props.wallet}</h3>
                    {this.props.t(
                      '*only iCash balance is available for Bank Deposit.',
                    )}
                  </center>
                </Alert>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2" style={{ marginTop: 10 }}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.submit}
                          ref={(c) => (this.form = c)}
                        >
                          <div className="form-group">
                            <AvField
                              type="select"
                              name="bank_id"
                              label={this.props.t('Select Bank')}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.props.t(
                                    'Please select your bank',
                                  ),
                                },
                                selectValid,
                              }}
                              helpMessage="Select your bank"
                              value={this.state.savedBank}
                            >
                              <option value="invalid">
                                {this.props.t('Select a Bank')}
                              </option>
                              {bankList}
                            </AvField>
                          </div>
                          <div className="form-group">
                            <AvField
                              name="account_name"
                              label={this.props.t("Account Holder's Name")}
                              type="text"
                              placeholder={this.props.t(
                                "Account Holder's Name",
                              )}
                              value={this.state.savedName}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.props.t(
                                    'Please enter your Account Name',
                                  ),
                                },
                                // pattern: {
                                //   value: '^[A-Za-z ]+$',
                                //   errorMessage: this.props.t(
                                //     "Account Holder's Name should only contain alphabetical characters",
                                //   ),
                                // },
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="account_number"
                              label={this.props.t('Account Number')}
                              type="text"
                              placeholder={this.props.t('Account Number')}
                              value={this.state.savedNumber}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.props.t(
                                    'Please enter your Account Number',
                                  ),
                                },
                                pattern: {
                                  value: '^[A-Za-z0-9]+$',
                                  errorMessage: this.props.t(
                                    'Account Numbers should only contain alphabetical characters',
                                  ),
                                },
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="amount"
                              label={this.props.t('Amount')}
                              className="form-control"
                              placeholder={this.props.t(
                                'Enter Amount to Deposit',
                              )}
                              type="text"
                              onChange={(e) =>
                                this.amountChange(e.target.value)
                              }
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.props.t(
                                    'Please enter the amount to be deposited',
                                  ),
                                },
                                min: {
                                  value: 10,
                                  errorMessage: this.props.t(
                                    'Amount to be deposited must be greater or equal to Rs. 10',
                                  ),
                                },
                                max: {
                                  value: this.props.wallet,
                                  errorMessage: this.props.t(
                                    'Your amount must be less or equal to your wallet balance',
                                  ),
                                },
                                pattern: {
                                  value: '^[0-9.]+$',
                                  errorMessage: this.props.t(
                                    'Amount must be a natural number',
                                  ),
                                },
                              }}
                              helpMessage={this.props.t(
                                'Enter the Amount to deposit',
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              type="select"
                              name="purpose"
                              label={this.props.t('Purpose')}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: this.props.t(
                                    'Please select your purpose',
                                  ),
                                },
                                selectValid,
                              }}
                            >
                              <option value="invalid">
                                {this.props.t('Select a Purpose')}
                              </option>
                              {purposeList.map((pur) => (
                                <option value={pur.value}>{pur.label}</option>
                              ))}
                            </AvField>
                          </div>

                          <div className="form-group">
                            <AvField
                              name="remark"
                              label="Remarks"
                              className="form-control"
                              placeholder="Enter your Remark"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter your remark',
                                },
                              }}
                            />
                          </div>

                          {/* <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={this.state.checked}
                              onChange={this.checked}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              {this.props.t('Save banking details ?')}
                            </label>
                          </div> */}
                          {this.state.commission ? (
                            <div className="mt-3">
                              <Alert
                                color={
                                  localStorage.getItem('agent') === 'ACCEPTED'
                                    ? 'danger'
                                    : 'info'
                                }
                              >
                                {this.props.t('Deposit Amount')}: Rs.
                                {this.state.amount}
                                <br />
                                {this.props.t('Service Charge')}: Rs.{' '}
                                {this.state.commission}
                                <br />
                                <hr />
                                {this.props.t('Total Paying Amount')}: Rs.{' '}
                                {parseFloat(this.state.amount) +
                                  parseFloat(this.state.commission)}
                              </Alert>
                            </div>
                          ) : null}

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              style={{
                                backgroundImage:
                                  localStorage.getItem('agent') === 'ACCEPTED'
                                    ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                    : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                borderColor: '#ffff',
                              }}
                            >
                              {this.props.t('Make Deposit')}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
              </Col>
              <Col md={8} lg={6} xl={5}>
                <Card className="border">
                  <CardBody>
                    <CardTitle className="mb-4">
                      {this.props.t('My Saved Banks')}
                    </CardTitle>
                    <div className="table-responsive">
                      <table className="table table-nowrap table-centered mb-0">
                        <tbody>
                          {this.state.savedBanks.length === 0 ? (
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  {this.props.t(
                                    'You have not saved any Bank Account details yet.',
                                  )}
                                </h5>
                              </td>
                            </tr>
                          ) : (
                            saved
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <TransactionPin
              visible={this.state.pin}
              closeModal={() => {
                this.setState({
                  pin: false,
                })
              }}
              checkPin={this.checkPin}
            />
            {this.state.alert ? (
              <SweetAlert
                confirm={() => {
                  this.setState({
                    alert: false,
                  })
                  this.handleSubmit(this.state.postData)
                }}
                cancel={() => {
                  this.setState({
                    alert: false,
                  })
                }}
                message={`Are you sure you want to make a deposit of Rs.${this.state.postData.amount}?`}
              />
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    wallet: state.Wallet.wallet,
    bank_commission: state.Login.user.bank_transfer_commission,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    responseWallet: () => dispatch(responseWallet()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(Topup))
