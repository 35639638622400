import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
//Import images
//import avatar3 from '../../../assets/images/users/avatar-3.jpg';
//import avatar4 from '../../../assets/images/users/avatar-4.jpg';
import moment from 'moment';
//i18n
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import axios from '../../../axios';
import { errorResponse } from '../../../helpers/errorConfig';
import { responseWallet } from '../../../store/wallet/action';
import { colors } from '../../../helpers/color';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class NotificationDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			notifications: this.props.notifications,
			loading: false,
			balanceError: null,
			errorIndex: null,
		};
		this.toggle = this.toggle.bind(this);
	}

	componentWillReceiveProps(props) {
		this.setState({
			notifications: props.notifications,
		});
	}

	rejectRequest = async (id, actionId, index) => {
		this.setState({
			loading: true,
		});
		try {
			await axios.post(
				`api/notification/${id}/${actionId}/reject`,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				}
			);
			let temp = this.state.notifications;
			temp.splice(index, 1);
			this.setState({
				notifications: temp,
				errorIndex: null,
				balanceError: null,
			});
		} catch (e) {
			errorResponse(e);
		}
		this.setState({
			loading: false,
		});
	};

	acceptRequest = async (id, actionId, index) => {
		this.setState({
			loading: true,
		});
		try {
			await axios.post(
				`api/notification/${id}/${actionId}/accept`,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				}
			);
			let temp = this.state.notifications;
			temp.splice(index, 1);
			this.setState({
				notifications: temp,
				errorIndex: null,
				balanceError: null,
			});
			this.props.responseWallet();
		} catch (e) {
			errorResponse(e);
		}
		this.setState({
			loading: false,
		});
	};

	balanceError = (index) => {
		this.setState({
			balanceError: 'You do not have sufficient funds to complete this transaction.',
			errorIndex: index,
		});
	};

	toggle() {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	}
	render() {
		let temp = this.state.notifications.slice(0, 4);
		let nots =
			temp &&
			temp.map((nots, index) => {
				return (
					<Link to="/notifications" className="text-reset notification-item">
						<div className="media">
							<div className="avatar-xs mr-3">
								<span
									className={
										localStorage.getItem('agent') === 'ACCEPTED'
											? 'avatar-title bg-danger rounded-circle font-size-16'
											: 'avatar-title bg-primary rounded-circle font-size-16'
									}
								>
									<i className="bx bx-cart"></i>
								</span>
							</div>
							<div className="media-body">
								<h6 className="mt-0 mb-1">{nots.data.title}</h6>
								<div className="font-size-12 text-muted">
									<p className="mb-1">{nots.data.description}</p>
									{nots.data.actionId ? (
										<p className="mb-1">
											<button
												className="btn btn-primary btn-block waves-effect waves-light"
												style={{
													backgroundImage:
														localStorage.getItem('agent') === 'ACCEPTED'
															? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
															: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
													borderColor: '#ffff',
													width: '30%',
												}}
												onClick={(e) => {
													e.preventDefault();
													if (parseFloat(nots.data.amount) > this.props.wallet) {
														this.balanceError(index);
													} else {
														this.acceptRequest(nots.id, nots.data.actionId, index);
													}
												}}
											>
												Accept
											</button>
											&nbsp;&nbsp;
											<button
												className="btn btn-secondary btn-block waves-effect waves-light"
												style={{
													width: '30%',
													marginTop: 'auto',
												}}
												onClick={(e) => {
													e.preventDefault();
													this.rejectRequest(nots.id, nots.data.actionId, index);
												}}
											>
												Reject
											</button>
										</p>
									) : null}

									<p className="mb-0">
										<i className="mdi mdi-clock-outline"></i> {moment(nots.createdAt).fromNow()}{' '}
									</p>
									{this.state.balanceError && index === this.state.errorIndex ? (
										<p className="mb-0">
											<b style={{ color: 'red' }}>{this.state.balanceError}</b>
										</p>
									) : null}
								</div>
							</div>
						</div>
					</Link>
				);
			});

		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="dropdown d-inline-block" tag="li">
					<DropdownToggle
						className="btn header-item noti-icon waves-effect"
						tag="button"
						id="page-header-notifications-dropdown"
					>
						<i
							className={this.props.unread_count === 0 ? 'bx bx-bell' : 'bx bx-bell bx-tada'}
							style={{ color: '#555B6D' }}
						></i>
						<span className="badge badge-danger badge-pill">{this.props.unread_count}</span>
					</DropdownToggle>

					<DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
						<div className="p-3">
							<Row className="align-items-center">
								<Col>
									<h6 className="m-0"> {this.props.t('Notifications')} </h6>
								</Col>
								<div className="col-auto">
									<a
										href="/notifications"
										className="small"
										style={{
											color:
												localStorage.getItem('agent') === 'ACCEPTED'
													? colors.agent
													: colors.blue,
										}}
									>
										{' '}
										View All Notifications
									</a>
								</div>
							</Row>
						</div>
						<Spin spinning={this.state.loading} indicator={antIcon}>
							<SimpleBar style={{ height: '230px' }}>{nots}</SimpleBar>
						</Spin>
						<div className="p-2 border-top">
							<Link
								className="btn btn-sm btn-link font-size-14 btn-block text-center"
								to="/notifications"
								style={{
									color: localStorage.getItem('agent') === 'ACCEPTED' ? colors.agent : colors.blue,
								}}
							>
								{' '}
								View All Notifications
							</Link>
						</div>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		notifications: state.Notifications.notifications,
		wallet: state.Wallet.wallet,
		unread_count: state.Notifications.unread_count,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		responseWallet: () => dispatch(responseWallet()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(NotificationDropdown));
