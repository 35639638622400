import React, { Component } from 'react'

import { Row, Col, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import avatarImg from '../../assets/person.png'
import { colors } from '../../helpers/color'
class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    //console.log("user", this.props);
    return (
      <React.Fragment>
        <Card
          className="overflow-hidden"
          style={{ height: this.props.page === 'dashboard' ? '95%' : null }}
        >
          <div className="bg-soft-primary">
            <Row>
              <Col
                xs="12"
                style={{
                  backgroundImage:
                    localStorage.getItem('agent') === 'ACCEPTED'
                      ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                      : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                }}
              >
                <div className="text-primary p-3">
                  <h5 style={{ color: 'white' }}>
                    Welcome Back{' '}
                    {localStorage.getItem('name').replace(/ .*/, '')}
                  </h5>
                  <p style={{ color: 'white' }}>iCash Merchant</p>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="12">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    className="rounded-circle header-profile-user"
                    src={
                      localStorage.getItem('avatar') !== 'null'
                        ? localStorage.getItem('avatar')
                        : avatarImg
                    }
                    alt="Header Avatar"
                    style={{ width: 70, height: 70 }}
                  />
                </div>
                <h5 className="font-size-15 text-truncate">
                  {localStorage.getItem('name')}
                </h5>
                <p className="text-muted mb-0 text-truncate">
                  {localStorage.getItem('mobile_no')}
                </p>
                <p className="text-muted mb-0 text-truncate">
                  {localStorage.getItem('email')}
                </p>
              </Col>

              <Col sm="12">
                <div className="pt-4">
                  <Row>
                    <Col xs="6" style={{ marginBottom: 10 }}>
                      <h5 className="font-size-12">Wallet Balance</h5>
                      <p className="text-muted mb-0">Rs. {this.props.wallet}</p>
                    </Col>
                    <Col xs="6">
                      <h5 className="font-size-12">KYC Status</h5>
                      <p className="text-muted mb-0">
                        {!localStorage.getItem('kyc_status')
                          ? 'INCOMPLETE'
                          : localStorage.getItem('kyc_status')}
                      </p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link
                      to={
                        this.props.page === 'dashboard'
                          ? '/profile'
                          : this.props.page === 'profile'
                          ? '/settings'
                          : null
                      }
                      className="btn btn-primary waves-effect waves-light btn-sm"
                      style={{
                        backgroundImage:
                          localStorage.getItem('agent') === 'ACCEPTED'
                            ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                            : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                        border: '#4aacaa',
                      }}
                    >
                      {this.props.page === 'dashboard'
                        ? 'View Profile'
                        : this.props.page === 'profile'
                        ? 'View Settings'
                        : null}
                      <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    wallet: state.Wallet.wallet,
  }
}

export default connect(mapStateToProps)(WelcomeComp)
