import React, { Component } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { updateKYCForm } from '../../store/kyc/action';

const adbs = require('ad-bs-converter');

class Documents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bs_year: null,
			bs_month: null,
			bs_day: null,
			date_of_birth: null,
			fileList: [],
			passport: {
				upload: false,
				image: '',
			},
			front: {
				upload: false,
				image: '',
			},
			back: {
				upload: false,
				image: '',
			},
		};
	}

	setNepaliDate = (e) => {
		const { value } = e.target;

		if (moment(value).get('year') > 1900) {
			let nepaliDate = adbs.ad2bs(moment(value).format('YYYY/MM/DD')).en;
			console.log('nepali_date', nepaliDate);
			this.setState({
				bs_year: nepaliDate.year,
				bs_month: nepaliDate.month,
				bs_day: nepaliDate.day,
			});
		} else {
			this.setState({
				bs_year: null,
				bs_month: null,
				bs_day: null,
			});
		}
	};
	bsChange = async (e) => {
		const { name, value } = e.target;
		await this.setState({
			[name]: value,
		});
		if (this.state.bs_year !== null && this.state.bs_month !== null && this.state.bs_day !== null) {
			let englishDate = adbs.bs2ad(
				moment(this.state.bs_year + '/' + this.state.bs_month + '/' + this.state.bs_day).format('YYYY/MM/DD')
			);
			//console.log('english', englishDate)
			let month = null;
			let day = null;
			if (englishDate.month.toString().length === 1) {
				month = '0' + englishDate.month;
			} else {
				month = englishDate.month;
			}
			if (englishDate.day.toString().length === 1) {
				day = '0' + englishDate.day;
			} else {
				day = englishDate.day;
			}
			let finalEnglish = englishDate.year + '-' + month + '-' + day;
			this.setState({
				date_of_birth: moment(finalEnglish)._i,
			});
		}
	};

	yearLoop = () => {
		let year = [];
		for (let i = 1990; i < 2080; i++) {
			year.push(<option value={i}>{i}</option>);
		}
		return year;
	};
	dayLoop = () => {
		let day = [];
		for (let i = 1; i < 33; i++) {
			day.push(<option value={i}>{i}</option>);
		}
		return day;
	};

	handleChange = (info, type) => {
		if (info.file.status !== 'uploading') {
			console.log(info, 'uploading');
			console.log('uploading');
		}
		if (info.file.status === 'done') {
			message.success(`${info.file.name} file uploaded successfully`);
			if (type === 'passport') {
				let temp = this.state.passport;
				temp.upload = true;
				temp.image = info.file.response.filename;
				this.setState({
					passport: temp,
				});
			} else if (type === 'front') {
				let temp = this.state.front;
				temp.upload = true;
				temp.image = info.file.response.filename;
				this.setState({
					front: temp,
				});
			} else if (type === 'back') {
				let temp = this.state.back;
				temp.upload = true;
				temp.image = info.file.response.filename;
				this.setState({
					back: temp,
				});
			}
		} else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
			console.log('error');
		}
	};

	checkImage = (rule, value, callback) => {
		if (value && value.fileList.length === 0) {
			callback('Please Upload your Document!');
		} else {
			callback();
		}
	};

	handleSubmit = async (e, values) => {
		e.preventDefault();
		if (this.state.passport.image === '' || this.state.front.image === '' || this.state.back.image === '') {
			alert('Please Upload all of your images!');
		} else {
			let send = {
				documentation_type: values.documentation_type,
				id_no: values.id_no,
				c_issued_from: values.c_issued_from,
				c_issued_date: values.c_issued_date,
				p_photo: this.state.passport.image,
				id_photo_front: this.state.front.image,
				id_photo_back: this.state.back.image,
			};

			await this.props.updateKYCForm(send);
			await this.props.next();
		}
	};

	render() {
		const selectValid = (value) => {
			if (value === '0') {
				return 'This field is required';
			} else {
				return true;
			}
		};

		const props = {
			name: 'image',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				'App-Authorizer': 647061697361,
			},
		};
		const uploadButton = (type) => {
			return (
				<div>
					<PlusOutlined />
					<div className="ant-upload-text">{type}</div>
				</div>
			);
		};
		return (
			<AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
				<Row>
					<Col lg="4">
						<div>
							<AvField
								type="select"
								name="documentation_type"
								label="Document Type"
								validate={{
									required: {
										value: true,
										errorMessage: 'Please select your Document Type',
									},
									selectValid,
								}}
							>
								<option value={0}>Select Document Type</option>
								<option value={'c'}>Citizenship</option>
								<option value={'p'}>Passport</option>
								<option value={'l'}>Driving Liscence</option>
							</AvField>
						</div>
					</Col>

					<Col lg="4">
						<div>
							<AvField
								name="id_no"
								label="Document Number"
								type="text"
								placeholder="Enter your Document Number"
								validate={{
									required: {
										value: true,
										errorMessage: 'Document Number is required',
									},
								}}
							/>
						</div>
					</Col>
					<Col lg="4">
						<div>
							<AvField
								name="c_issued_from"
								label="Document Issued Place"
								type="text"
								placeholder="Enter your Document Issued Place"
								validate={{
									required: {
										value: true,
										errorMessage: 'Please enter your Document Issued Place',
									},
									pattern: {
										value: '^[A-Za-z ]+$',
										errorMessage:
											'Document Issued Place should only contain alphabetical characters',
									},
								}}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col lg="3">
						<div>
							<AvField
								name="c_issued_date"
								label="Document Issued Date (A.D)"
								type="date"
								validate={{
									required: {
										value: true,
										errorMessage: 'Document Issued Date is required',
									},
								}}
								onChange={this.setNepaliDate}
								value={this.state.date_of_birth}
							/>
						</div>
					</Col>
					{/* english date */}

					<Col lg="3">
						<div>
							<AvField
								type="select"
								name="bs_year"
								label="Year (B.S)"
								validate={{
									required: {
										value: true,
										errorMessage: 'Please select your B.S Year',
									},
									selectValid,
								}}
								onChange={this.bsChange}
								value={this.state.bs_year}
							>
								<option value={0}>Select Year</option>
								{this.yearLoop()}
							</AvField>
						</div>
					</Col>
					<Col lg="3">
						<div>
							<AvField
								type="select"
								name="bs_month"
								label="Month (B.S)"
								validate={{
									required: {
										value: true,
										errorMessage: 'Please select your B.S month',
									},
									selectValid,
								}}
								onChange={this.bsChange}
								value={this.state.bs_month}
							>
								<option value={0}>Select Month</option>
								<option value={1}>Baisakh</option>
								<option value={2}>Jesth</option>
								<option value={3}>Ashad</option>
								<option value={4}>Shrawan</option>
								<option value={5}>Bhadra</option>
								<option value={6}>Ashoj</option>
								<option value={7}>Kartik</option>
								<option value={8}>Mangsir</option>
								<option value={9}>Poush</option>
								<option value={10}>Magh</option>
								<option value={11}>Falgun</option>
								<option value={12}>Chaitra</option>
							</AvField>
						</div>
					</Col>
					<Col lg="3">
						<div>
							<AvField
								type="select"
								name="bs_day"
								label="Date (B.S)"
								validate={{
									required: {
										value: true,
										errorMessage: 'Please select your B.S date',
									},
									selectValid,
								}}
								onChange={this.bsChange}
								value={this.state.bs_day}
							>
								<option value={0}>Select Day</option>
								{this.dayLoop()}
							</AvField>
						</div>
					</Col>
				</Row>

				{/* images */}
				<Row>
					<Col lg="2"></Col>
					<Col lg="3">
						<center>
							<ImgCrop>
								<Upload
									{...props}
									onChange={(info) => this.handleChange(info, 'passport')}
									action={`${process.env.REACT_APP_API_URL}api/kyc/photo`}
									listType={'picture-card'}
									onRemove={() => {
										let temp = this.state.passport;
										temp.upload = false;
										temp.image = '';
										this.setState({
											passport: temp,
										});
									}}
								>
									{this.state.passport.upload === false ? uploadButton('Passport Sized Photo') : null}
								</Upload>
							</ImgCrop>
						</center>
					</Col>
					<Col lg="3">
						<Upload
							{...props}
							onChange={(info) => this.handleChange(info, 'front')}
							action={`${process.env.REACT_APP_API_URL}api/kyc/front-image`}
							listType={'picture-card'}
							onRemove={() => {
								let temp = this.state.front;
								temp.upload = false;
								temp.image = '';
								this.setState({
									front: temp,
								});
							}}
						>
							{this.state.front.upload === false ? uploadButton('Document Front Photo') : null}
						</Upload>
					</Col>
					<Col lg="3">
						<Upload
							{...props}
							onChange={(info) => this.handleChange(info, 'back')}
							action={`${process.env.REACT_APP_API_URL}api/kyc/back-image`}
							listType={'picture-card'}
							onRemove={() => {
								let temp = this.state.back;
								temp.upload = false;
								temp.image = '';
								this.setState({
									back: temp,
								});
							}}
						>
							{this.state.back.upload === false ? uploadButton('Document Back Photo') : null}
						</Upload>
					</Col>
				</Row>
				<Row>
					<Col lg="3">
						<div className="mt-4">
							<button
								className="btn btn-primary btn-block waves-effect waves-light"
								style={{
									backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
									borderColor: '#ffff',
								}}
								disabled={this.props.activeTabProgress === 1 ? true : false}
								onClick={this.props.previous}
							>
								Previous
							</button>
						</div>
					</Col>
					<Col lg="6"></Col>
					<Col lg="3">
						<div className="mt-4">
							<button
								className="btn btn-primary btn-block waves-effect waves-light"
								type="submit"
								style={{
									backgroundImage: 'linear-gradient(to right,#006BCF,  #00D0F5 )',
									borderColor: '#ffff',
								}}
							>
								Next
							</button>
						</div>
					</Col>
				</Row>
			</AvForm>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateKYCForm: (values) => dispatch(updateKYCForm(values)),
	};
};

export default connect('', mapDispatchToProps)(Documents);
