import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
// import ebank from '../../assets/icons/online-banking.png'
// import mobile from '../../assets/icons/mobile_banking.png'
import ips from '../../assets/icons/connection.png'

class EnterAmount extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Load Funds" />

            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-2" style={{ marginTop: 10 }}>
                      <Row className="no-gutters">
                        {/* <Col md="2">
                          <Link
                            className="dropdown-icon-item"
                            to={'/e-banking'}
                          >
                            <img
                              src={ebank}
                              alt="adsl"
                              style={{ height: 50 }}
                            />
                            <span>E-Banking</span>
                          </Link>
                        </Col>
                        <Col md="2">
                          <Link
                            className="dropdown-icon-item"
                            to={'/m-banking'}
                          >
                            <img
                              src={mobile}
                              alt="adsl"
                              style={{ height: 50 }}
                            />
                            <span>Mobile Banking</span>
                          </Link>
                        </Col> */}
                        <Col md="2">
                          <Link
                            className="dropdown-icon-item"
                            to={'/connect-ips'}
                          >
                            <img src={ips} alt="adsl" style={{ height: 50 }} />
                            <span>Connect IPS</span>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default EnterAmount
