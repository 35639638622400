const bankListLive = [
    {
        "name": "Agricultural Development Bank Limited",
        "code": "EADBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S15.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Citizens Bank",
        "code": "ECITIZEN",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S81.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Civil Bank",
        "code": "ECIVIL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241825S92.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Global IME Bank",
        "code": "EGIBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302124S33.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Jyoti Bikash Bank",
        "code": "EJBBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S85.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Kumari Bank",
        "code": "EKUMARI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S57.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Laxmi Bank",
        "code": "ELAXMI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301927S68.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Mega Bank",
        "code": "EMEGA",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S82.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Muktinath Bikas Bank Ltd",
        "code": "EMUKTI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105101421S93.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nepal Bank Limited",
        "code": "ENBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S54.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nepal Investment Bank Ltd",
        "code": "NIBLEBANK",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302011S72.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nic Asia Bank Limited",
        "code": "ENIC",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301926S99.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Prabhu Bank",
        "code": "EPRABHU",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S69.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Shangrila Development Bank Ltd",
        "code": "ESNBB",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105141426S09.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Agricultural Development Bank Limited",
        "code": "MBADBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S15.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Best Finance Company Ltd",
        "code": "MBBEST",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202108131600S73.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Century Bank",
        "code": "MBCENTURY",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S61.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Citizens Bank",
        "code": "MCITIZEN",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S81.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Civil Bank",
        "code": "MBCIVIL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241825S92.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Everest Bank",
        "code": "MBEBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105281830S14.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Global IME Bank",
        "code": "MBGIBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302124S33.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "ICFC Bank Limited",
        "code": "MBICFC",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S17.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Jyoti Bikash Bank",
        "code": "MBJBBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S85.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Kamana Sewa Bikash Bank Ltd",
        "code": "MBKAMANA",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S22.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Kumari Bank",
        "code": "MBKUMARI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S57.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Laxmi Bank",
        "code": "MBLAXMI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301927S68.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Machhapuchchhre Bank Limited",
        "code": "MBMBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302007S38.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Mega Bank",
        "code": "MBMEGA",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S82.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Muktinath Bikas Bank Ltd",
        "code": "MBMUKTI",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105101421S93.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "NCC Bank",
        "code": "MBNCC",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302123S83.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nepal Bank Limited",
        "code": "MBNBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S54.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nic Asia Bank Limited",
        "code": "MBNIC",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301926S99.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Prabhu Bank",
        "code": "MBPRABHU",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S69.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Rastriya Banijya Bank Ltd",
        "code": "MBRBB",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302007S9.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Sanima Bank",
        "code": "MBSANIMA",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302007S54.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Saptakoshi Development Bank",
        "code": "MBSKDB",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S11.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Shangrila Development Bank Ltd",
        "code": "MBSNBB",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105141426S09.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Siddhartha Bank",
        "code": "MBSBL",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302111S56.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Sunrise Bank",
        "code": "MBSUNRISE",
        "image": "https://apigateway.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S77.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Century Mobile Banking",
        "code": "CENTURYM",
        "image": "https://gateway.npay.com.np/Images/century-logo.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Citizen Bank Limited Mobile",
        "code": "CITIZENM",
        "image": "https://gateway.npay.com.np/Images/citizen.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Citizens Bank International Limited",
        "code": "CITIZEN",
        "image": "https://gateway.npay.com.np/Images/citizen.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Civil Bank Ltd. Mobile",
        "code": "CBLM",
        "image": "https://gateway.npay.com.np/Images/civilbank_logo.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Everest Bank Limited",
        "code": "Everest",
        "image": "https://gateway.npay.com.np/Images/Everest.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Garima Bikas Bank Limited",
        "code": "GARIMA",
        "image": "https://gateway.npay.com.np/Images/garima.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Garima Bikas Bank Mobile Banking",
        "code": "GARIMAM",
        "image": "https://gateway.npay.com.np/Images/garima.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Global IME Bank Ltd.",
        "code": "GIB",
        "image": "https://gateway.npay.com.np/Images/gibl.jpg",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Global IME Mobile Banking",
        "code": "GIBM",
        "image": "https://gateway.npay.com.np/Images/gibl.jpg",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Kamana Sewa Bikash Bank Ebank",
        "code": "KAMANA",
        "image": "https://gateway.npay.com.np/Images/kamana.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Kamana Sewa Bikash Bank Mbank",
        "code": "KAMANAM",
        "image": "https://gateway.npay.com.np/Images/kamana.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Kumari Bank Ltd.",
        "code": "KBL",
        "image": "https://gateway.npay.com.np/Images/kumari.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Kumari Bank Mobile Banking",
        "code": "KUMARIM",
        "image": "https://gateway.npay.com.np/Images/kumari.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Mahalaxmi Mobile Banking",
        "code": "MAHALAXMIM",
        "image": "https://gateway.npay.com.np/Images/mahalaxmi.jpg",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Mega Bank Ltd.",
        "code": "MEGA",
        "image": "https://gateway.npay.com.np/Images/Mega.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Mega Mobile Banking Ltd.",
        "code": "MEGAM",
        "image": "https://gateway.npay.com.np/Images/Mega.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Muktinath Bikas Bank",
        "code": "MUKTI",
        "image": "https://gateway.npay.com.np/Images/muktinath-logo.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Muktinath Mobile Banking",
        "code": "MUKTIM",
        "image": "https://gateway.npay.com.np/Images/muktinath-logo.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "NCC Mobile Banking",
        "code": "NCCM",
        "image": "https://gateway.npay.com.np/Images/ncc-logo.jpg",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Nepal Investment Bank Ltd.",
        "code": "NIBL",
        "image": "https://gateway.npay.com.np/Images/nibl_small.jpg",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "NIC ASIA Bank Ltd.",
        "code": "NICASIA",
        "image": "https://gateway.npay.com.np/Images/nicAsia1.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "NICASIA Mobile Bank",
        "code": "NICASIAM",
        "image": "https://gateway.npay.com.np/Images/nicAsia1.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "NMB e-Banking",
        "code": "NMB",
        "image": "https://gateway.npay.com.np/Images/nmb-logo.png",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "NMB Mobile Bank",
        "code": "NMBM",
        "image": "https://gateway.npay.com.np/Images/nmb-logo.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Prabhu Bank Ltd.",
        "code": "PBL",
        "image": "https://gateway.npay.com.np/Images/prabhu-logo.jpg",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Prabhu Mobile Banking",
        "code": "PRABHUM",
        "image": "https://gateway.npay.com.np/Images/prabhu-logo.jpg",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Prime Commercial Mobile Bank",
        "code": "PRIMEM",
        "image": "https://gateway.npay.com.np/Images/prime.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Rastriya Banijya Bank E-Banking",
        "code": "RBB",
        "image": "https://gateway.npay.com.np/Images/rbb.jpg",
        "type": "EBANK",
        "vendor": "NPAY"
    },
    {
        "name": "Rastriya Banijya Bank Mobile Banking",
        "code": "RBBM",
        "image": "https://gateway.npay.com.np/Images/rbb.jpg",
        "type": "MBANK",
        "vendor": "NPAY"
    }
];
const bankListStaging = [
    {
        "name": "Connect IPS",
        "code": "CONNECTIPS2",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105312153S41.png",
        "type": "CheckoutGateway",
        "url": "CheckoutGateway",
        "vendor": "NPS"
    },
    {
        "name": "Ime Pay",
        "code": "IMEPAYG",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104211546S28.png",
        "type": "CheckoutGateway",
        "url": "CheckoutGateway",
        "vendor": "NPS"
    },
    {
        "name": "Prabhu Pay",
        "code": "PRABHUPAYG",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104211707S81.png",
        "type": "CheckoutGateway",
        "url": "CheckoutGateway",
        "vendor": "NPS"
    },
    {
        "name": "Global IME Bank",
        "code": "EGIBL",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241934S88.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "MBL",
        "code": "MBLNNPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241857S6.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nepal Bank Limited",
        "code": "NEBLNPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241935S29.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "NIBL",
        "code": "NIBLNPKT",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202008071416S98.jpg",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "NIC ASIA",
        "code": "NICENPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241857S28.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Prabhu Bank",
        "code": "EPRABHU",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241935S93.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Test Bank",
        "code": "TEBANK",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202007292355S3.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Test Bank II",
        "code": "TIIEBANK",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104011205S44.png",
        "type": "EBanking",
        "url": "EBanking",
        "vendor": "NPS"
    },
    {
        "name": "Global IME Bank",
        "code": "MBGIBL",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241934S88.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "MBL",
        "code": "MBLNNPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241857S6.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Nepal Bank Limited",
        "code": "NEBLNPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241935S29.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "NIC ASIA",
        "code": "NICENPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241857S28.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Prabhu Bank",
        "code": "MBPRABHU",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241935S93.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Sanima Bank",
        "code": "MBSANIMA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241935S69.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Sunrise Bank",
        "code": "SRBLNPKA",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202105241936S73.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Test Bank II",
        "code": "TIIMBANK",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104011205S44.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "Test Bank",
        "code": "TMBANK",
        "image": "https://apisandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202007292355S3.png",
        "type": "MBanking",
        "url": "MBanking",
        "vendor": "NPS"
    },
    {
        "name": "TEST BANK LTD.",
        "code": "TBANK",
        "image": "http://gateway.sandbox.npay.com.np/Images/testbank_logo.png",
        "type": "MBANK",
        "vendor": "NPAY"
    },
    {
        "name": "TEST E Bank",
        "code": "TEBANK",
        "image": "http://gateway.sandbox.npay.com.np/Images/testbank_logo.png",
        "type": "EBANK",
        "vendor": "NPAY"
    }
];

const bankTransferListLive = [
    {
        "bankId": "2301",
        "bankName": "NIC Asia Bank Limited"
    },
    {
        "bankId": "1201",
        "bankName": "Nepal Credit and Commerce Bank Limited"
    },
    {
        "bankId": "7518",
        "bankName": "Nepal Infrastucture Bank Ltd."
    },
    {
        "bankId": "1901",
        "bankName": "Global IME Bank Limited"
    },
    {
        "bankId": "0501",
        "bankName": "Nepal Investment Bank Limited"
    },
    {
        "bankId": "1701",
        "bankName": "Laxmi Bank Limited"
    },
    {
        "bankId": "2601",
        "bankName": "Prabhu Bank Limited"
    },
    {
        "bankId": "1101",
        "bankName": "Bank of Kathmandu Limited"
    },
    {
        "bankId": "1001",
        "bankName": "Everest Bank Limited"
    },
    {
        "bankId": "0701",
        "bankName": "Himalayan Bank Limited"
    },
    {
        "bankId": "0301",
        "bankName": "Agricultural Development Bank Ltd."
    },
    {
        "bankId": "2001",
        "bankName": "Citizens Bank International Limited"
    },
    {
        "bankId": "6001",
        "bankName": "Jyoti Bikash Bank Ltd"
    },
    {
        "bankId": "1501",
        "bankName": "Machhapuchchhre Bank Limited"
    },
    {
        "bankId": "0401",
        "bankName": "Nabil Bank Ltd."
    },
    {
        "bankId": "0801",
        "bankName": "Nepal SBI Bank Limited"
    },
    {
        "bankId": "2801",
        "bankName": "Mega Bank Nepal Ltd."
    },
    {
        "bankId": "2101",
        "bankName": "Prime Commercial Bank Limited"
    },
    {
        "bankId": "2201",
        "bankName": "Sunrise Bank Limited"
    },
    {
        "bankId": "0201",
        "bankName": "Rastriya Banijya Bank Limited"
    },
    {
        "bankId": "2501",
        "bankName": "NMB Bank Limited"
    },
    {
        "bankId": "1801",
        "bankName": "Siddhartha Bank Limited"
    },
    {
        "bankId": "3101",
        "bankName": "Century Commercial Bank Limited"
    },
    {
        "bankId": "4501",
        "bankName": "Sanima Bank Ltd."
    },
    {
        "bankId": "1601",
        "bankName": "Kumari Bank Ltd."
    },
    {
        "bankId": "6601",
        "bankName": "Garima Bikas Bank Limited"
    },
    {
        "bankId": "0101",
        "bankName": "Nepal Bank Limited"
    },
    {
        "bankId": "3001",
        "bankName": "Civil Bank Ltd."
    },
    {
        "bankId": "7301",
        "bankName": "Shangrila Development Bank Limited"
    },
    {
        "bankId": "6801",
        "bankName": "Kamana Sewa Bikas Bank Ltd."
    },
    {
        "bankId": "7201",
        "bankName": "Muktinath Bikas Bank Limited"
    },
    {
        "bankId": "0901",
        "bankName": "Nepal Bangladesh Bank Ltd"
    },
    {
        "bankId": "9906",
        "bankName": "Central Finance Ltd"
    },
    {
        "bankId": "9201",
        "bankName": "Shree Investment & Finance Co. Ltd."
    },
    {
        "bankId": "7509",
        "bankName": "Miteri Development Bank Limited"
    },
    {
        "bankId": "9939",
        "bankName": "Reliance Finance Ltd."
    },
    {
        "bankId": "9935",
        "bankName": "Manjushree Finance Limited"
    },
    {
        "bankId": "9001",
        "bankName": "Goodwill Finance Limited"
    },
    {
        "bankId": "9929",
        "bankName": "Karnali Development Bank Ltd."
    },
    {
        "bankId": "8101",
        "bankName": "Shine Resunga Development Bank Ltd."
    },
    {
        "bankId": "9915",
        "bankName": "Guheswori Merchant Banking and Finance Limited"
    },
    {
        "bankId": "7701",
        "bankName": "Nepal Finance Limited"
    },
    {
        "bankId": "9931",
        "bankName": "Mahalaxmi Bikas Bank Ltd."
    },
    {
        "bankId": "6401",
        "bankName": "Sindhu Bikash Bank Ltd."
    },
    {
        "bankId": "7502",
        "bankName": "Excel Development Bank Ltd."
    },
    {
        "bankId": "0601",
        "bankName": "Standard Chartered Bank Nepal Limited"
    },
    {
        "bankId": "5901",
        "bankName": "Saptakoshi Development Bank Ltd."
    },
    {
        "bankId": "9919",
        "bankName": "ICFC Finance Limited"
    },
    {
        "bankId": "5401",
        "bankName": "Lumbini Bikas Bank Limited"
    },
    {
        "bankId": "9905",
        "bankName": "Pokhara Finance Ltd."
    },
    {
        "bankId": "8301",
        "bankName": "Gurkhas Finance Limited"
    },
    {
        "bankId": "9911",
        "bankName": "Samriddhi Finance Company Ltd."
    },
    {
        "bankId": "9902",
        "bankName": "Progressive Finance Co. Ltd."
    },
    {
        "bankId": "7516",
        "bankName": "Best Finance Company Ltd."
    },
    {
        "bankId": "7517",
        "bankName": "Green Development Bank Ltd."
    }
];
const bankTransferListStaging = [
    {
        "bankId": "2201",
        "bankName": "Sunrise Bank Limited"
    },
    {
        "bankId": "1801",
        "bankName": "Siddhartha Bank Limited"
    },
    {
        "bankId": "9001",
        "bankName": "Goodwill Finance Limited"
    },
    {
        "bankId": "9912",
        "bankName": "Purnima Bikas Bank Limited"
    },
    {
        "bankId": "5401",
        "bankName": "Lumbini Bikas Bank Ltd"
    },
    {
        "bankId": "9902",
        "bankName": "Progressive Finance Co. Ltd."
    },
    {
        "bankId": "7502",
        "bankName": "Excel Development Bank Ltd."
    },
    {
        "bankId": "9915",
        "bankName": "Guheshwori Merchant Banking and Finance Limited"
    },
    {
        "bankId": "9801",
        "bankName": "United Finance Ltd."
    },
    {
        "bankId": "9201",
        "bankName": "Shree Investment & Finance Co. Ltd."
    },
    {
        "bankId": "1101",
        "bankName": "Bank of Kathmandu Limited"
    },
    {
        "bankId": "8301",
        "bankName": "Gurkhas Finance Limited"
    },
    {
        "bankId": "9919",
        "bankName": "ICFC Finance Limited"
    },
    {
        "bankId": "9935",
        "bankName": "Manjushree Finance Limited"
    },
    {
        "bankId": "9945",
        "bankName": "Kanchan Development Bank Ltd"
    },
    {
        "bankId": "7518",
        "bankName": "Nepal Infrastructure Bank Ltd."
    },
    {
        "bankId": "9931",
        "bankName": "Mahalaxmi Bikas Bank Ltd."
    },
    {
        "bankId": "5901",
        "bankName": "Saptakoshi Development Bank Ltd."
    },
    {
        "bankId": "7517",
        "bankName": "Green Development Bank Ltd"
    },
    {
        "bankId": "0601",
        "bankName": "Standard Chartered Bank Nepal"
    },
    {
        "bankId": "1001",
        "bankName": "Everest Bank Limited"
    },
    {
        "bankId": "9939",
        "bankName": "Reliance Finance Ltd."
    },
    {
        "bankId": "2701",
        "bankName": "Janata Bank Nepal Limited"
    },
    {
        "bankId": "2601",
        "bankName": "Prabhu Bank Limited"
    },
    {
        "bankId": "8881",
        "bankName": "DUM Bank"
    },
    {
        "bankId": "1201",
        "bankName": "Nepal Credit and Commerce Bank Limited"
    },
    {
        "bankId": "1601",
        "bankName": "Kumari Bank Ltd."
    },
    {
        "bankId": "2001",
        "bankName": "Citizens Bank International Limited"
    },
    {
        "bankId": "8401",
        "bankName": "Bhargav Bikas Bank Limited"
    },
    {
        "bankId": "6001",
        "bankName": "Jyoti Bikash Bank Ltd"
    },
    {
        "bankId": "9929",
        "bankName": "Karnali Development Bank td."
    },
    {
        "bankId": "3001",
        "bankName": "Civil Bank Ltd."
    },
    {
        "bankId": "3101",
        "bankName": "Century Commercial Bank Limited"
    },
    {
        "bankId": "7001",
        "bankName": "Gandaki Bikas Bank Ltd"
    },
    {
        "bankId": "1501",
        "bankName": "Machhapuchchhre Bank Limited"
    },
    {
        "bankId": "0801",
        "bankName": "Nepal SBI Bank Limited"
    },
    {
        "bankId": "0201",
        "bankName": "Rastriya Banijya Bank Limited"
    },
    {
        "bankId": "7516",
        "bankName": "Best Finance Company Ltd."
    },
    {
        "bankId": "5001",
        "bankName": "Deva Bikas Bank Limited"
    },
    {
        "bankId": "8001",
        "bankName": "Kailash Bikas Bank Ltd."
    },
    {
        "bankId": "7301",
        "bankName": "Shangrila Development Bank Limited"
    },
    {
        "bankId": "9938",
        "bankName": "Jebils Finance Ltd"
    },
    {
        "bankId": "2801",
        "bankName": "Mega Bank Nepal Ltd."
    },
    {
        "bankId": "0901",
        "bankName": "Nepal Bangladesh Bank Ltd"
    },
    {
        "bankId": "4501",
        "bankName": "Sanima Bank Ltd."
    },
    {
        "bankId": "0501",
        "bankName": "Nepal Investment Bank Limited"
    },
    {
        "bankId": "0401",
        "bankName": "Nabil Bank Ltd."
    },
    {
        "bankId": "1901",
        "bankName": "Global IME Bank Limited"
    },
    {
        "bankId": "0101",
        "bankName": "Nepal Bank Limited"
    },
    {
        "bankId": "6601",
        "bankName": "Garima Bikas Bank Limited"
    },
    {
        "bankId": "7201",
        "bankName": "Muktinath Bikas Bank Limited"
    },
    {
        "bankId": "7515",
        "bankName": "Kankai Bikas Bank Ltd"
    },
    {
        "bankId": "0701",
        "bankName": "Himalayan Bank Limited"
    },
    {
        "bankId": "0301",
        "bankName": "Agriculture Development Bank Ltd"
    },
    {
        "bankId": "6701",
        "bankName": "Om Development Bank Limited"
    },
    {
        "bankId": "1701",
        "bankName": "Laxmi Bank Limited"
    },
    {
        "bankId": "2501",
        "bankName": "NMB Bank Limited"
    },
    {
        "bankId": "8101",
        "bankName": "Shine Resunga Development Bank Ltd."
    },
    {
        "bankId": "2101",
        "bankName": "Prime Commercial Bank Limited"
    },
    {
        "bankId": "6801",
        "bankName": "Kamana Sewa Bikas Bank Ltd."
    },
    {
        "bankId": "2301",
        "bankName": "NIC Asia Bank Limited"
    }
];

const brokerListLive = [
    {
        "appId": "BRO-65-SSP-1",
        "appName": "No: 39 Sumeru Securities Pvt.Limited"
    },
    {
        "appId": "BRO-38-DAK-1",
        "appName": "No: 33 Dakshinkali Investment & Securities Pvt. Ltd."
    },
    {
        "appId": "DSS-27-APP-1",
        "appName": "No: 59 Deevyaa Securities and Stock House Pvt. Ltd."
    },
    {
        "appId": "INV-85-APP-1",
        "appName": "No: 19 Nepal Investment and Securities trading pvt. Ltd"
    },
    {
        "appId": "BRO-42-NSH-1",
        "appName": "No: 14 Nepal Stock House Pvt. Ltd."
    },
    {
        "appId": "BRO-71-DYN-1",
        "appName": "No: 44 Dynamic Money Managers Securities Pvt.Ltd"
    },
    {
        "appId": "BRO-73-SEW-1",
        "appName": "No: 54 Sewa Securities Pvt. Limited."
    },
    {
        "appId": "BRO-74-LIN-1",
        "appName": "No: 41 Linch Stock Market Limited."
    },
    {
        "appId": "ABC-14-APP-1",
        "appName": "No: 17 ABC Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-50-KAL-1",
        "appName": "No: 46 Kalika Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-56-OPL-1",
        "appName": "No: 04 Stock Broker Opal Securities Investment Pvt. ltd."
    },
    {
        "appId": "BRO-67-ARN-1",
        "appName": "No: 03 Arun Securities Pvt. Limited"
    },
    {
        "appId": "BRO-39-JFS-1",
        "appName": "No: 07 J.F. Securities Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-47-NAS-1",
        "appName": "No: 58 Naasa Securities Company Ltd."
    },
    {
        "appId": "BRO-55-AGR-5",
        "appName": "No: 06 Agrawal Securities Pvt. ltd. - Janakpur"
    },
    {
        "appId": "BRO-81-SAB-1",
        "appName": "No: 43 South Asian Bulls Pvt. Ltd."
    },
    {
        "appId": "BRO-55-AGR-4",
        "appName": "No: 06 Agrawal Securities Pvt. Ltd. - Biratnagar"
    },
    {
        "appId": "SPL-21-APP-1",
        "appName": "No: 20 Sipla Securities Pvt. Ltd."
    },
    {
        "appId": "SWT-18-APP-1",
        "appName": "No: 25 Sweta Securities Pvt. Ltd."
    },
    {
        "appId": "TRV-24-APP-1",
        "appName": "No: 13 Thrive Brokerage House Pvt. Ltd."
    },
    {
        "appId": "BRO-72-SAG-1",
        "appName": "No: 18 Sagarmatha Securities Pvt. Limited"
    },
    {
        "appId": "VSN-33-APP-1",
        "appName": "No: 34 Vision Securities Pvt Ltd."
    },
    {
        "appId": "BRO-68-ASN-1",
        "appName": "No: 26 Asian Securities Pvt. Limited"
    },
    {
        "appId": "CRS-31-APP-1",
        "appName": "No: 50 Crystal Kanchenjunga Securities Pvt.Ltd."
    },
    {
        "appId": "DPK-26-APP-1",
        "appName": "No: 38 Dipshika Dhitopatra Karobar Company Pvt. Ltd."
    },
    {
        "appId": "BRO-55-AGR-1",
        "appName": "No: 06 Agrawal Securities Pvt. Limited"
    },
    {
        "appId": "BRO-54-SAN-1",
        "appName": "No: 42  Sani Securities Company Ltd."
    },
    {
        "appId": "BRO-59-SKS-1",
        "appName": "No: 28 Shree Krishna Securities Limited"
    },
    {
        "appId": "BRO-60-PRE-1",
        "appName": "No: 32 Premier Securities Co. Ltd."
    },
    {
        "appId": "BRO-62-SUN-1",
        "appName": "No: 52 Sundhara Securities Ltd. "
    },
    {
        "appId": "BRO-57-TRI-1",
        "appName": "No: 48 Trishakti Securities Public Ltd."
    },
    {
        "appId": "BRO-52-MKT-1",
        "appName": "No: 05 Market Securities Exchange Company Pvt. Limited"
    },
    {
        "appId": "BRO-61-CRE-1",
        "appName": "No: 40 Creative Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-63-IMN-1",
        "appName": "No: 53 Investment Management Nepal Pvt. Ltd."
    },
    {
        "appId": "BRO-40-IMP-1",
        "appName": "No: 45 Imperial Securities Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-43-OXF-1",
        "appName": "No: 51 Oxford Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-44-BSB-1",
        "appName": "No: 55 Bhrikuti Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-45-SWA-1",
        "appName": "No: 37 Swarnalaxmi Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-48-ARY-1",
        "appName": "No: 57 Aryatara Investment & Securities Pvt. Ltd"
    },
    {
        "appId": "BRO-49-NEV-1",
        "appName": "No: 47  Neev Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-35-KOH-1",
        "appName": "No: 35 Kohinoor Investment & Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-36-SEC-1",
        "appName": "No: 36 Secured Securities Ltd."
    },
    {
        "appId": "BRO-37-TRI-1",
        "appName": "No: 29 Trishul Securities & Investment Ltd."
    },
    {
        "appId": "KUM-32-APP-1",
        "appName": "No: 01 Kumari Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-109-APP-1",
        "appName": "No: 56 Shree Hari Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-82-ASH-3",
        "appName": "No: 08 Ashutosh Brokerage & Securities Pvt. Ltd."
    },
    {
        "appId": "MDS-23-APP-1",
        "appName": "No: 21 Midas Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "MNM-19-APP-1",
        "appName": "No: 11 Malla & Malla Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "ONL-16-APP-1",
        "appName": "No: 49 Online Securities Pvt. Ltd."
    },
    {
        "appId": "PRG-22-APP-1",
        "appName": "No: 10 Pragyan Securities Pvt. Ltd"
    },
    {
        "appId": "PRM-17-APP-1",
        "appName": "No: 16 Primo Securites Pvt. Ltd."
    },
    {
        "appId": "SPB-20-APP-1",
        "appName": "No: 22 Siprabi Securities Pvt. Ltd."
    }
];
const brokerListStaging = [
    {
        "appId": "BRO-68-ASN-1",
        "appName": "No: 26 Asian Securities Pvt. Limited"
    },
    {
        "appId": "BRO-36-SEC-1",
        "appName": "No: 36 Secured Securities Ltd."
    },
    {
        "appId": "BRO-37-TRI-1",
        "appName": "No: 29 Trishul Securities & Investment Ltd."
    },
    {
        "appId": "ONL-16-APP-1",
        "appName": "No: 49 Online Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-38-DAK-1",
        "appName": "No: 33 Dakshinkali Investment & Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-73-SEW-1",
        "appName": "No: 54 Sewa Securities Pvt. Limited."
    },
    {
        "appId": "DSS-27-APP-1",
        "appName": "No: 59 Deevyaa Securities and Stock House Pvt. Ltd."
    },
    {
        "appId": "BRO-42-NSH-1",
        "appName": "No: 14 Nepal Stock House Pvt. Ltd."
    },
    {
        "appId": "BRO-74-LIN-1",
        "appName": "No: 41 Linch Stock Market Limited."
    },
    {
        "appId": "ABC-14-APP-1",
        "appName": "No: 17 ABC Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-55-AGR-4",
        "appName": "No: 6 Agrawal Securities Pvt. Ltd. - Biratnagar"
    },
    {
        "appId": "BRO-72-SAG-1",
        "appName": "No: 18 Sagarmatha Securities Pvt. Limited"
    },
    {
        "appId": "MNM-19-APP-1",
        "appName": "No: 11 Malla & Malla Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "PRG-22-APP-1",
        "appName": "No: 10 Pragyan Securities Pvt. Ltd"
    },
    {
        "appId": "PRM-17-APP-1",
        "appName": "No: 16 Primo Securites Pvt. Ltd."
    },
    {
        "appId": "SPB-20-APP-1",
        "appName": "No: 22 Siprabi Securities Pvt. Ltd."
    },
    {
        "appId": "SPL-21-APP-1",
        "appName": "No: 20 Sipla Securities Pvt. Ltd."
    },
    {
        "appId": "SWT-18-APP-1",
        "appName": "No: 25 Sweta Securities Pvt. Ltd."
    },
    {
        "appId": "TRV-24-APP-1",
        "appName": "No: 13 Thrive Brokerage House Pvt. Ltd."
    },
    {
        "appId": "VSN-33-APP-1",
        "appName": "No: 34 Vision Securities Pvt Ltd."
    },
    {
        "appId": "KUM-32-APP-1",
        "appName": "No: 1 Kumari Securities Pvt. Ltd."
    },
    {
        "appId": "MDS-23-APP-1",
        "appName": "No: 21 Midas Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "CRS-31-APP-1",
        "appName": "No: 50 Crystal Kanchenjunga Securities Pvt.Ltd."
    },
    {
        "appId": "BRO-52-MKT-1",
        "appName": "No: 5 Market Securities Exchange Company Pvt. Limited"
    },
    {
        "appId": "BRO-55-AGR-1",
        "appName": "No: 6 Agrawal Securities Pvt. Limited"
    },
    {
        "appId": "BRO-54-SAN-1",
        "appName": "No: 42  Sani Securities Company Ltd."
    },
    {
        "appId": "BRO-56-OPL-1",
        "appName": "No: 4 Stock Broker Opal Securities Investment Pvt. ltd."
    },
    {
        "appId": "BRO-59-SKS-1",
        "appName": "No: 28 Shree Krishna Securities Limited"
    },
    {
        "appId": "BRO-60-PRE-1",
        "appName": "No: 32 Premier Securities Co. Ltd."
    },
    {
        "appId": "BRO-62-SUN-1",
        "appName": "No: 52 Sundhara Securities Ltd. "
    },
    {
        "appId": "BRO-61-CRE-1",
        "appName": "No: 40 Creative Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-40-IMP-1",
        "appName": "No: 45 Imperial Securities Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-43-OXF-1",
        "appName": "No: 51 Oxford Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-44-BSB-1",
        "appName": "No: 55 Bhrikuti Stock Broking Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-45-SWA-1",
        "appName": "No: 37 Swarnalaxmi Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-48-ARY-1",
        "appName": "No: 57 Aryatara Investment & Securities Pvt. Ltd"
    },
    {
        "appId": "BRO-49-NEV-1",
        "appName": "No: 47  Neev Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-35-KOH-1",
        "appName": "No: 35 Kohinoor Investment & Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-81-SAB-1",
        "appName": "No: 43 South Asian Bulls Pvt. Ltd."
    },
    {
        "appId": "DPK-26-APP-1",
        "appName": "No: 38 Dipshika Dhitopatra Karobar Company Pvt. Ltd."
    },
    {
        "appId": "INV-85-APP-1",
        "appName": "No:19 Nepal Investment and Securities trading pvt. Ltd"
    },
    {
        "appId": "BRO-39-JFS-1",
        "appName": "No: 7 J.F. Securities Co. Pvt. Ltd."
    },
    {
        "appId": "BRO-71-DYN-1",
        "appName": "No: 44 Dynamic Money Managers Securities Pvt.Ltd"
    },
    {
        "appId": "BRO-55-AGR-5",
        "appName": "Agrawal Securities Pvt. ltd. - Janakpur"
    },
    {
        "appId": "BRO-50-KAL-1",
        "appName": "No: 46 Kalika Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-63-IMN-1",
        "appName": "No: 53 Investment Management Nepal Pvt. Ltd."
    },
    {
        "appId": "BRO-47-NAS-1",
        "appName": "No: 58 Naasa Securities Company Ltd."
    },
    {
        "appId": "BRO-57-TRI-1",
        "appName": "No: 48 Trishakti Securities Public Ltd."
    },
    {
        "appId": "BRO-67-ARN-1",
        "appName": "No: 3 Arun Securities Pvt. Limited"
    },
    {
        "appId": "BRO-65-SSP-1",
        "appName": "No: 39 Sumeru Securities Pvt.Limited"
    },
    {
        "appId": "BRO-82-ASH-3",
        "appName": "No: 8 Ashutosh Brokerage & Securities Pvt. Ltd."
    },
    {
        "appId": "BRO-109-APP-1",
        "appName": "No: 56 Shree Hari Securities Pvt. Ltd."
    }
];

const meroshareListLive = [
    {
        "appId": "MER-495-JAN-2",
        "appName": "Janata Capital Limited - 13012200"
    },
    {
        "appId": "DPK-26-APP-3",
        "appName": "Dipshika Dhitopatra Karobar Company Pvt. Ltd. - 13011300"
    },
    {
        "appId": "MER-88-NMB-2",
        "appName": "NMB Capital Limited - 13011000"
    },
    {
        "appId": "MER-282-CIV-2",
        "appName": "Civil Capital Market Limited - 13010100"
    },
    {
        "appId": "MER-461-APP-2",
        "appName": "RBB Merchant Banking Limited - 13016500"
    },
    {
        "appId": "SPL-21-APP-2",
        "appName": "Sipla Securities Pvt. Ltd - 13014600"
    },
    {
        "appId": "MER-66-SUN-2",
        "appName": "Sunrise Capital Limited - 13012400"
    },
    {
        "appId": "CTZ-69-APP-3",
        "appName": "Citizens Bank International Limited  - 13011700"
    },
    {
        "appId": "MER-536-CSP-1",
        "appName": "Creative Securities Pvt Ltd-13013300"
    },
    {
        "appId": "MER-528-MEG-1",
        "appName": "Mega Capital Market Limited - 13017300"
    },
    {
        "appId": "DAK-38-APP-2",
        "appName": "Dakshinkali Investment & Securities Pvt. Ltd.  - 13012000"
    },
    {
        "appId": "MER-537-SAN-1",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "MER-526-MAC-1",
        "appName": "Machhapuchchhre Bank Ltd. - 13016100"
    },
    {
        "appId": "MER-530-ICFC-1",
        "appName": "ICFC Finance Limited-13017400"
    },
    {
        "appId": "BRO-91-NDP-2",
        "appName": "Nepal DP Limited - 13015500"
    },
    {
        "appId": "MER-46-GIC-3",
        "appName": "Global IME Capital Ltd - 13011200"
    },
    {
        "appId": "BRO-60-PRE-2",
        "appName": "Premier Securities Co. Ltd - 13014800"
    },
    {
        "appId": "CRS-31-APP-2",
        "appName": "Crystal Kanchenjunga Securities Pvt.Ltd - 13013400"
    },
    {
        "appId": "VSN-33-APP-3",
        "appName": "Vision Securities Pvt. Ltd - 13013500"
    },
    {
        "appId": "NSH-42-APP-3",
        "appName": "Nepal Stock House Pvt. Ltd. -13011500"
    },
    {
        "appId": "MER-562-APP-1",
        "appName": "Jyoti Bikash Bank Ltd-13017900"
    },
    {
        "appId": "PMB-578-APP-2",
        "appName": "Provident merchant banking Limited- 13016600"
    },
    {
        "appId": "MER-71-APP-1",
        "appName": "Dynamic Money Managers Securities Pvt.Ltd -14900"
    },
    {
        "appId": "NBL-565-APP-1",
        "appName": "Nepal Bank Ltd - 13015700"
    },
    {
        "appId": "MER-17-APP-2",
        "appName": "Primo Securities Pvt. Ltd-13012800"
    },
    {
        "appId": "BRO-82-ASH-1",
        "appName": "Ashutosh Brokerage & Securities Pvt. Ltd - 13015600"
    },
    {
        "appId": "BRO-36-SEC-3",
        "appName": "Secured Securities Ltd - 13011600"
    },
    {
        "appId": "BRO-40-IMP-2",
        "appName": "Imperial Securities Co. Pvt. Ltd - 13013100"
    },
    {
        "appId": "ABC-14-APP-3",
        "appName": "ABC Securities Pvt. Ltd - 13013200"
    },
    {
        "appId": "ONL-16-APP-3",
        "appName": "Online Securities Pvt. Ltd - 13011800"
    },
    {
        "appId": "BRO-65-SSP-2",
        "appName": "Sumeru Securities Pvt.Limited - 14200"
    },
    {
        "appId": "BRO-109-APP-3",
        "appName": "Sri Hari Securities Pvt. Ltd.- 13014000"
    },
    {
        "appId": "BRO-631-APP-2",
        "appName": "National Merchant Banker Ltd. - 13016400"
    },
    {
        "appId": "MER-64-LAX-2",
        "appName": "Laxmi Capital Market Limited - 13010700"
    },
    {
        "appId": "MER-539-KCL-1",
        "appName": "KCL ASTUTE CAPITAL LIMITED-13017600"
    },
    {
        "appId": "MER-559-PRM-2",
        "appName": "Prime Commercial Bank Limited - 13016900"
    },
    {
        "appId": "BRO-57-TRI-3",
        "appName": "Trishakti Securities Public Ltd. - 13011400"
    },
    {
        "appId": "DSS-27-APP-3",
        "appName": "Deevyaa Securities and Stock House Pvt. Ltd. - 13014500"
    },
    {
        "appId": "MER-553-APP-1",
        "appName": "Kriti Capital & Investment Limited - 13014100"
    },
    {
        "appId": "MER-507-APP-2",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "MER-540-SUN-1",
        "appName": "Sundhara Securities Ltd.-13017800"
    },
    {
        "appId": "BRO-632-APP-1",
        "appName": "Gurkhas Finance Limited-13018000"
    },
    {
        "appId": "MER-437-APP-1",
        "appName": "United Finance Limited - 13016800"
    },
    {
        "appId": "MER-533-APP-1",
        "appName": "Oxford Securities Pvt. Ltd - 13017000"
    },
    {
        "appId": "MER-270-PRB-2",
        "appName": "Prabhu Capital Limited- 13012600"
    },
    {
        "appId": "MER-561-APP-1",
        "appName": "NCC Bank-16300"
    },
    {
        "appId": "MER-532-SKS-1",
        "appName": "Shree Krishna Securities  Ltd-13012900"
    },
    {
        "appId": "MER-391-APP-1",
        "appName": "BOK Capital Market Limited - 13011100"
    },
    {
        "appId": "MER-531-GMF-1",
        "appName": "Guheswori Merchant Banking and Finance Ltd - 13016200"
    },
    {
        "appId": "MER-351-KUM-2",
        "appName": "Kumari Capital Market Limited - 13015200"
    },
    {
        "appId": "MER-404-APP-1",
        "appName": "Muktinath Capital Ltd - 13012500"
    },
    {
        "appId": "SAB-81-APP-1",
        "appName": "South Asian Bulls Pvt. Ltd - 13013000"
    },
    {
        "appId": "BRO-55-AGR-3",
        "appName": "Agrawal Securities Pvt. Limited - 13012300"
    },
    {
        "appId": "MER-535-MSE-1",
        "appName": "Market Securities Exchange Co. Pvt Ltd-13013600"
    },
    {
        "appId": "MER-557-WMM-2",
        "appName": "Wealth Management & Merchant Banking Ltd - 13017500"
    },
    {
        "appId": "MER-534-LIN-1",
        "appName": "Linch Stock Market Ltd -13013800"
    },
    {
        "appId": "MER-548-APP-1",
        "appName": "Century Commercial Bank Limited - 13016000"
    },
    {
        "appId": "SEW-14-APP-3",
        "appName": "Sewa Securities Pvt. Ltd. - 13012700"
    },
    {
        "appId": "BRO-44-BSB-3",
        "appName": "Bhrikuti Stock Broking Company Pvt. Ltd. - 13015000"
    },
    {
        "appId": "NAS-47-APP-3",
        "appName": "NAASA SECURITIES CO. LTD. -13015900"
    },
    {
        "appId": "ARY-48-APP-3",
        "appName": "Aryatara Inverstment & Securities Pvt. Ltd. - 13011900"
    },
    {
        "appId": "BRO-68-ASN-3",
        "appName": "Asian Securities Pvt. Ltd.- 13014700"
    },
    {
        "appId": "MER-467-APP-2",
        "appName": "NIBL Ace Capital Limited - 13010600 "
    },
    {
        "appId": "MER-467-APP-3",
        "appName": "NIBL Ace Capital Limited - 13010200"
    },
    {
        "appId": "MER-421-SID-2",
        "appName": "Siddhartha Capital Limited - 13010900"
    },
    {
        "appId": "MER-597-SBI-2",
        "appName": "Nepal SBI Bank Limited - 13015300"
    },
    {
        "appId": "MER-621-APP-2",
        "appName": "Everest Bank Limited - 13010800"
    }
];
const meroshareListStaging = [
    {
        "appId": "PMB-578-APP-2",
        "appName": "Provident merchant banking Limited- 13016600"
    },
    {
        "appId": "MER-539-KCL-1",
        "appName": "KCL ASTUTE CAPITAL LIMITED-13017600"
    },
    {
        "appId": "MER-537-SAN-1",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "MER-559-PRM-2",
        "appName": "Prime Commercial Bank Limited - 13016900"
    },
    {
        "appId": "MER-404-APP-1",
        "appName": "Cellcom"
    },
    {
        "appId": "MER-531-GMF-1",
        "appName": "Guheswori Merchant Banking and Finance Ltd - 13016200"
    },
    {
        "appId": "BRO-57-TRI-3",
        "appName": "Trishakti Securities Public Ltd. - 13011400"
    },
    {
        "appId": "DSS-27-APP-3",
        "appName": "Deevyaa Securities and Stock House Pvt. Ltd. - 13014500"
    },
    {
        "appId": "MER-553-APP-1",
        "appName": "Kriti Capital & Investment Limited - 13014100"
    },
    {
        "appId": "MER-507-APP-2",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "MER-282-CIV-2",
        "appName": "Civil Capital Market Limited - 13010100"
    },
    {
        "appId": "SAB-81-APP-1",
        "appName": "South Asian Bulls Pvt. Ltd - 13013000"
    },
    {
        "appId": "BRO-68-ASN-3",
        "appName": "Asian Securities Pvt. Ltd.- 13014700"
    },
    {
        "appId": "MER-540-SUN-1",
        "appName": "Sundhara Securities Ltd.-13017800"
    },
    {
        "appId": "CDSC-13013000-1",
        "appName": "South Asian Bulls Pvt. Ltd - 13013000"
    },
    {
        "appId": "CDSC-13012500-1",
        "appName": "Muktinath Capital Ltd - 13012500"
    },
    {
        "appId": "CDSC-13013600-1",
        "appName": "Market Securities Exchange Co. Pvt Ltd-13013600"
    },
    {
        "appId": "CDSC-13015200-1",
        "appName": "Kumari Capital Market Limited - 13015200"
    },
    {
        "appId": "CDSC-13014000-1",
        "appName": "Sri Hari Securities Pvt. Ltd.- 13014000"
    },
    {
        "appId": "CDSC-13013200-1",
        "appName": "ABC Securities Pvt. Ltd - 13013200"
    },
    {
        "appId": "CDSC-13016200-1",
        "appName": "Guheswori Merchant Banking and Finance Ltd - 13016200"
    },
    {
        "appId": "CDSC-13014600-1",
        "appName": "Sipla Securities Pvt. Ltd - 13014600"
    },
    {
        "appId": "CDSC-13014100-1",
        "appName": "Kriti Capital & Investment Limited - 13014100"
    },
    {
        "appId": "CDSC-13012200-1",
        "appName": "Janata Capital Limited - 13012200"
    },
    {
        "appId": "CDSC-13017600-1",
        "appName": "KCL ASTUTE CAPITAL LIMITED-13017600"
    },
    {
        "appId": "CDSC-13010700-1",
        "appName": "Laxmi Capital Market Limited - 13010700"
    },
    {
        "appId": "CDSC-13016100-1",
        "appName": "Machhapuchchhre Bank Ltd. - 13016100"
    },
    {
        "appId": "CDSC-13017300-1",
        "appName": "Mega Capital Market Limited - 13017300"
    },
    {
        "appId": "CDSC-13015900-1",
        "appName": "NAASA SECURITIES CO. LTD. -13015900"
    },
    {
        "appId": "CDSC-13012900-1",
        "appName": "Shree Krishna Securities  Ltd-13012900"
    },
    {
        "appId": "CDSC-13010900-1",
        "appName": "Siddhartha Capital Limited - 13010900"
    },
    {
        "appId": "CDSC-13017400-1",
        "appName": "ICFC Finance Limited-13017400"
    },
    {
        "appId": "MER-437-APP-1",
        "appName": "United Finance Limited - 13016800"
    },
    {
        "appId": "MER-46-GIC-3",
        "appName": "Global IME Capital Ltd - 13011200"
    },
    {
        "appId": "MER-533-APP-1",
        "appName": "Oxford Securities Pvt. Ltd - 13017000"
    },
    {
        "appId": "MER-270-PRB-2",
        "appName": "Prabhu Capital Limited- 13012600"
    },
    {
        "appId": "MER-561-APP-1",
        "appName": "NCC Bank-16300"
    },
    {
        "appId": "MER-532-SKS-1",
        "appName": "Shree Krishna Securities  Ltd-13012900"
    },
    {
        "appId": "MER-461-APP-2",
        "appName": "RBB Merchant Banking Limited - 13016500"
    },
    {
        "appId": "DPK-26-APP-3",
        "appName": "Dipshika Dhitopatra Karobar Company Pvt. Ltd. - 13011300"
    },
    {
        "appId": "MER-391-APP-1",
        "appName": "BOK Capital Market Limited - 13011100"
    },
    {
        "appId": "MER-71-APP-1",
        "appName": "Dynamic Money Managers Securities Pvt.Ltd -14900"
    },
    {
        "appId": "MER-351-KUM-2",
        "appName": "Kumari Capital Market Limited - 13015200"
    },
    {
        "appId": "BRO-60-PRE-2",
        "appName": "Premier Securities Co. Ltd - 13014800"
    },
    {
        "appId": "SPL-21-APP-2",
        "appName": "Sipla Securities Pvt. Ltd - 13014600"
    },
    {
        "appId": "MER-88-NMB-2",
        "appName": "NMB Capital Limited - 13011000"
    },
    {
        "appId": "BRO-55-AGR-3",
        "appName": "Agrawal Securities Pvt. Limited - 13012300"
    },
    {
        "appId": "MER-535-MSE-1",
        "appName": "Market Securities Exchange Co. Pvt Ltd-13013600"
    },
    {
        "appId": "MER-557-WMM-2",
        "appName": "Wealth Management & Merchant Banking Ltd - 13017500"
    },
    {
        "appId": "MER-548-APP-1",
        "appName": "Century Commercial Bank Limited - 13016000"
    },
    {
        "appId": "MER-536-CSP-1",
        "appName": "Creative Securities Pvt Ltd-13013300"
    },
    {
        "appId": "MER-528-MEG-1",
        "appName": "Mega Capital Market Limited - 13017300"
    },
    {
        "appId": "MER-526-MAC-1",
        "appName": "Machhapuchchhre Bank Ltd. - 13016100"
    },
    {
        "appId": "MER-530-ICFC-1",
        "appName": "ICFC Finance Limited-13017400"
    },
    {
        "appId": "MER-597-SBI-2",
        "appName": "Nepal SBI Bank Limited - 13015300"
    },
    {
        "appId": "MER-66-SUN-2",
        "appName": "Sunrise Capital Limited - 13012400"
    },
    {
        "appId": "MER-534-LIN-1",
        "appName": "Linch Stock Market Ltd -13013800"
    },
    {
        "appId": "CTZ-69-APP-3",
        "appName": "Citizens Bank International Limited  - 13011700"
    },
    {
        "appId": "DAK-38-APP-2",
        "appName": "Dakshinkali Investment & Securities Pvt. Ltd.  - 13012000"
    },
    {
        "appId": "SEW-14-APP-3",
        "appName": "Sewa Securities Pvt. Ltd. - 13012700"
    },
    {
        "appId": "BRO-44-BSB-3",
        "appName": "Bhrikuti Stock Broking Company Pvt. Ltd. - 13015000"
    },
    {
        "appId": "BRO-82-ASH-1",
        "appName": "Ashutosh Brokerage & Securities Pvt. Ltd - 13015600"
    },
    {
        "appId": "BRO-36-SEC-3",
        "appName": "Secured Securities Ltd - 13011600"
    },
    {
        "appId": "CRS-31-APP-2",
        "appName": "Crystal Kanchenjunga Securities Pvt.Ltd - 13013400"
    },
    {
        "appId": "BRO-40-IMP-2",
        "appName": "Imperial Securities Co. Pvt. Ltd - 13013100"
    },
    {
        "appId": "BRO-91-NDP-2",
        "appName": "Nepal DP Limited - 13015500"
    },
    {
        "appId": "MER-64-LAX-2",
        "appName": "Laxmi Capital Market Limited - 13010700"
    },
    {
        "appId": "MER-495-JAN-2",
        "appName": "Janata Capital Limited - 13012200"
    },
    {
        "appId": "CDSC-13013800-1",
        "appName": "Linch Stock Market Ltd -13013800"
    },
    {
        "appId": "ABC-14-APP-3",
        "appName": "ABC Securities Pvt. Ltd - 13013200"
    },
    {
        "appId": "VSN-33-APP-3",
        "appName": "Vision Securities Pvt. Ltd - 13013500"
    },
    {
        "appId": "ONL-16-APP-3",
        "appName": "Online Securities Pvt. Ltd - 13011800"
    },
    {
        "appId": "BRO-65-SSP-2",
        "appName": "Sumeru Securities Pvt.Limited - 14200"
    },
    {
        "appId": "MER-95-NAC-2",
        "appName": "NIC Asia Capital Limited - 13013700"
    },
    {
        "appId": "BRO-109-APP-3",
        "appName": "Sri Hari Securities Pvt. Ltd.- 13014000"
    },
    {
        "appId": "NAS-47-APP-3",
        "appName": "NAASA SECURITIES CO. LTD. -13015900"
    },
    {
        "appId": "CDSC-13013100-1",
        "appName": "Imperial Securities Co. Pvt. Ltd - 13013100"
    },
    {
        "appId": "ARY-48-APP-3",
        "appName": "Aryatara Inverstment & Securities Pvt. Ltd. - 13011900"
    },
    {
        "appId": "MER-421-SID-2",
        "appName": "Siddhartha Capital Limited - 13010900"
    },
    {
        "appId": "NSH-42-APP-3",
        "appName": "Nepal Stock House Pvt. Ltd. -13011500"
    }
];

const dematListLive = [
    {
        "appId": "BRO-631-APP-1",
        "appName": "National Merchant Banker Ltd. - 13016400"
    },
    {
        "appId": "MER-507-APP-1",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "MER-495-JAN-1",
        "appName": "Janata Capital Limited - 13012200"
    },
    {
        "appId": "BRO-57-TRI-2",
        "appName": "Trishakti Securities Public Ltd. - 13011400"
    },
    {
        "appId": "DPK-26-APP-2",
        "appName": "Dipshika Dhitopatra Karobar Company Pvt. Ltd - 13011300"
    },
    {
        "appId": "BRO-109-APP-2",
        "appName": "Sri Hari Securities Pvt. Ltd.- 13014000"
    },
    {
        "appId": "NSH-73-APP-2",
        "appName": "Nepal Stock House Pvt. Ltd. -13011500"
    },
    {
        "appId": "VSN-33-APP-2",
        "appName": "Vision Securities Pvt. Ltd - 13013500"
    },
    {
        "appId": "MER-557-WMM-1",
        "appName": "Wealth Management & Merchant Banking Ltd - 13017500"
    },
    {
        "appId": "MER-71-APP-2",
        "appName": "Dynamic Money Managers Securities Pvt.Ltd - 13014900"
    },
    {
        "appId": "MER-548-APP-2",
        "appName": "Century Commercial Bank Limited - 13016000"
    },
    {
        "appId": "CTZ-69-APP-2",
        "appName": "Citizens Bank International Limited  - 13011700"
    },
    {
        "appId": "MER-534-LIN-2",
        "appName": "Linch Stock Market Ltd -13013800"
    },
    {
        "appId": "MER-535-MSE-2",
        "appName": "Market Securities Exchange Co. Pvt Ltd-13013600"
    },
    {
        "appId": "DAK-38-APP-1",
        "appName": "Dakshinkali Investment & Securities Pvt. Ltd.  - 13012000"
    },
    {
        "appId": "MER-536-CSP-2",
        "appName": "Creative Securities Pvt Ltd-13013300"
    },
    {
        "appId": "MER-66-SUN-1",
        "appName": "Sunrise Capital Limited - 13012400"
    },
    {
        "appId": "MER-559-PRM-1",
        "appName": "Prime Commercial Bank Limited - 13016900"
    },
    {
        "appId": "SAB-81-APP-2",
        "appName": "South Asian Bulls Pvt. Ltd - 13013000"
    },
    {
        "appId": "BRO-91-NDP-1",
        "appName": "Nepal DP Limited - 13015500"
    },
    {
        "appId": "MER-46-GIC-2",
        "appName": "Global IME Capital Ltd - 13011200"
    },
    {
        "appId": "CRS-31-APP-3",
        "appName": "Crystal Kanchenjunga Securities Pvt.Ltd - 13013400"
    },
    {
        "appId": "BRO-60-PRE-3",
        "appName": "Premier Securities Co. Ltd - 13014800"
    },
    {
        "appId": "MER-526-MAC-2",
        "appName": "Machhapuchchhre Bank Ltd. - 13016100"
    },
    {
        "appId": "MER-562-APP-2",
        "appName": "Jyoti Bikash Bank Ltd-13017900"
    },
    {
        "appId": "PMB-578-APP-1",
        "appName": "Provident merchant banking Limited- 13016600"
    },
    {
        "appId": "MER-621-APP-1",
        "appName": "Everest Bank Limited - 13010800"
    },
    {
        "appId": "NBL-565-APP-2",
        "appName": "Nepal Bank Ltd - 13015700"
    },
    {
        "appId": "MER-17-APP-3",
        "appName": "Primo Securities Pvt. Ltd-13012800"
    },
    {
        "appId": "BRO-82-ASH-2",
        "appName": "Ashutosh Brokerage & Securities Pvt. Ltd - 13015600"
    },
    {
        "appId": "BRO-40-IMP-3",
        "appName": "Imperial Securities Co. Pvt. Ltd - 13013100"
    },
    {
        "appId": "ONL-16-APP-2",
        "appName": "Online Securities Pvt. Ltd - 13011800"
    },
    {
        "appId": "ABC-14-APP-2",
        "appName": "ABC Securities Pvt. Ltd - 13013200"
    },
    {
        "appId": "BRO-65-SSP-3",
        "appName": "Sumeru Securities Pvt.Ltd. - 14200"
    },
    {
        "appId": "MER-88-NMB-1",
        "appName": "NMB Capital Limited - 13011000"
    },
    {
        "appId": "MER-532-SKS-2",
        "appName": "Shree Krishna Securities  Ltd-13012900"
    },
    {
        "appId": "MER-530-ICFC-2",
        "appName": "ICFC Finance Limited-13017400"
    },
    {
        "appId": "MER-64-LAX-1",
        "appName": "Laxmi Capital Market Limited - 13010700"
    },
    {
        "appId": "MER-533-APP-2",
        "appName": "Oxford Securities Pvt. Ltd - 13017000"
    },
    {
        "appId": "MER-539-KCL-2",
        "appName": "KCL ASTUTE CAPITAL LIMITED-13017600"
    },
    {
        "appId": "MER-351-KUM-1",
        "appName": "Kumari Capital Market Limited - 13015200"
    },
    {
        "appId": "MER-528-MEG-2",
        "appName": "Mega Capital Market Limited - 13017300"
    },
    {
        "appId": "MER-553-APP-2",
        "appName": "Kriti Capital & Investment Limited - 13014100"
    },
    {
        "appId": "MER-437-APP-2",
        "appName": "United Finance Limited - 13016800"
    },
    {
        "appId": "MER-540-SUN-2",
        "appName": "Sundhara Securities Ltd.-13017800"
    },
    {
        "appId": "BRO-632-APP-2",
        "appName": "Gurkhas Finance Limited-13018000"
    },
    {
        "appId": "MER-391-APP-2",
        "appName": "BOK Capital Market Limited - 13011100"
    },
    {
        "appId": "MER-461-APP-1",
        "appName": "RBB Merchant Banking Limited - 13016500"
    },
    {
        "appId": "MER-531-GMF-2",
        "appName": "Guheswori Merchant Banking and Finance Ltd - 13016200"
    },
    {
        "appId": "MER-270-PRB-1",
        "appName": "Prabhu Capital Limited - 13012600"
    },
    {
        "appId": "MER-561-APP-2",
        "appName": "NCC Bank-13016300"
    },
    {
        "appId": "MER-404-APP-2",
        "appName": "Muktinath Capital Ltd - 13012500"
    },
    {
        "appId": "SPL-21-APP-3",
        "appName": "Sipla Securities Pvt. Ltd - 13014600"
    },
    {
        "appId": "MER-282-CIV-1",
        "appName": "Civil Capital Market Limited - 13010100"
    },
    {
        "appId": "DSS-27-APP-2",
        "appName": "Deevyaa Securities and Stock House Pvt. Ltd. - 13014500"
    },
    {
        "appId": "BRO-55-AGR-2",
        "appName": "Agrawal Securities Pvt. Limited - 13012300"
    },
    {
        "appId": "BRO-36-SEC-2",
        "appName": "Secured Securities Ltd - 13011600"
    },
    {
        "appId": "MER-559-PRM-3",
        "appName": "Prime Commercial Bank Limited - 13015400"
    },
    {
        "appId": "MER-537-SAN-2",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "BRO-44-BSB-2",
        "appName": "Bhrikuti Stock Broking Company Pvt. Ltd. - 13015000"
    },
    {
        "appId": "SEW-73-APP-2",
        "appName": "Sewa Securities Pvt. Ltd. - 13012700"
    },
    {
        "appId": "NAS-47-APP-2",
        "appName": "NAASA SECURITIES CO. LTD. -13015900"
    },
    {
        "appId": "ARY-48-APP-2",
        "appName": "Aryatara Inverstment &  Securities Pvt. Ltd. - 13011900"
    },
    {
        "appId": "BRO-68-ASN-2",
        "appName": "Asian Securities Pvt. Ltd. - 13014700"
    },
    {
        "appId": "MER-421-SID-1",
        "appName": "Siddhartha Capital Limited - 13010900"
    },
    {
        "appId": "MER-597-SBI-1",
        "appName": "Nepal SBI Bank Limited - 13015300"
    }
];
const dematListStaging = [
    {
        "appId": "MER-64-LAX-1",
        "appName": "Laxmi Capital Market Limited - 13010700"
    },
    {
        "appId": "MER-533-APP-2",
        "appName": "Oxford Securities Pvt. Ltd - 13017000"
    },
    {
        "appId": "MER-539-KCL-2",
        "appName": "KCL ASTUTE CAPITAL LIMITED-13017600"
    },
    {
        "appId": "MER-507-APP-1",
        "appName": "Nepal Academy"
    },
    {
        "appId": "MER-351-KUM-1",
        "appName": "Kumari Capital Market Limited - 13015200"
    },
    {
        "appId": "MER-528-MEG-2",
        "appName": "Mega Capital Market Limited - 13017300"
    },
    {
        "appId": "MER-553-APP-2",
        "appName": "Kriti Capital & Investment Limited - 13014100"
    },
    {
        "appId": "MER-437-APP-2",
        "appName": "United Finance Limited - 13016800"
    },
    {
        "appId": "MER-495-JAN-1",
        "appName": "Janata Capital Limited - 13012200"
    },
    {
        "appId": "BRO-57-TRI-2",
        "appName": "Trishakti Securities Public Ltd. - 13011400"
    },
    {
        "appId": "MER-540-SUN-2",
        "appName": "Sundhara Securities Ltd.-13017800"
    },
    {
        "appId": "MER-534-LIN-2",
        "appName": "Linch Stock Market Ltd -13013800"
    },
    {
        "appId": "CAP-612-APP-2",
        "appName": "aa test kinra"
    },
    {
        "appId": "MER-531-GMF-2",
        "appName": "Guheswori Merchant Banking and Finance Ltd - 13016200"
    },
    {
        "appId": "DPK-26-APP-2",
        "appName": "Dipshika Dhitopatra Karobar Company Pvt. Ltd - 13011300"
    },
    {
        "appId": "MER-391-APP-2",
        "appName": "BOK Capital Market Limited - 13011100"
    },
    {
        "appId": "MER-461-APP-1",
        "appName": "RBB Merchant Banking Limited - 13016500"
    },
    {
        "appId": "NSH-73-APP-2",
        "appName": "Nepal Stock House Pvt. Ltd. -13011500"
    },
    {
        "appId": "VSN-33-APP-2",
        "appName": "Vision Securities Pvt. Ltd - 13013500"
    },
    {
        "appId": "MER-557-WMM-1",
        "appName": "Wealth Management & Merchant Banking Ltd - 13017500"
    },
    {
        "appId": "MER-561-APP-2",
        "appName": "NCC Bank-13016300"
    },
    {
        "appId": "SPL-21-APP-3",
        "appName": "Sipla Securities Pvt. Ltd - 13014600"
    },
    {
        "appId": "MER-282-CIV-1",
        "appName": "Civil Capital Market Limited - 13010100"
    },
    {
        "appId": "DSS-27-APP-2",
        "appName": "Deevyaa Securities and Stock House Pvt. Ltd. - 13014500"
    },
    {
        "appId": "BRO-55-AGR-2",
        "appName": "Agrawal Securities Pvt. Limited - 13012300"
    },
    {
        "appId": "BRO-36-SEC-2",
        "appName": "Secured Securities Ltd - 13011600"
    },
    {
        "appId": "MER-559-PRM-3",
        "appName": "Prime Commercial Bank Limited - 13015400"
    },
    {
        "appId": "PMB-578-APP-1",
        "appName": "Provident merchant banking Limited- 13016600"
    },
    {
        "appId": "MER-548-APP-2",
        "appName": "Century Commercial Bank Limited - 13016000"
    },
    {
        "appId": "MER-71-APP-2",
        "appName": "Dynamic Money Managers Securities Pvt.Ltd - 13014900"
    },
    {
        "appId": "CTZ-69-APP-2",
        "appName": "Citizens Bank International Limited  - 13011700"
    },
    {
        "appId": "MER-535-MSE-2",
        "appName": "Market Securities Exchange Co. Pvt Ltd-13013600"
    },
    {
        "appId": "DAK-38-APP-1",
        "appName": "Dakshinkali Investment & Securities Pvt. Ltd.  - 13012000"
    },
    {
        "appId": "MER-66-SUN-1",
        "appName": "Sunrise Capital Limited - 13012400"
    },
    {
        "appId": "MER-559-PRM-1",
        "appName": "Prime Commercial Bank Limited - 13016900"
    },
    {
        "appId": "SAB-81-APP-2",
        "appName": "South Asian Bulls Pvt. Ltd - 13013000"
    },
    {
        "appId": "MER-270-PRB-1",
        "appName": "Prabhu Capital Limited - 13012600"
    },
    {
        "appId": "MER-404-APP-2",
        "appName": "Muktinath Capital Ltd - 13012500"
    },
    {
        "appId": "MER-536-CSP-2",
        "appName": "Creative Securities Pvt Ltd-13013300"
    },
    {
        "appId": "BRO-44-BSB-2",
        "appName": "Bhrikuti Stock Broking Company Pvt. Ltd. - 13015000"
    },
    {
        "appId": "BRO-91-NDP-1",
        "appName": "Nepal DP Limited - 13015500"
    },
    {
        "appId": "BRO-82-ASH-2",
        "appName": "Ashutosh Brokerage & Securities Pvt. Ltd - 13015600"
    },
    {
        "appId": "BRO-60-PRE-3",
        "appName": "Premier Securities Co. Ltd - 13014800"
    },
    {
        "appId": "BRO-40-IMP-3",
        "appName": "Imperial Securities Co. Pvt. Ltd - 13013100"
    },
    {
        "appId": "MER-46-GIC-2",
        "appName": "Global IME Capital Ltd - 13011200"
    },
    {
        "appId": "MER-532-SKS-2",
        "appName": "Shree Krishna Securities  Ltd-13012900"
    },
    {
        "appId": "MER-530-ICFC-2",
        "appName": "ICFC Finance Limited-13017400"
    },
    {
        "appId": "IND-617-APP-1",
        "appName": "yyy yykkyuuui"
    },
    {
        "appId": "CRS-31-APP-3",
        "appName": "Crystal Kanchenjunga Securities Pvt.Ltd - 13013400"
    },
    {
        "appId": "MER-537-SAN-2",
        "appName": "Sanima Capital Limited - 13015800"
    },
    {
        "appId": "ONL-16-APP-2",
        "appName": "Online Securities Pvt. Ltd - 13011800"
    },
    {
        "appId": "ABC-14-APP-2",
        "appName": "ABC Securities Pvt. Ltd - 13013200"
    },
    {
        "appId": "BRO-65-SSP-3",
        "appName": "Sumeru Securities Pvt.Ltd. - 14200"
    },
    {
        "appId": "MER-95-NAC-1",
        "appName": "NIC Asia Capital Limited - 13013700"
    },
    {
        "appId": "MER-88-NMB-1",
        "appName": "NMB Capital Limited - 13011000"
    },
    {
        "appId": "BRO-109-APP-2",
        "appName": "Sri Hari Securities Pvt. Ltd.- 13014000"
    },
    {
        "appId": "MER-526-MAC-2",
        "appName": "Machhapuchchhre Bank Ltd. - 13016100"
    },
    {
        "appId": "SEW-73-APP-2",
        "appName": "Sewa Securities Pvt. Ltd. - 13012700"
    },
    {
        "appId": "NAS-47-APP-2",
        "appName": "NAASA SECURITIES CO. LTD. -13015900"
    },
    {
        "appId": "ARY-48-APP-2",
        "appName": "Aryatara Inverstment &  Securities Pvt. Ltd. - 13011900"
    },
    {
        "appId": "BRO-68-ASN-2",
        "appName": "Asian Securities Pvt. Ltd. - 13014700"
    },
    {
        "appId": "MER-421-SID-1",
        "appName": "Siddhartha Capital Limited - 13010900"
    },
    {
        "appId": "CAP-618-APP-1",
        "appName": "kiran app tset yyy"
    },
    {
        "appId": "MER-597-SBI-1",
        "appName": "Nepal SBI Bank Limited - 13015300"
    }
];

const creditcardListLive = [
    {
        "appId": "MEG-70-APP-1",
        "appName": "Mega Bank Credit Card - NPR"
    },
    {
        "appId": "BOK-77-APP-1",
        "appName": "Bank Of Kathmandu Credit Card - NPR"
    },
    {
        "appId": "MEG-70-APP-2",
        "appName": "Mega Bank Credit Card - NPR  (Visa Classic)"
    },
    {
        "appId": "SBL-11-APP-2",
        "appName": "Siddhartha Bank Visa Credit Card - NPR(VISA Credit Card)"
    },
    {
        "appId": "CTZ-68-APP-1",
        "appName": "Citizens Bank Visa Credit Card - NPR"
    },
    {
        "appId": "KBL-30-APP-1",
        "appName": "Kumari Bank VISA Credit Card - NPR"
    },
    {
        "appId": "GIB-13-APP-2",
        "appName": "Global IME Visa Credit Card - NPR (VISA GOLD)"
    },
    {
        "appId": "CRD-41-PBL-1",
        "appName": "Prabhu Bank Credit Card - NPR"
    },
    {
        "appId": "NMB-15-APP-2",
        "appName": "NMB Bank Visa Credit Card - NPR (VISA PLATINUM CARD)"
    },
    {
        "appId": "CEN-29-APP-2",
        "appName": "Century Bank VISA Credit Card - NPR (VISA GOLD)"
    },
    {
        "appId": "NIB-516-APP-1",
        "appName": "NIBL Visa Credit Card Gold - NPR"
    },
    {
        "appId": "NIB-516-APP-3",
        "appName": "NIBL Visa Credit Card Gold (Contactless) - NPR"
    },
    {
        "appId": "SUN-76-APP-1",
        "appName": "Sunrise Bank Credit Card - NPR"
    },
    {
        "appId": "NIC-12-APP-1",
        "appName": "NIC Asia Visa Credit Card - NPR"
    },
    {
        "appId": "SBL-11-APP-1",
        "appName": "Siddhartha Bank Visa Credit Card - NPR"
    },
    {
        "appId": "CEN-29-APP-1",
        "appName": "Century Bank VISA Credit Card - NPR"
    },
    {
        "appId": "CRD-34-NBB-1",
        "appName": "Nepal Bangladesh Bank Credit Card - NPR"
    },
    {
        "appId": "GIB-13-APP-1",
        "appName": "Global IME Visa Credit Card - NPR"
    },
    {
        "appId": "NMB-15-APP-1",
        "appName": "NMB Bank Visa Credit Card - NPR"
    },
    {
        "appId": "MBL-25-APP-1",
        "appName": "Machhapuchhre Bank Credit Card - NPR"
    },
    {
        "appId": "CRD-58-PRM-1",
        "appName": "Prime Bank Credit Card - NPR"
    },
    {
        "appId": "EBL-260-APP-1",
        "appName": "Everest Bank Visa Credit Card - NPR"
    },
    {
        "appId": "SAN-255-APP-1",
        "appName": "Sanima Bank  VISA  Credit Card - NPR"
    },
    {
        "appId": "LXB-673-APP-2",
        "appName": "Laxmi Bank Visa Credit Card (405019) - NPR"
    },
    {
        "appId": "LXB-673-APP-1",
        "appName": "Laxmi Bank Visa Credit Card (426501) - NPR"
    },
    {
        "appId": "KBL-30-APP-2",
        "appName": "Kumari Bank VISA Credit Card (New)-NPR"
    },
    {
        "appId": "NIB-516-APP-2",
        "appName": "NIBL Visa Credit Card Classic - NPR"
    },
    {
        "appId": "NIB-516-APP-4",
        "appName": "NIBL Visa Credit Card Classic (Contactless)- NPR"
    },
    {
        "appId": "CRD-99-NAB-2",
        "appName": "Nabil Bank Credit Card NPR - Mastercard"
    },
    {
        "appId": "CRD-99-NAB-1",
        "appName": "Nabil Bank Credit Card NPR - Visa"
    }
];
const creditcardListStaging = [
    {
        "appId": "MER-989-APP-1",
        "appName": "Nepal Bank Recruitment"
    },
    {
        "appId": "KBL-30-APP-1",
        "appName": "Kumari Bank VISA Credit Card - NPR"
    },
    {
        "appId": "NIC-12-APP-1",
        "appName": "NIC Asia Visa Credit Card - NPR"
    },
    {
        "appId": "MER-89-PTC-2",
        "appName": "Prabhu Technology Pvt. Ltd. (Prabhu PAY)"
    }
];

const collegeListLive = [
    {
        "appId": "MER-454-APP-1",
        "appName": "Nepalgunj Health Campus - Gosaigaun,Nepalgunj"
    },
    {
        "appId": "MER-674-APP-2",
        "appName": "Global College of Science , Baneshwor"
    },
    {
        "appId": "MER-506-APP-2",
        "appName": "Nepalaya College"
    },
    {
        "appId": "MER-427-APP-1",
        "appName": "Naaya Aayam Multi-Disciplinary Institute Ltd."
    },
    {
        "appId": "MER-425-APP-1",
        "appName": "Patan College for Professional Studies Pvt Ltd - Lalitpur Nepal"
    },
    {
        "appId": "MER-367-APP-1",
        "appName": "The British College Pvt. Ltd. - Thapathali"
    },
    {
        "appId": "MER-674-APP-1",
        "appName": "Global College of Management, Baneshwor"
    },
    {
        "appId": "MER-289-APP-1",
        "appName": "Herald College Pvt.Ltd. - Handigaun"
    },
    {
        "appId": "MER-542-APP-1",
        "appName": "Khwopa Engineering College - Libali, Bhaktapur"
    },
    {
        "appId": "MER-508-APP-1",
        "appName": "Little Angels College - Hattiban, Lalitpur"
    },
    {
        "appId": "MER-543-APP-1",
        "appName": "Khwopa College of Engineering - Libali, Bhaktapur"
    },
    {
        "appId": "MER-392-APP-1",
        "appName": "Lord Buddha Education Foundation - Maitidevi"
    },
    {
        "appId": "MER-311-APP-1",
        "appName": "Informatics College Pokhara-Matepani, Pokhara"
    },
    {
        "appId": "MER-110-APP-1",
        "appName": "Sunway International Business School - Maitidevi Kathmandu"
    },
    {
        "appId": "MER-445-APP-1",
        "appName": "St. Xaviers college"
    },
    {
        "appId": "MER-401-APP-1",
        "appName": "College for Professional Studies Pvt. Ltd, Maitidevi, Kathmandu"
    },
    {
        "appId": "MER-211-APP-1",
        "appName": "Acme Engineering College - Sitapaila, Kathmandu"
    },
    {
        "appId": "MER-451-APP-1",
        "appName": "Nobel College - Sinamangal, Kathmandu"
    },
    {
        "appId": "MER-700-APP-1",
        "appName": "KU-School of Education"
    },
    {
        "appId": "MER-356-APP-1",
        "appName": "NIMS COLLEGE PVT.LTD. - Lagankhel"
    },
    {
        "appId": "MER-222-APP-1",
        "appName": "Kantipur City College Pvt. Ltd."
    },
    {
        "appId": "MER-469-APP-1",
        "appName": "Itahari International College - Itahari"
    },
    {
        "appId": "MER-701-APP-1",
        "appName": "KU-School of Management"
    },
    {
        "appId": "MER-486-APP-1",
        "appName": "Southwestern State College-Basundhara"
    },
    {
        "appId": "MER-520-APP-1",
        "appName": "Oxford Advanced Engineering College Pvt. Ltd."
    },
    {
        "appId": "MER-488-APP-1",
        "appName": "Virinchi College International Pvt. Ltd-lalitpur"
    },
    {
        "appId": "MER-164-APP-1",
        "appName": "Kathmandu College Of Management - Balkumari Lalitpur"
    },
    {
        "appId": "MER-168-APP-1",
        "appName": "Kathmandu Don Bosco College - Lazimpat, Kathmandu"
    },
    {
        "appId": "MER-503-APP-1",
        "appName": "Liverpool Education Network - Kathmandu"
    },
    {
        "appId": "MER-563-APP-1",
        "appName": "Prime College - Nayabazar, Kathmandu"
    },
    {
        "appId": "MER-538-APP-1",
        "appName": "Little Buddha College of Health Science - Buddhanagar"
    },
    {
        "appId": "MER-419-APP-1",
        "appName": "CENTER FOR SPACE SCIENCE AND GEOMATICS STUDIES"
    },
    {
        "appId": "MER-180-APP-1",
        "appName": "UniGlobe College, Baneshor, Kathmandu"
    },
    {
        "appId": "MER-703-APP-1",
        "appName": "KU-School of Science"
    },
    {
        "appId": "MER-175-APP-1",
        "appName": "Islington College Pvt. Ltd.- Kamalpokhari, Kathmandu"
    },
    {
        "appId": "MER-183-APP-2",
        "appName": "Texas Intl. Edu Network Pvt. Ltd, Chabahil kathmandu"
    },
    {
        "appId": "MER-193-APP-1",
        "appName": "Aayam International College - Kumaripati Lalitpur"
    },
    {
        "appId": "MER-192-APP-1",
        "appName": "Chakrabarti Habi Education Academy - Madhayapur Thimi"
    },
    {
        "appId": "MER-194-APP-2",
        "appName": "Nepal Mega College & Research Center Pvt. Ltd. , Babarmahal, Kathmandu"
    },
    {
        "appId": "MER-252-APP-1",
        "appName": "National College of Computer Studies, Paknajol, Kathmandu"
    },
    {
        "appId": "MER-253-APP-1",
        "appName": "NCCS Higher Secondary School, Paknajol, Kathmandu"
    },
    {
        "appId": "MER-283-APP-1",
        "appName": "Xavier Academy - Lazimpat"
    },
    {
        "appId": "MER-307-APP-1",
        "appName": "Biratnagar International College Pvt.Ltd - Biratnagar"
    },
    {
        "appId": "MER-473-APP-1",
        "appName": "Lamki Multiple Campus - Kailali"
    },
    {
        "appId": "MER-588-APP-1",
        "appName": "Sagarmatha Engineering College"
    },
    {
        "appId": "MER-587-APP-1",
        "appName": "Sagarmatha College of Science & Technology - Sanepa"
    },
    {
        "appId": "MER-696-APP-1",
        "appName": "Kathmandu University"
    },
    {
        "appId": "MER-697-APP-1",
        "appName": "KU school of Law, Dhulikhel"
    },
    {
        "appId": "MER-699-APP-1",
        "appName": "KU-School of Arts"
    },
    {
        "appId": "MER-702-APP-1",
        "appName": "KU-School of Engineering"
    }
];
const collegeListStaging = [
    {
        "appId": "MER-425-APP-1",
        "appName": "Patan College for Professional Studies Pvt Ltd - Lalitpur Nepal"
    },
    {
        "appId": "MER-427-APP-1",
        "appName": "Naaya Aayam Multi-Disciplinary Institute Ltd."
    },
    {
        "appId": "MER-401-APP-1",
        "appName": "Yeti Airlines"
    },
    {
        "appId": "MER-110-APP-1",
        "appName": "Sunway International Business School - Maitidevi Kathmandu"
    },
    {
        "appId": "MER-451-APP-1",
        "appName": "Techrida"
    },
    {
        "appId": "MER-180-WAL-1",
        "appName": "smartcard"
    },
    {
        "appId": "MER-367-APP-1",
        "appName": "The British College Pvt. Ltd. - Thapathali"
    },
    {
        "appId": "MER-289-APP-1",
        "appName": "Herald College Pvt.Ltd. - Handigaun"
    },
    {
        "appId": "MER-542-APP-1",
        "appName": "Khwopa Engineering College - Libali, Bhaktapur"
    },
    {
        "appId": "MER-508-APP-1",
        "appName": "Little Angels College - Hattiban, Lalitpur"
    },
    {
        "appId": "MER-543-APP-1",
        "appName": "Khwopa College of Engineering - Libali, Bhaktapur"
    },
    {
        "appId": "MER-392-APP-1",
        "appName": "Lord Buddha Education Foundation - Maitidevi"
    },
    {
        "appId": "MER-311-APP-1",
        "appName": "Informatics College Pokhara-Matepani, Pokhara"
    },
    {
        "appId": "MER-445-APP-1",
        "appName": "St. Xaviers college"
    },
    {
        "appId": "MER-211-APP-1",
        "appName": "Acme Engineering College - Sitapaila, Kathmandu"
    },
    {
        "appId": "MER-356-APP-1",
        "appName": "NIMS COLLEGE PVT.LTD. - Lagankhel"
    },
    {
        "appId": "MER-222-APP-1",
        "appName": "Kantipur City College Pvt. Ltd."
    },
    {
        "appId": "MER-488-APP-1",
        "appName": "Virinchi College International Pvt. Ltd-lalitpur"
    },
    {
        "appId": "MER-164-APP-1",
        "appName": "Kathmandu College Of Management - Balkumari Lalitpur"
    },
    {
        "appId": "MER-168-APP-1",
        "appName": "Kathmandu Don Bosco College - Lazimpat, Kathmandu"
    },
    {
        "appId": "MER-194-APP-1",
        "appName": "Prabhu Capital"
    },
    {
        "appId": "MER-503-APP-1",
        "appName": "Liverpool Education Network - Kathmandu"
    },
    {
        "appId": "MER-563-APP-1",
        "appName": "Prime College - Nayabazar, Kathmandu"
    },
    {
        "appId": "MER-538-APP-1",
        "appName": "Little Buddha College of Health Science - Buddhanagar"
    },
    {
        "appId": "MER-419-APP-1",
        "appName": "CENTER FOR SPACE SCIENCE AND GEOMATICS STUDIES"
    },
    {
        "appId": "MER-486-APP-1",
        "appName": "Southwestern State College-Basundhara"
    },
    {
        "appId": "MER-520-APP-1",
        "appName": "Oxford Advanced Engineering College Pvt. Ltd."
    },
    {
        "appId": "MER-454-APP-1",
        "appName": "Nepalgunj Health Campus - Gosaigaun,Nepalgunj"
    },
    {
        "appId": "MER-506-APP-2",
        "appName": "Nepalaya College"
    },
    {
        "appId": "MER-469-APP-1",
        "appName": "CloudTreez"
    },
    {
        "appId": "MER-175-APP-1",
        "appName": "Islington College Pvt. Ltd.- Kamalpokhari, Kathmandu"
    },
    {
        "appId": "MER-183-APP-2",
        "appName": "Texas Intl. Edu Network Pvt. Ltd, Chabahil kathmandu"
    },
    {
        "appId": "MER-192-APP-1",
        "appName": "Chakrabarti Habi Education Academy - Madhayapur Thimi"
    },
    {
        "appId": "MER-193-APP-1",
        "appName": "Buddha Air"
    },
    {
        "appId": "MER-252-APP-1",
        "appName": "National College of Computer Studies, Paknajol, Kathmandu"
    },
    {
        "appId": "MER-283-APP-1",
        "appName": "Xavier Academy - Lazimpat"
    },
    {
        "appId": "MER-307-APP-1",
        "appName": "Biratnagar International College Pvt.Ltd - Biratnagar"
    },
    {
        "appId": "MER-473-APP-1",
        "appName": "Lamki Multiple Campus - Kailali"
    },
    {
        "appId": "MER-253-APP-1",
        "appName": "NCCS Higher Secondary School, Paknajol, Kathmandu"
    },
    {
        "appId": "MER-588-APP-1",
        "appName": "Sagarmatha Engineering College"
    },
    {
        "appId": "MER-587-APP-1",
        "appName": "Sagarmatha College of Science & Technology - Sanepa"
    }
];

const schoolListLive = [
    {
        "appId": "MER-315-APP-1",
        "appName": "Gautam Buddha Memorial Sec. School-Ranimills, Biratnagar"
    },
    {
        "appId": "MER-314-APP-1",
        "appName": "SHAMS Academy Sunsari Pvt. Ltd."
    },
    {
        "appId": "MER-317-APP-1",
        "appName": "NIMS College-Lagankhel"
    },
    {
        "appId": "MER-308-APP-1",
        "appName": "Ganesh Secondary School - Bode"
    },
    {
        "appId": "MER-275-APP-1",
        "appName": "Active English School - Bhaktapur Thimi"
    },
    {
        "appId": "MER-463-APP-1",
        "appName": "Rato Bangla School - Patan Dhoka"
    },
    {
        "appId": "MER-510-APP-1",
        "appName": "Ideal Model School"
    },
    {
        "appId": "MER-472-APP-1",
        "appName": "NEPAL DONBOSCO SCHOOL -  Lubhu, Lalitpur"
    },
    {
        "appId": "MER-433-APP-1",
        "appName": "SHREE LITTLE STAR ENGLISH BOARDING SCHOOL - Chitwan"
    },
    {
        "appId": "MER-428-APP-1",
        "appName": "SHEETAL ACADEMY - Kirtipur"
    },
    {
        "appId": "MER-429-APP-1",
        "appName": "SPARKLE INTERNATIONAL SCHOOL PVT.LTD. - Trisuli"
    },
    {
        "appId": "MER-426-APP-1",
        "appName": "Nami College  Pvt. Ltd.-Jorpati"
    },
    {
        "appId": "MER-511-APP-1",
        "appName": "Small Heaven School - Kalyanpur, Chitwan"
    },
    {
        "appId": "MER-449-APP-1",
        "appName": "Nobel Academy"
    },
    {
        "appId": "MER-462-APP-1",
        "appName": "Babylon Education Foundation Pvt Ltd - New Baneshore"
    },
    {
        "appId": "MER-358-APP-1",
        "appName": "Sambriddhi School - Balaju"
    },
    {
        "appId": "MER-353-APP-1",
        "appName": "MAHAN SIDDHARTHA HIGH SCHOOL - Kathmandu"
    },
    {
        "appId": "MER-515-APP-1",
        "appName": "Joseph High School Pvt. Ltd. Milanchowk, Kathmandu"
    },
    {
        "appId": "MER-509-APP-1",
        "appName": "St. Xaviers School - Jawalakhel"
    },
    {
        "appId": "MER-377-APP-1",
        "appName": "CREATIVE LEARNERS ACADEMY-BHAKTAPUR"
    },
    {
        "appId": "MER-382-APP-1",
        "appName": "VINAYAK SHIKSHA NIKETAN SEC ENGLISH SCHOOL - Chapacho,  Bhaktapur"
    },
    {
        "appId": "MER-324-APP-1",
        "appName": "Ullens Education Foundation - Khumaltar"
    },
    {
        "appId": "MER-165-APP-1",
        "appName": "Golden Peak High School - Sarswotinagar Kathmandu"
    },
    {
        "appId": "MER-166-APP-1",
        "appName": "Greenland Academy Kathmandu Pvt. Ltd. - Tokha,Kathmandu"
    },
    {
        "appId": "MER-477-APP-1",
        "appName": "Valley Public School Pvt. Ltd. - Lamatar"
    },
    {
        "appId": "MER-402-APP-1",
        "appName": "Akshar Vidhya Mandir Janakpur Pvt. Ltd, Janakpur"
    },
    {
        "appId": "MER-478-APP-1",
        "appName": "International Joseph Public School Pvt Ltd - Kapan"
    },
    {
        "appId": "MER-191-APP-1",
        "appName": "Euro Kids Nepal Pvt. Ltd. - Thapathali, Kathmandu"
    },
    {
        "appId": "MER-381-APP-1",
        "appName": "EVEREST PUBLIC ACADEMY BHAKTAPUR PVT. LTD. - Bhaktapur"
    },
    {
        "appId": "MER-383-APP-1",
        "appName": "SWOPNA SANSAR PRE SCHOOL PVT. LTD - Bode, Bhaktapur"
    },
    {
        "appId": "MER-448-APP-1",
        "appName": "Blooming Buds Boarding School Pvt Ltd. -  Bardaghat"
    },
    {
        "appId": "MER-269-APP-1",
        "appName": "International Lucky English School - Kanchanpur"
    },
    {
        "appId": "MER-412-APP-1",
        "appName": "EDEN'S ACADEMY KASKI PVT LTD - Pokhara"
    },
    {
        "appId": "MER-298-APP-1",
        "appName": "Radiant Public Boarding School - Syuchatar"
    },
    {
        "appId": "MER-479-APP-1",
        "appName": "Galaxy Public School"
    },
    {
        "appId": "MER-470-APP-1",
        "appName": "Presidential Business School - Thapagaun, Kathmandu"
    },
    {
        "appId": "MER-487-APP-1",
        "appName": "Meridian International School Pvt. Ltd.-Baluwatar"
    },
    {
        "appId": "MER-677-APP-1",
        "appName": "Blue Bird Higher Secondary School"
    },
    {
        "appId": "MER-675-APP-1",
        "appName": "Marigold Secondary School, Birgunj"
    },
    {
        "appId": "MER-489-APP-1",
        "appName": "Imperial World School Pvt. Ltd-Hattigauda"
    },
    {
        "appId": "MER-501-APP-1",
        "appName": "Little Angels School - Hattiban, Lalitpur"
    },
    {
        "appId": "MER-490-APP-1",
        "appName": "St. Xavier's School, Godavari"
    },
    {
        "appId": "MER-575-APP-1",
        "appName": "Triyog Higher Secondary School Pvt Ltd - Dhapasi, Kathmandu"
    },
    {
        "appId": "MER-491-APP-1",
        "appName": "DAV Sushil Kedia Vishwa Bharati Pvt. Trust - Dhobighat"
    },
    {
        "appId": "MER-183-APP-1",
        "appName": "Texas Intl. Edu Network Pvt. Ltd, Chabahil kathmandu"
    },
    {
        "appId": "MER-492-APP-1",
        "appName": "St. Mary's S. School - Jawalakhel, Lalitpur"
    },
    {
        "appId": "MER-563-APP-2",
        "appName": "Prime International School - Nayabazar, Kathmandu"
    },
    {
        "appId": "MER-414-APP-1",
        "appName": "SATYAWATI ACADEMY KAILALI PVT LTD - Kailali"
    },
    {
        "appId": "MER-413-APP-1",
        "appName": "DOVAN ENGLISH BOARDING SCHOOL - Tanahun"
    },
    {
        "appId": "MER-420-APP-1",
        "appName": "SHRISTI ENGLISH SCHOOL KATHMANDU PVT.LTD. - Budhanilkhantha-3"
    },
    {
        "appId": "MER-422-APP-1",
        "appName": "GAUTAM BUDDHA COLLEGE OF TECHNICAL EDUCATION PVT LTD-Siddharthanagar,Bhairahawa"
    },
    {
        "appId": "MER-423-APP-1",
        "appName": "ALPHA BOARDING SCHOOL PVT LTD - Lamachaur"
    },
    {
        "appId": "MER-458-APP-1",
        "appName": "Gyanodaya Vidhya Niketan Eng. Med. School - Nepalgunj"
    },
    {
        "appId": "MER-453-APP-1",
        "appName": "United Educational Academy Pvt. Ltd- Muktipur, Nepalgunj"
    },
    {
        "appId": "MER-452-APP-1",
        "appName": "GEMS School - Dhapakhel, Lalitpur"
    },
    {
        "appId": "MER-506-APP-1",
        "appName": "Nepalaya Education Foundation "
    },
    {
        "appId": "MER-178-APP-1",
        "appName": "Parijat English Boarding School - Bangai, Kapilvastu"
    },
    {
        "appId": "MER-184-APP-1",
        "appName": "New Environment Academy - Tilottama-5  Manigram"
    },
    {
        "appId": "MER-179-APP-1",
        "appName": "Pathshala Nepal Foundation, Baneshor Kathmandu"
    },
    {
        "appId": "MER-182-APP-1",
        "appName": "National Creative Co-educational School,Chandol Kathmandu"
    },
    {
        "appId": "MER-185-APP-1",
        "appName": "Paragon English School - Tilottoma-6 Rupandehi"
    },
    {
        "appId": "MER-186-APP-1",
        "appName": "Pathshala Nepal Foundation - Bagdol Lalitpur"
    },
    {
        "appId": "MER-181-APP-1",
        "appName": "Vidhya Sagar Educational Foundation, Banepa Kavre"
    },
    {
        "appId": "MER-273-APP-1",
        "appName": "Eden Bridge Academy Tokha - 4  Krishnamandir"
    },
    {
        "appId": "MER-278-APP-1",
        "appName": "Little Flower English Boarding School - Rupandehi"
    },
    {
        "appId": "MER-286-APP-1",
        "appName": "Sun Model English Boarding School - Kanchan"
    },
    {
        "appId": "MER-288-APP-1",
        "appName": "Rhododendron Int'l Higher Sec School - Thankot"
    },
    {
        "appId": "MER-585-APP-1",
        "appName": "Indreni Balbatika - Baluwatar"
    },
    {
        "appId": "MER-678-APP-1",
        "appName": "Ugrachandi Boarding School Pvt Ltd"
    },
    {
        "appId": "MER-595-APP-1",
        "appName": "Budhanilkantha School"
    },
    {
        "appId": "MER-624-APP-1",
        "appName": "Nisarga Batika School, Rabi Bhawan, Kathmandu"
    },
    {
        "appId": "MER-630-APP-1",
        "appName": "Kathmandu Model Higher Secondary School"
    },
    {
        "appId": "MER-652-APP-1",
        "appName": "Sainik Awasiya Ma.Vi Fee - Pokhara"
    },
    {
        "appId": "MER-663-APP-1",
        "appName": "Occidental Public School"
    },
    {
        "appId": "MER-489-APP-2",
        "appName": "Imperial World School Pvt. Ltd-Hattigauda"
    },
    {
        "appId": "MER-668-APP-1",
        "appName": "Nagarjuna Academy Pvt Ltd."
    },
    {
        "appId": "MER-309-APP-1",
        "appName": "Gyan Tara English School-Degamana,Bhaktapur"
    },
    {
        "appId": "MER-685-APP-1",
        "appName": "Valmiki Siksha Sadan Pvt. Ltd,Bharatpur"
    },
    {
        "appId": "MER-686-APP-1",
        "appName": "Riviera International Academy"
    },
    {
        "appId": "MER-173-APP-1",
        "appName": "Kathmandu Valley School & College - Chauni, Kathmandu"
    }
];
const schoolListStaging = [
    {
        "appId": "MER-426-APP-1",
        "appName": "Nami College  Pvt. Ltd.-Jorpati"
    },
    {
        "appId": "MER-429-APP-1",
        "appName": "Shiran"
    },
    {
        "appId": "MER-511-APP-1",
        "appName": "Small Heaven School - Kalyanpur, Chitwan"
    },
    {
        "appId": "MER-509-APP-1",
        "appName": "St. Xaviers School - Jawalakhel"
    },
    {
        "appId": "MER-165-APP-1",
        "appName": "Golden Peak High School - Sarswotinagar Kathmandu"
    },
    {
        "appId": "MER-173-APP-1",
        "appName": "Kathmandu Valley School & College - Chauni, Kathmandu"
    },
    {
        "appId": "MER-517-APP-1",
        "appName": "Malika Incorp."
    },
    {
        "appId": "MER-453-APP-1",
        "appName": "Nepalfix"
    },
    {
        "appId": "MER-269-APP-1",
        "appName": "International Lucky English School - Kanchanpur"
    },
    {
        "appId": "MER-381-APP-1",
        "appName": "NAC Purchase Request Entry"
    },
    {
        "appId": "MER-381-APP-2",
        "appName": "NAC Unit Purchase"
    },
    {
        "appId": "MER-492-APP-1",
        "appName": "Oho Movies"
    },
    {
        "appId": "MER-449-APP-1",
        "appName": "Nobel Academy"
    },
    {
        "appId": "MER-462-APP-1",
        "appName": "Kards Corp"
    },
    {
        "appId": "MER-184-APP-1",
        "appName": "New Environment Academy - Tilottama-5  Manigram"
    },
    {
        "appId": "MER-402-APP-1",
        "appName": "Bihebari"
    },
    {
        "appId": "MER-505-APP-1",
        "appName": "Nimble Infosys"
    },
    {
        "appId": "MER-505-APP-2",
        "appName": "Nimble Infosys"
    },
    {
        "appId": "MER-487-APP-1",
        "appName": "Meridian International School Pvt. Ltd.-Baluwatar"
    },
    {
        "appId": "MER-501-APP-1",
        "appName": "Little Angels School - Hattiban, Lalitpur"
    },
    {
        "appId": "MER-490-APP-1",
        "appName": "St. Xavier's School, Godavari"
    },
    {
        "appId": "MER-510-APP-1",
        "appName": "Ideal Model School"
    },
    {
        "appId": "MER-477-APP-1",
        "appName": "Valley Public School Pvt. Ltd. - Lamatar"
    },
    {
        "appId": "MER-182-APP-1",
        "appName": "National Creative Co-educational School,Chandol Kathmandu"
    },
    {
        "appId": "MER-185-APP-1",
        "appName": "Paragon English School - Tilottoma-6 Rupandehi"
    },
    {
        "appId": "MER-288-APP-1",
        "appName": "Rhododendron Int'l Higher Sec School - Thankot"
    },
    {
        "appId": "MER-563-APP-2",
        "appName": "Prime International School - Nayabazar, Kathmandu"
    },
    {
        "appId": "MER-308-APP-1",
        "appName": "Online Saathi"
    },
    {
        "appId": "MER-275-APP-1",
        "appName": "Active English School - Bhaktapur Thimi"
    },
    {
        "appId": "MER-485-APP-1",
        "appName": "DmarketNepal"
    },
    {
        "appId": "MER-433-APP-1",
        "appName": "Pasapay"
    },
    {
        "appId": "MER-353-APP-1",
        "appName": "MAHAN SIDDHARTHA HIGH SCHOOL - Kathmandu"
    },
    {
        "appId": "MER-515-APP-1",
        "appName": "Joseph High School Pvt. Ltd. Milanchowk, Kathmandu"
    },
    {
        "appId": "MER-497-APP-1",
        "appName": "iMark"
    },
    {
        "appId": "MER-459-APP-5",
        "appName": "SIP Registration by Login"
    },
    {
        "appId": "MER-459-APP-6",
        "appName": "SIP Payment by Login"
    },
    {
        "appId": "MER-459-APP-1",
        "appName": "NIBL Ace Capital"
    },
    {
        "appId": "MER-459-APP-2",
        "appName": "SIP Registration"
    },
    {
        "appId": "MER-459-APP-3",
        "appName": "SIP Payment"
    },
    {
        "appId": "MER-459-APP-4",
        "appName": "Purchase Req Entry"
    },
    {
        "appId": "MER-595-APP-1",
        "appName": "Budhanilkantha School"
    },
    {
        "appId": "MER-897-APP-1",
        "appName": "Dpaisa"
    },
    {
        "appId": "MER-377-APP-1",
        "appName": "CREATIVE LEARNERS ACADEMY-BHAKTAPUR"
    },
    {
        "appId": "MER-382-APP-1",
        "appName": "Moru"
    },
    {
        "appId": "MER-324-APP-1",
        "appName": "Ullens Education Foundation - Khumaltar"
    },
    {
        "appId": "MER-166-APP-1",
        "appName": "Greenland Academy Kathmandu Pvt. Ltd. - Tokha,Kathmandu"
    },
    {
        "appId": "MER-478-APP-1",
        "appName": "International Joseph Public School Pvt Ltd - Kapan"
    },
    {
        "appId": "MER-383-APP-1",
        "appName": "SWOPNA SANSAR PRE SCHOOL PVT. LTD - Bode, Bhaktapur"
    },
    {
        "appId": "MER-448-APP-1",
        "appName": "Blooming Buds Boarding School Pvt Ltd. -  Bardaghat"
    },
    {
        "appId": "MER-412-APP-1",
        "appName": "PrimeLife"
    },
    {
        "appId": "MER-298-APP-1",
        "appName": "Radiant Public Boarding School - Syuchatar"
    },
    {
        "appId": "MER-479-APP-1",
        "appName": "Galaxy Public School"
    },
    {
        "appId": "MER-470-APP-1",
        "appName": "Presidential Business School - Thapagaun, Kathmandu"
    },
    {
        "appId": "MER-489-APP-1",
        "appName": "Mukundo"
    },
    {
        "appId": "MER-575-APP-1",
        "appName": "Triyog Higher Secondary School Pvt Ltd - Dhapasi, Kathmandu"
    },
    {
        "appId": "MER-491-APP-1",
        "appName": "KMC"
    },
    {
        "appId": "MER-183-APP-1",
        "appName": "Texas Intl. Edu Network Pvt. Ltd, Chabahil kathmandu"
    },
    {
        "appId": "MER-420-APP-1",
        "appName": "SHRISTI ENGLISH SCHOOL KATHMANDU PVT.LTD. - Budhanilkhantha-3"
    },
    {
        "appId": "MER-422-APP-1",
        "appName": "GAUTAM BUDDHA COLLEGE OF TECHNICAL EDUCATION PVT LTD-Siddharthanagar,Bhairahawa"
    },
    {
        "appId": "MER-423-APP-1",
        "appName": "ALPHA BOARDING SCHOOL PVT LTD - Lamachaur"
    },
    {
        "appId": "MER-191-APP-1",
        "appName": "Euro Kids Nepal Pvt. Ltd. - Thapathali, Kathmandu"
    },
    {
        "appId": "MER-315-APP-1",
        "appName": "Gautam Buddha Memorial Sec. School-Ranimills, Biratnagar"
    },
    {
        "appId": "MER-413-APP-1",
        "appName": "Astranix"
    },
    {
        "appId": "MER-452-APP-1",
        "appName": "Kodak Nepal"
    },
    {
        "appId": "MER-428-APP-1",
        "appName": "AllPasal"
    },
    {
        "appId": "MER-414-APP-1",
        "appName": "Online Creative Studio"
    },
    {
        "appId": "MER-314-APP-1",
        "appName": "SHAMS Academy Sunsari Pvt. Ltd."
    },
    {
        "appId": "MER-317-APP-1",
        "appName": "NIMS College-Lagankhel"
    },
    {
        "appId": "MER-309-APP-1",
        "appName": "Gyan Tara English School-Degamana,Bhaktapur"
    },
    {
        "appId": "MER-463-APP-1",
        "appName": "Rato Bangla School - Patan Dhoka"
    },
    {
        "appId": "MER-472-APP-1",
        "appName": "NEPAL DONBOSCO SCHOOL -  Lubhu, Lalitpur"
    },
    {
        "appId": "MER-358-APP-1",
        "appName": "Sambriddhi School - Balaju"
    },
    {
        "appId": "MER-506-APP-1",
        "appName": "Nepalaya Education Foundation "
    },
    {
        "appId": "MER-179-APP-1",
        "appName": "Pathshala Nepal Foundation, Baneshor Kathmandu"
    },
    {
        "appId": "MER-186-APP-1",
        "appName": "Pathshala Nepal Foundation - Bagdol Lalitpur"
    },
    {
        "appId": "MER-181-APP-1",
        "appName": "UG CAKES"
    },
    {
        "appId": "MER-178-APP-1",
        "appName": "Parijat English Boarding School - Bangai, Kapilvastu"
    },
    {
        "appId": "MER-273-APP-1",
        "appName": "Eden Bridge Academy Tokha - 4  Krishnamandir"
    },
    {
        "appId": "MER-278-APP-1",
        "appName": "Little Flower English Boarding School - Rupandehi"
    },
    {
        "appId": "MER-286-APP-1",
        "appName": "Sun Model English Boarding School - Kanchan"
    },
    {
        "appId": "MER-585-APP-1",
        "appName": "Indreni Balbatika - Baluwatar"
    }
];

const citListLive = [
    {
        "appId": "CIT-151-APP-2",
        "appName": "2. House Loan - (आवाश कर्जा)"
    },
    {
        "appId": "CIT-151-APP-3",
        "appName": "3. Easy Loan - (सरल कर्जा)"
    },
    {
        "appId": "CIT-151-APP-1",
        "appName": "1. ESG Retirement Fund (80% Loan) - (क. ब. अ. को. - ८०% सापटी)"
    }
];
const citListStaging = [
    {
        "appId": "CIT-151-APP-3",
        "appName": "Easy Loan - (सरल कर्जा)"
    },
    {
        "appId": "CIT-151-APP-2",
        "appName": "House Loan - (आवाश कर्जा)"
    }
];

const bonusTaxListLive = [
    {
        "appId": "MAR-94-APP-2",
        "appName": "Premier Insurance Bonus Tax-7475"
    }
];
const bonusTaxListStaging = [
    {
        "appId": "MAR-94-APP-2",
        "appName": "Premier Insurance Bonus Tax-7475"
    }
];


const neaListLive=[
    {
        "code": "0",
        "branchcode": "243",
        "branch": "AANBU"
    },
    {
        "code": "0",
        "branchcode": "391",
        "branch": "ACHHAM"
    },
    {
        "code": "0",
        "branchcode": "273",
        "branch": "AMUWA"
    },
    {
        "code": "0",
        "branchcode": "268",
        "branch": "ANARMANI"
    },
    {
        "code": "0",
        "branchcode": "248",
        "branch": "ARGHAKHACHI"
    },
    {
        "code": "0",
        "branchcode": "384",
        "branch": "ARUGHAT"
    },
    {
        "code": "0",
        "branchcode": "345",
        "branch": "ATTARIYA"
    },
    {
        "code": "0",
        "branchcode": "237",
        "branch": "BADEGAUN SDC"
    },
    {
        "code": "0",
        "branchcode": "299",
        "branch": "BAGLUNG"
    },
    {
        "code": "0",
        "branchcode": "381",
        "branch": "BAITADI"
    },
    {
        "code": "0",
        "branchcode": "215",
        "branch": "BALAJU"
    },
    {
        "code": "0",
        "branchcode": "219",
        "branch": "BANESHWOR"
    },
    {
        "code": "0",
        "branchcode": "373",
        "branch": "BANSGADHI"
    },
    {
        "code": "0",
        "branchcode": "399",
        "branch": "BARAHATHAWA"
    },
    {
        "code": "0",
        "branchcode": "267",
        "branch": "BARDAGHAT SDC"
    },
    {
        "code": "0",
        "branchcode": "378",
        "branch": "BARDIBAS"
    },
    {
        "code": "0",
        "branchcode": "277",
        "branch": "Barhabise"
    },
    {
        "code": "0",
        "branchcode": "348",
        "branch": "BELAURI"
    },
    {
        "code": "0",
        "branchcode": "317",
        "branch": "BELBARI"
    },
    {
        "code": "0",
        "branchcode": "339",
        "branch": "BELTAR"
    },
    {
        "code": "0",
        "branchcode": "265",
        "branch": "BHADRAPUR"
    },
    {
        "code": "0",
        "branchcode": "272",
        "branch": "BHAIRAHAWA"
    },
    {
        "code": "0",
        "branchcode": "370",
        "branch": "BHAJANI"
    },
    {
        "code": "0",
        "branchcode": "245",
        "branch": "BHAKTAPUR DC"
    },
    {
        "code": "0",
        "branchcode": "211",
        "branch": "BHARATPUR DC"
    },
    {
        "code": "0",
        "branchcode": "270",
        "branch": "BHIMAN"
    },
    {
        "code": "0",
        "branchcode": "316",
        "branch": "BHOJPUR"
    },
    {
        "code": "0",
        "branchcode": "285",
        "branch": "BIRATNAGAR"
    },
    {
        "code": "0",
        "branchcode": "286",
        "branch": "BIRGUNJ DC"
    },
    {
        "code": "0",
        "branchcode": "301",
        "branch": "BODEBARSAIEN"
    },
    {
        "code": "0",
        "branchcode": "333",
        "branch": "BUDHABARE SDC"
    },
    {
        "code": "0",
        "branchcode": "223",
        "branch": "BUDHANILKANTHA"
    },
    {
        "code": "0",
        "branchcode": "229",
        "branch": "BUTWAL"
    },
    {
        "code": "0",
        "branchcode": "220",
        "branch": "Chabahil"
    },
    {
        "code": "0",
        "branchcode": "315",
        "branch": "CHAINPUR"
    },
    {
        "code": "0",
        "branchcode": "294",
        "branch": "CHANAULI"
    },
    {
        "code": "0",
        "branchcode": "356",
        "branch": "CHANDRANIGAPUR"
    },
    {
        "code": "0",
        "branchcode": "217",
        "branch": "CHAPAGAUN SDC"
    },
    {
        "code": "0",
        "branchcode": "326",
        "branch": "CHAUTARA"
    },
    {
        "code": "0",
        "branchcode": "385",
        "branch": "DADELDHURA"
    },
    {
        "code": "0",
        "branchcode": "350",
        "branch": "DAILEKH"
    },
    {
        "code": "0",
        "branchcode": "280",
        "branch": "DAMAK"
    },
    {
        "code": "0",
        "branchcode": "241",
        "branch": "DAMAULI"
    },
    {
        "code": "0",
        "branchcode": "383",
        "branch": "DARCHULA"
    },
    {
        "code": "0",
        "branchcode": "224",
        "branch": "DHADING"
    },
    {
        "code": "0",
        "branchcode": "344",
        "branch": "DHANGADI"
    },
    {
        "code": "0",
        "branchcode": "284",
        "branch": "DHANKUTA"
    },
    {
        "code": "0",
        "branchcode": "302",
        "branch": "DHANUSHADHAM"
    },
    {
        "code": "0",
        "branchcode": "212",
        "branch": "DHARAN"
    },
    {
        "code": "0",
        "branchcode": "269",
        "branch": "DHARKE"
    },
    {
        "code": "0",
        "branchcode": "320",
        "branch": "DHULABARI SDC"
    },
    {
        "code": "0",
        "branchcode": "375",
        "branch": "DHUNCHE DC"
    },
    {
        "code": "0",
        "branchcode": "397",
        "branch": "DIKTEL"
    },
    {
        "code": "0",
        "branchcode": "274",
        "branch": "DOLAKHA"
    },
    {
        "code": "0",
        "branchcode": "388",
        "branch": "DOTI"
    },
    {
        "code": "0",
        "branchcode": "271",
        "branch": "DUHABI"
    },
    {
        "code": "0",
        "branchcode": "390",
        "branch": "DUMRE"
    },
    {
        "code": "0",
        "branchcode": "308",
        "branch": "FICKEL"
    },
    {
        "code": "0",
        "branchcode": "235",
        "branch": "GAIDAKOT SDC"
    },
    {
        "code": "0",
        "branchcode": "297",
        "branch": "GAIGHAT"
    },
    {
        "code": "0",
        "branchcode": "225",
        "branch": "GAJURI"
    },
    {
        "code": "0",
        "branchcode": "323",
        "branch": "GAUR"
    },
    {
        "code": "0",
        "branchcode": "287",
        "branch": "GAURADAH"
    },
    {
        "code": "0",
        "branchcode": "359",
        "branch": "GAUSALA"
    },
    {
        "code": "0",
        "branchcode": "250",
        "branch": "GHORAHI"
    },
    {
        "code": "0",
        "branchcode": "238",
        "branch": "GORKHA"
    },
    {
        "code": "0",
        "branchcode": "242",
        "branch": "GULARIYA"
    },
    {
        "code": "0",
        "branchcode": "290",
        "branch": "GULMI"
    },
    {
        "code": "0",
        "branchcode": "330",
        "branch": "HANUMAN"
    },
    {
        "code": "0",
        "branchcode": "231",
        "branch": "HETAUDA"
    },
    {
        "code": "0",
        "branchcode": "292",
        "branch": "IILAM"
    },
    {
        "code": "0",
        "branchcode": "281",
        "branch": "INARUWA"
    },
    {
        "code": "0",
        "branchcode": "264",
        "branch": "ITAHARI"
    },
    {
        "code": "0",
        "branchcode": "354",
        "branch": "JAHARE"
    },
    {
        "code": "0",
        "branchcode": "392",
        "branch": "JAJARKOT"
    },
    {
        "code": "0",
        "branchcode": "331",
        "branch": "JALESHOR"
    },
    {
        "code": "0",
        "branchcode": "261",
        "branch": "JANAKPUR DC"
    },
    {
        "code": "0",
        "branchcode": "275",
        "branch": "JIRI"
    },
    {
        "code": "0",
        "branchcode": "386",
        "branch": "JOGBUDA"
    },
    {
        "code": "0",
        "branchcode": "221",
        "branch": "JORPATI"
    },
    {
        "code": "0",
        "branchcode": "318",
        "branch": "KALAIYA"
    },
    {
        "code": "0",
        "branchcode": "380",
        "branch": "KALIKOT"
    },
    {
        "code": "0",
        "branchcode": "351",
        "branch": "KANCHANPUR"
    },
    {
        "code": "0",
        "branchcode": "338",
        "branch": "KATARI"
    },
    {
        "code": "0",
        "branchcode": "247",
        "branch": "KAVRE"
    },
    {
        "code": "0",
        "branchcode": "234",
        "branch": "KAWASOTI"
    },
    {
        "code": "0",
        "branchcode": "306",
        "branch": "Khadbari"
    },
    {
        "code": "0",
        "branchcode": "325",
        "branch": "khajura"
    },
    {
        "code": "0",
        "branchcode": "282",
        "branch": "KHARIDHUNGA"
    },
    {
        "code": "0",
        "branchcode": "246",
        "branch": "KIRTIPUR"
    },
    {
        "code": "0",
        "branchcode": "309",
        "branch": "KNAGAR"
    },
    {
        "code": "0",
        "branchcode": "324",
        "branch": "KOHALPUR"
    },
    {
        "code": "0",
        "branchcode": "205",
        "branch": "KULESHOR"
    },
    {
        "code": "0",
        "branchcode": "216",
        "branch": "LAGANKHEL DC"
    },
    {
        "code": "0",
        "branchcode": "293",
        "branch": "LAHAN"
    },
    {
        "code": "0",
        "branchcode": "379",
        "branch": "LALBANDI"
    },
    {
        "code": "0",
        "branchcode": "251",
        "branch": "LAMAHI"
    },
    {
        "code": "0",
        "branchcode": "332",
        "branch": "Lamjung"
    },
    {
        "code": "0",
        "branchcode": "343",
        "branch": "LAMKI"
    },
    {
        "code": "0",
        "branchcode": "228",
        "branch": "Lekhnath"
    },
    {
        "code": "0",
        "branchcode": "218",
        "branch": "LUBHU SDC"
    },
    {
        "code": "0",
        "branchcode": "307",
        "branch": "LUMBINI"
    },
    {
        "code": "0",
        "branchcode": "222",
        "branch": "MAHARAJGUNJ DC"
    },
    {
        "code": "0",
        "branchcode": "347",
        "branch": "MAHENDRANAGAR"
    },
    {
        "code": "0",
        "branchcode": "376",
        "branch": "MAINAPOKHARI"
    },
    {
        "code": "0",
        "branchcode": "239",
        "branch": "Majuwa"
    },
    {
        "code": "0",
        "branchcode": "305",
        "branch": "MALANGWA"
    },
    {
        "code": "0",
        "branchcode": "396",
        "branch": "MANGALSEN"
    },
    {
        "code": "0",
        "branchcode": "296",
        "branch": "MANTHALI"
    },
    {
        "code": "0",
        "branchcode": "303",
        "branch": "MAULAPUR"
    },
    {
        "code": "0",
        "branchcode": "337",
        "branch": "MELAMCHI"
    },
    {
        "code": "0",
        "branchcode": "334",
        "branch": "Mirchiya"
    },
    {
        "code": "0",
        "branchcode": "310",
        "branch": "MIRMI"
    },
    {
        "code": "0",
        "branchcode": "362",
        "branch": "MUDHE"
    },
    {
        "code": "0",
        "branchcode": "319",
        "branch": "MYAGDI"
    },
    {
        "code": "0",
        "branchcode": "214",
        "branch": "NAXAL"
    },
    {
        "code": "0",
        "branchcode": "313",
        "branch": "NAYAMILL"
    },
    {
        "code": "0",
        "branchcode": "256",
        "branch": "NEPALGUNJ"
    },
    {
        "code": "0",
        "branchcode": "279",
        "branch": "nijgadh"
    },
    {
        "code": "0",
        "branchcode": "232",
        "branch": "Nuwakot"
    },
    {
        "code": "0",
        "branchcode": "355",
        "branch": "OKHALDHUNGA DC"
    },
    {
        "code": "0",
        "branchcode": "263",
        "branch": "PALPA"
    },
    {
        "code": "0",
        "branchcode": "262",
        "branch": "PALUNG"
    },
    {
        "code": "0",
        "branchcode": "335",
        "branch": "PANAUTI"
    },
    {
        "code": "0",
        "branchcode": "249",
        "branch": "PANCHKHAL"
    },
    {
        "code": "0",
        "branchcode": "395",
        "branch": "PANCHTHAR"
    },
    {
        "code": "0",
        "branchcode": "321",
        "branch": "PARASI"
    },
    {
        "code": "0",
        "branchcode": "240",
        "branch": "PARBAT"
    },
    {
        "code": "0",
        "branchcode": "314",
        "branch": "PASHUPATINAGAR"
    },
    {
        "code": "0",
        "branchcode": "382",
        "branch": "PATAN"
    },
    {
        "code": "0",
        "branchcode": "226",
        "branch": "POKHARA DC"
    },
    {
        "code": "0",
        "branchcode": "227",
        "branch": "POKHARA GRAMIN SDC"
    },
    {
        "code": "0",
        "branchcode": "360",
        "branch": "POKHARIYA"
    },
    {
        "code": "0",
        "branchcode": "207",
        "branch": "PULCHOWK"
    },
    {
        "code": "0",
        "branchcode": "357",
        "branch": "PYUTHAN"
    },
    {
        "code": "0",
        "branchcode": "371",
        "branch": "RAJAPUR"
    },
    {
        "code": "0",
        "branchcode": "329",
        "branch": "RAJBIRAJ"
    },
    {
        "code": "0",
        "branchcode": "336",
        "branch": "RAMECHHAP"
    },
    {
        "code": "0",
        "branchcode": "291",
        "branch": "RANGELI"
    },
    {
        "code": "0",
        "branchcode": "288",
        "branch": "RANI SUB DC"
    },
    {
        "code": "0",
        "branchcode": "201",
        "branch": "RATNAPARK DC"
    },
    {
        "code": "0",
        "branchcode": "311",
        "branch": "RIDI"
    },
    {
        "code": "0",
        "branchcode": "387",
        "branch": "ROLPA"
    },
    {
        "code": "0",
        "branchcode": "346",
        "branch": "RUKUM"
    },
    {
        "code": "0",
        "branchcode": "374",
        "branch": "SAKHUWA"
    },
    {
        "code": "0",
        "branchcode": "349",
        "branch": "SALYAN"
    },
    {
        "code": "0",
        "branchcode": "352",
        "branch": "SANISCHARE SDC"
    },
    {
        "code": "0",
        "branchcode": "322",
        "branch": "SANKHU"
    },
    {
        "code": "0",
        "branchcode": "398",
        "branch": "SILGADHI"
    },
    {
        "code": "0",
        "branchcode": "230",
        "branch": "simara"
    },
    {
        "code": "0",
        "branchcode": "304",
        "branch": "SIMRAUNGADH"
    },
    {
        "code": "0",
        "branchcode": "276",
        "branch": "SINDHU"
    },
    {
        "code": "0",
        "branchcode": "233",
        "branch": "SINDHULI"
    },
    {
        "code": "0",
        "branchcode": "312",
        "branch": "SIRAHA"
    },
    {
        "code": "0",
        "branchcode": "298",
        "branch": "SOLU"
    },
    {
        "code": "0",
        "branchcode": "327",
        "branch": "SURAJPURA"
    },
    {
        "code": "0",
        "branchcode": "353",
        "branch": "SURKHET"
    },
    {
        "code": "0",
        "branchcode": "358",
        "branch": "SURUNGA"
    },
    {
        "code": "0",
        "branchcode": "278",
        "branch": "SYANGJA"
    },
    {
        "code": "0",
        "branchcode": "236",
        "branch": "TANDI"
    },
    {
        "code": "0",
        "branchcode": "377",
        "branch": "TATOPANI"
    },
    {
        "code": "0",
        "branchcode": "283",
        "branch": "TAULIHAWA"
    },
    {
        "code": "0",
        "branchcode": "341",
        "branch": "TEHRATHUM"
    },
    {
        "code": "0",
        "branchcode": "244",
        "branch": "THIMI DC"
    },
    {
        "code": "0",
        "branchcode": "342",
        "branch": "TIKAPUR"
    },
    {
        "code": "0",
        "branchcode": "328",
        "branch": "TRIVENI"
    },
    {
        "code": "0",
        "branchcode": "252",
        "branch": "TULSIPUR"
    },
    {
        "code": "0",
        "branchcode": "363",
        "branch": "TUMLINGTAR"
    },
    {
        "code": "0",
        "branchcode": "295",
        "branch": "URLABARI"
    },
    {
        "code": "0",
        "branchcode": "372",
        "branch": "VURIGAUN"
    },
    {
        "code": "0",
        "branchcode": "389",
        "branch": "YADUKUWA"
    }
];

const khanepaniListLive = {

    "2": "Bardibas",
    "3": "Biratnagar",
    "4": "Birgunj",
    "5": "Charali",
    "6": "Dhangadi",
    "7": "Dhankuta",
    "8": "Dhulabari",
    "9": "Itahari",
    "10": "Kakarvitta",
    "11": "Nepalgunj",
    "12": "Urlabari"
};
const khanepaniListStaging = {
    "1": "Attariya",
    "2": "Bardibas",
    "3": "Biratnagar",
    "4": "Birgunj",
    "5": "Charali",
    "6": "Dhangadi",
    "7": "Dhankuta",
    "8": "Dhulabari",
    "9": "Itahari",
    "10": "Kakarvitta",
    "11": "Nepalgunj",
    "12": "Urlabari"
};

const nepalWaterListLive={
    "2": "BIRATNAGAR",
    "11": "BIRGUNJ",
    "15": "BUTWAL",
    "19": "NEPALGUNJ",
    "20": "DHANGADI"
};
const nepalWaterListStaging = {
    "2": "BIRATNAGAR",
    "11": "BIRGUNJ",
    "15": "BUTWAL",
    "19": "NEPALGUNJ",
    "20": "DHANGADI"
};

const AccountLinkBankListLive= [
    {
        "InstitutionName": "Century Bank",
        "InstrumentCode": "CCBNNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S61.png"
    },
    {
        "InstitutionName": "Global IME Bank",
        "InstrumentCode": "GLBBNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302124S33.png"
    },
    {
        "InstitutionName": "Jyoti Bikash Bank",
        "InstrumentCode": "JBBLNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S85.png"
    },
    {
        "InstitutionName": "Kamana Sewa Bikash Bank Ltd",
        "InstrumentCode": "KSKFNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302009S22.png"
    },
    {
        "InstitutionName": "Laxmi Bank",
        "InstrumentCode": "LXBLNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301927S68.png"
    },
    {
        "InstitutionName": "Mega Bank",
        "InstrumentCode": "MBNLNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S82.png"
    },
    {
        "InstitutionName": "Nepal Bank Limited",
        "InstrumentCode": "NEBLNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302008S54.png"
    },
    {
        "InstitutionName": "Nic Asia Bank Limited",
        "InstrumentCode": "NICENPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104301926S99.png"
    },
    {
        "InstitutionName": "Prabhu Bank",
        "InstrumentCode": "PRVUNPKA",
        "LogoUrl": "https://linkaccount.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104302006S69.png"
    }
];
const AccountLinkBankListStaging= [
    {
        "InstitutionName": "Test Bank",
        "InstrumentCode": "FTTESTBANK",
        "LogoUrl": "https://linkaccountsandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202007292355S3.png"
    },
    {
        "InstitutionName": "Kamana Sewa Bikas Bank",
        "InstrumentCode": "KSKFNPKA",
        "LogoUrl": "https://linkaccountsandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202107261410S79.png"
    },
    {
        "InstitutionName": "Test Bank II",
        "InstrumentCode": "TIIBNPKA",
        "LogoUrl": "https://linkaccountsandbox.nepalpayment.com/UploadedImages/PaymentInstitution/LogoUrl-202104011205S44.png"
    }
];


export default {
    bankList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? bankListLive : bankListStaging,
    bankTransferList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? bankTransferListLive : bankTransferListStaging,
    brokerList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? brokerListLive : brokerListStaging,
    meroshareList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? meroshareListLive : meroshareListStaging,
    dematList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? dematListLive : dematListStaging,
    creditCardList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? creditcardListLive : creditcardListStaging,
    collegeList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? collegeListLive : collegeListStaging,
    schoolList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? schoolListLive : schoolListStaging,
    citlList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? citListLive : citListStaging,
    bonusTaxList: (process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? bonusTaxListLive : bonusTaxListStaging,
    khanepaniList:(process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? khanepaniListLive : khanepaniListStaging,
    nepalWaterList:(process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? nepalWaterListLive : nepalWaterListStaging,
    neaList:(process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? neaListLive : neaListLive,
    accountLinkBankList:(process.env.REACT_APP_API_URL === "https://api.icash.com.np/") ? AccountLinkBankListLive : AccountLinkBankListStaging,
}

