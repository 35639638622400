import axios from "../../axios";

export const responseWallet = (balance) => {
  return (dispatch, getState) => {
    axios
      .get(`api/wallet`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then(({ data }) => {
        console.log(data)
        let balance = data.data.balance / 100;
        console.log(balance)
        dispatch({ type: "RESPONSE_WALLET", balance });
      })
      .catch((err) => {
        dispatch({ type: "RESPONSE_WALLET_ERROR" });
      });
  };
};

export const updateWallet = (amount) => {
  return (dispatch, getState) => {
    let balance = amount/100;
    dispatch({ type: "RESPONSE_WALLET", balance });
  };
};
