import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import axios from '../../axios'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { UncontrolledAlert } from 'reactstrap'
import { Link } from 'react-router-dom'
import { colors } from '../../helpers/color'
import SweetAlert from '../SweetAlert'
import { withNamespaces } from 'react-i18next'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class PagesStarter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pin: localStorage.getItem('pin'),
      setPin: false,
      loading: false,
      error: null,
      success: null,
      login: localStorage.getItem('login_pin'),
      transaction: localStorage.getItem('transaction_pin'),
      showForm: false,
      type: null,
      alert: false,
      postData: null,
      setPass: false,
      loginPass: false,
      tranPass: false,
    }
  }
  handleSubmit = async (values) => {
    this.setState({
      loading: true,
      success: null,
      error: null,
    })
    try {
      let setpin = await axios.post(
        `api/pin/save`,
        {
          pin: values.pin,
          password: values.password,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      localStorage.setItem('transaction_pin', true)
      localStorage.setItem('login_pin', true)
      localStorage.setItem('pin', true)
      this.setState({
        pin: 'true',
        login: 'true',
        transaction: 'true',
        success: setpin.data.message,
        setPin: false,
        type: null,
        postData: null,
      })
    } catch (e) {
      if (e.response.data && e.response.data.message) {
        this.setState({
          error: e.response.data.message,
        })
      } else {
        this.setState({
          error: 'Could not set PIN, please try again later',
        })
      }
    }
    this.setState({
      loading: false,
    })
  }

  passwordCheck = async (value) => {
    this.setState({
      loading: true,
      error: null,
      success: null,
    })
    try {
      let data
      if (this.state.type === 'login') {
        data = {
          password: value.password,
          transaction_pin_login: this.state.login === 'true' ? 0 : 1,
        }
      } else {
        data = {
          password: value.password,
          transaction_pin_payment: this.state.transaction === 'true' ? 0 : 1,
        }
      }
      let changeStatus = await axios.post(`api/change-pin-status`, data, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      if (this.state.type === 'login') {
        localStorage.getItem('login_pin') === 'true'
          ? localStorage.setItem('login_pin', false)
          : localStorage.setItem('login_pin', true)
        this.setState({
          login: localStorage.getItem('login_pin'),
          showForm: false,
          success: changeStatus.data.message,
          type: null,
          postData: null,
        })
      } else {
        localStorage.getItem('transaction_pin') === 'true'
          ? localStorage.setItem('transaction_pin', false)
          : localStorage.setItem('transaction_pin', true)
        this.setState({
          transaction: localStorage.getItem('transaction_pin'),
          showForm: false,
          success: changeStatus.data.message,
          postData: null,
          type: null,
        })
      }
    } catch (e) {
      if (e.response.data && e.response.data.message) {
        this.setState({
          error: e.response.data.message,
        })
      } else {
        this.setState({
          error: 'Status not Changed',
        })
      }
    }
    this.setState({
      loading: false,
    })
  }

  submit = (e, values) => {
    e.preventDefault()
    this.setState({
      postData: values,
      alert: true,
    })
  }

  makeVisible = () => {
    if (this.state.type === 'set') {
      this.setState({
        setPass: !this.state.setPass,
      })
    } else if (this.state.type === 'login') {
      this.setState({
        loginPass: !this.state.loginPass,
      })
    } else if (this.state.type === 'transaction') {
      this.setState({
        tranPass: !this.state.tranPass,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t('Home')}
              breadcrumbItem={this.props.t('Security Settings')}
            />
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                {this.state.success ? (
                  <UncontrolledAlert
                    color="success"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.success}
                  </UncontrolledAlert>
                ) : null}
                {this.state.error ? (
                  <UncontrolledAlert
                    color="danger"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.error}
                  </UncontrolledAlert>
                ) : null}
                <Spin spinning={this.state.loading} indicator={antIcon}>
                  <Card
                    outline
                    color={
                      localStorage.getItem('agent') === 'ACCEPTED'
                        ? 'danger'
                        : 'primary'
                    }
                    className="border"
                  >
                    <CardBody>
                      <CardTitle className="mb-4">
                        {this.state.pin === 'false'
                          ? this.props.t('Set')
                          : this.props.t('Reset')}{' '}
                        {this.props.t('Security PIN')}
                      </CardTitle>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  {this.state.pin === 'false' ? 'Set' : 'Reset'}
                                  {this.props.t(' your Security PIN')}
                                </h5>
                                <span>
                                  {this.state.pin === 'false' ? 'Set' : 'Reset'}
                                  {this.props.t(
                                    'a 4-digit security PIN to login and authorize your transactions.',
                                  )}
                                </span>
                              </td>
                              <td>
                                <div className="text-center">
                                  {!this.state.setPin ? (
                                    <button
                                      className="btn badge badge-pill badge-soft-success font-size-13"
                                      style={{
                                        backgroundImage:
                                          localStorage.getItem('agent') ===
                                          'ACCEPTED'
                                            ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                            : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                        color: '#ffff',
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          type: 'set',
                                          setPin: true,
                                        })
                                      }
                                    >
                                      {this.state.pin === 'false'
                                        ? 'Set'
                                        : 'Reset'}{' '}
                                      {this.props.t('Security PIN')}
                                    </button>
                                  ) : (
                                    <button
                                      className="btn badge badge-pill badge-soft-danger font-size-13"
                                      onClick={() =>
                                        this.setState({
                                          setPin: false,
                                          type: null,
                                        })
                                      }
                                    >
                                      {this.props.t('Cancel')}
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {this.state.type === 'set' ? (
                              <div className="p-2">
                                <AvForm
                                  className="form-horizontal"
                                  onValidSubmit={this.submit}
                                  ref={(c) => (this.form = c)}
                                >
                                  <div className="form-group">
                                    <AvField
                                      name="pin"
                                      label={this.props.t('Security PIN')}
                                      type={
                                        this.state.setPass ? 'text' : 'password'
                                      }
                                      placeholder={this.props.t(
                                        'Enter Security PIN',
                                      )}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage:
                                            'Please enter a Security PIN',
                                        },
                                        pattern: {
                                          value: '^[0-9]+$',
                                          errorMessage:
                                            'Security PIN must be composed only with numbers',
                                        },
                                        minLength: {
                                          value: 4,
                                          errorMessage:
                                            'Security PIN must be of 4 digits',
                                        },
                                        maxLength: {
                                          value: 4,
                                          errorMessage:
                                            'Security PIN must be of 4 digits',
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <AvField
                                      name="confirmPin"
                                      label={this.props.t(
                                        'Confirm Security PIN',
                                      )}
                                      type={
                                        this.state.setPass ? 'text' : 'password'
                                      }
                                      placeholder={this.props.t(
                                        'Confirm Security PIN',
                                      )}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage:
                                            'Please enter a Security PIN confirmation',
                                        },
                                        minLength: {
                                          value: 4,
                                          errorMessage:
                                            'Security PIN must be of 4 digits',
                                        },
                                        maxLength: {
                                          value: 4,
                                          errorMessage:
                                            'Security PIN must be of 4 digits',
                                        },
                                        match: {
                                          value: 'pin',
                                          errorMessage:
                                            'Security PINs do not match',
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <AvField
                                      name="password"
                                      label={this.props.t('iCash Password')}
                                      type={
                                        this.state.setPass ? 'text' : 'password'
                                      }
                                      placeholder={this.props.t(
                                        'Enter iCash Password',
                                      )}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage:
                                            'Please enter a Password',
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={this.state.setPass}
                                      onChange={this.makeVisible}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck1"
                                    >
                                      {this.props.t(
                                        'Make password/PIN visible',
                                      )}
                                    </label>
                                  </div>

                                  <div className="mt-3">
                                    <button
                                      className="btn btn-primary btn-block waves-effect waves-light"
                                      type="submit"
                                      style={{
                                        backgroundImage:
                                          localStorage.getItem('agent') ===
                                          'ACCEPTED'
                                            ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                            : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                        borderColor: '#ffff',
                                      }}
                                    >
                                      {this.state.pin === 'false'
                                        ? this.props.t('Set')
                                        : this.props.t('Reset')}{' '}
                                      {this.props.t('Security PIN')}
                                    </button>
                                  </div>
                                </AvForm>
                              </div>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>

                  {this.state.pin === 'true' ? (
                    <Card
                      outline
                      color={
                        localStorage.getItem('agent') === 'ACCEPTED'
                          ? 'danger'
                          : 'primary'
                      }
                      className="border"
                    >
                      <CardBody>
                        <CardTitle className="mb-4">
                          {this.props.t('Security Settings')}
                        </CardTitle>
                        <div className="table-responsive">
                          <table className="table table-nowrap table-centered mb-0">
                            <tbody>
                              <tr>
                                <td>
                                  <h5 className="text-truncate font-size-14 m-0">
                                    {this.props.t('Login with PIN')}
                                  </h5>
                                  <span>
                                    {this.props.t(
                                      'Use your PIN to login to your iCash account',
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <div className="text-center">
                                    <div className="text-center">
                                      <div
                                        className="custom-control custom-switch mb-2"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customSwitch1"
                                          checked={
                                            this.state.login === 'true'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customSwitch1"
                                          onClick={() => {
                                            this.setState({
                                              type: 'login',
                                              setPin: false,
                                            })
                                          }}
                                        >
                                          {this.state.login === 'true'
                                            ? this.props.t('Disable')
                                            : this.props.t('Enable')}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {this.state.type === 'login' ? (
                                <div className="p-2">
                                  <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.submit}
                                    ref={(c) => (this.form = c)}
                                  >
                                    <div className="form-group">
                                      <AvField
                                        name="password"
                                        label={this.props.t('iCash Password')}
                                        type="password"
                                        placeholder={this.props.t(
                                          'Enter iCash Password to Enable/ Disable this setting',
                                        )}
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              'Please enter a Password',
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.loginPass}
                                        onChange={this.makeVisible}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck1"
                                      >
                                        {this.props.t('Make password visible')}
                                      </label>
                                    </div>

                                    <div className="mt-3">
                                      <button
                                        className="btn btn-primary btn-block waves-effect waves-light"
                                        type="submit"
                                        style={{
                                          backgroundImage:
                                            localStorage.getItem('agent') ===
                                            'ACCEPTED'
                                              ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                              : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                          borderColor: '#ffff',
                                        }}
                                      >
                                        {this.props.t('Confirm Password')}
                                      </button>
                                    </div>
                                  </AvForm>
                                </div>
                              ) : null}

                              <tr>
                                <td>
                                  <h5 className="text-truncate font-size-14 m-0">
                                    {this.props.t('Transaction PIN')}
                                  </h5>
                                  <span>
                                    {this.props
                                      .t(`Authorize your every transaction by using the
																		security PIN`)}
                                  </span>
                                </td>
                                <td>
                                  <div className="text-center">
                                    <div
                                      className="custom-control custom-switch mb-2"
                                      dir="ltr"
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch1"
                                        checked={
                                          this.state.transaction === 'true'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customSwitch1"
                                        onClick={() => {
                                          this.setState({
                                            setPin: false,
                                            type: 'transaction',
                                          })
                                        }}
                                      >
                                        {this.state.transaction === 'true'
                                          ? this.props.t('Disable')
                                          : this.props.t('Enable')}
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {this.state.type === 'transaction' ? (
                                <div className="p-2">
                                  <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.submit}
                                    ref={(c) => (this.form = c)}
                                  >
                                    <div className="form-group">
                                      <AvField
                                        name="password"
                                        label={this.props.t('iCash Password')}
                                        type={
                                          this.state.tranPass
                                            ? 'text'
                                            : 'password'
                                        }
                                        placeholder={this.props.t(
                                          'Enter iCash Password to Enable/ Disable this setting',
                                        )}
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              'Please enter a Password',
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={this.state.tranPass}
                                        onChange={this.makeVisible}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck1"
                                      >
                                        {this.props.t('Make password visible')}
                                      </label>
                                    </div>
                                    <div className="mt-3">
                                      <button
                                        className="btn btn-primary btn-block waves-effect waves-light"
                                        type="submit"
                                        style={{
                                          backgroundImage:
                                            localStorage.getItem('agent') ===
                                            'ACCEPTED'
                                              ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                              : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                          borderColor: '#ffff',
                                        }}
                                      >
                                        {this.props.t('Confirm Password')}
                                      </button>
                                    </div>
                                  </AvForm>
                                </div>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}

                  <Card
                    outline
                    color={
                      localStorage.getItem('agent') === 'ACCEPTED'
                        ? 'danger'
                        : 'primary'
                    }
                    className="border"
                  >
                    <CardBody>
                      <CardTitle className="mb-4">Other Settings</CardTitle>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  {this.props.t('Change Password')}
                                </h5>
                                <span>
                                  {this.props.t('Change your login password')}
                                </span>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to="/change-password">
                                    <button
                                      className="btn badge badge-pill badge-soft-success font-size-13"
                                      style={{
                                        backgroundImage:
                                          localStorage.getItem('agent') ===
                                          'ACCEPTED'
                                            ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                            : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                        borderColor: '#ffff',
                                        color: '#ffff',
                                      }}
                                    >
                                      {this.props.t('Change Password')}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  {this.props.t('Device Activity')}
                                </h5>
                                <span>
                                  {this.props.t(
                                    'View all your logged in devices and their activities',
                                  )}
                                </span>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Link to="/devices">
                                    <button
                                      className="btn badge badge-pill badge-soft-success font-size-13"
                                      style={{
                                        backgroundImage:
                                          localStorage.getItem('agent') ===
                                          'ACCEPTED'
                                            ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                            : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                        borderColor: '#ffff',
                                        color: '#ffff',
                                      }}
                                    >
                                      {this.props.t('View My Devices')}
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
              </Col>
            </Row>
            {this.state.alert ? (
              <SweetAlert
                confirm={() => {
                  this.setState({
                    alert: false,
                  })
                  if (this.state.type === 'set') {
                    this.handleSubmit(this.state.postData)
                  } else {
                    this.passwordCheck(this.state.postData)
                  }
                }}
                cancel={() => {
                  this.setState({
                    alert: false,
                  })
                }}
                message={
                  this.state.type === 'set'
                    ? `Are you sure you want to ${
                        localStorage.getItem('pin') === 'false'
                          ? 'set'
                          : 'reset'
                      } your PIN?`
                    : this.state.type === 'login'
                    ? `Are you sure you want to ${
                        localStorage.getItem('login_pin') === 'false'
                          ? 'enable'
                          : 'disable'
                      } your login PIN?`
                    : `Are you sure you want to ${
                        localStorage.getItem('transaction_pin') === 'false'
                          ? 'enable'
                          : 'disable'
                      } your transaction PIN?`
                }
              />
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withNamespaces()(PagesStarter)
