import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  CardTitle,
} from 'reactstrap'
import { message, Tooltip } from 'antd'
import { Redirect } from 'react-router-dom'
// Pages Components
import WelcomeComp from './WelcomeComp'
import Slide from './Slide'
//Import Breadcrumb

import { UncontrolledAlert } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { colors } from '../../helpers/color'
import qr from '../../assets/assets/Quick payment_icons/scan pay.svg'
import bank from '../../assets/assets/Quick payment_icons/bank transfer.svg'
import send from '../../assets/assets/Quick payment_icons/send req.svg'
import Balance from './balance'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          title: 'QR Code to accept payments',
          iconClass: qr,
          description: 'QR Code',
          link: '/qr-code',
        },
        {
          title: 'Transfer your funds into your bank.',
          iconClass: bank,
          description: 'Bank Transfer',
          link: '/bank-deposit',
        },

        {
          title: 'Distribute salary to your employees',
          iconClass: send,
          description: 'Salary',
          link: '/salary-distribution',
        },
      ],
    }
  }

  render() {
    if (!localStorage.getItem('access_token')) {
      return <Redirect to={'/login'} />
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}

            {!localStorage.getItem('kyc_status') ? (
              <UncontrolledAlert
                color="warning"
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className="mdi mdi-alert-outline mr-2"></i>Please fill your
                KYC details in order to get more limit transactions. Click{' '}
                <Link to="/KYC">here</Link> to fill your KYC form.
              </UncontrolledAlert>
            ) : localStorage.getItem('kyc_status') === 'REJECTED' ? (
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className="mdi mdi-block-helper mr-2"></i>Your KYC has been
                rejected, please re-submit your KYC form. Click{' '}
                <Link to="/KYC">here</Link> to fill your KYC form.
              </UncontrolledAlert>
            ) : null}
            {localStorage.getItem('pin') === 'false' ? (
              <UncontrolledAlert
                color="info"
                className="alert-dismissible fade show"
                role="alert"
              >
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Click{' '}
                <Link to="/settings">here</Link> to set a 4-digit security PIN
                to login and authorize your transactions.
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xl="4">
                <WelcomeComp page={'dashboard'} home={true} />
              </Col>
              <Col xl="8">
                {/* Main Payments */}
                <Row>
                  {this.state.reports.map((report, key) => (
                    <Col md="4" key={'_col_' + key}>
                      <Link to={report.link}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="text-muted font-weight-medium">
                                  {report.title}
                                </p>
                                <h4 className="mb-0">{report.description}</h4>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                <span
                                  className="avatar-title"
                                  style={{
                                    backgroundColor:
                                      localStorage.getItem('agent') ===
                                      'ACCEPTED'
                                        ? colors.agent
                                        : colors.blue,
                                  }}
                                >
                                  <img
                                    src={report.iconClass}
                                    alt={report.title}
                                    style={{ width: 20 }}
                                  />
                                </span>
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </Row>
                <Card>
                  <Slide style={{ marginBottom: 10 }} />
                </Card>
                {/* Main Payments */}
              </Col>
            </Row>
            {localStorage.getItem('merchant_type') === 'settlement_bank' ? (
              <Balance />
            ) : null}

            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Your Development Keys
                    </CardTitle>
                    <div className="table-responsive">
                      <table className="table table-nowrap table-centered mb-0">
                        <tbody>
                          <Tooltip
                            placement="topLeft"
                            title="Copy Sandbox key to Clipboard"
                          >
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  localStorage.getItem('vendor_key_sandbox'),
                                )
                                message.success(
                                  `Sandbox key, copied to clipboard`,
                                )
                              }}
                            >
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Sandbox Vendor Key
                                </h5>
                                <span>
                                  Use this vendor key to make Merchant Payments
                                  in the iCash test server.
                                </span>
                              </td>
                              <td>
                                <div className="text-center">
                                  <button
                                    className="btn badge badge-pill badge-soft-success font-size-13"
                                    style={{
                                      backgroundImage: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                      borderColor: '#ffff',
                                      color: '#ffff',
                                    }}
                                  >
                                    {localStorage.getItem('vendor_key_sandbox')}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </Tooltip>
                          <Tooltip
                            placement="bottomLeft"
                            title="Copy Production key to Clipboard"
                          >
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  localStorage.getItem('vendor_key_production'),
                                )
                                message.success(
                                  `Production key, copied to clipboard`,
                                )
                              }}
                            >
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Production Vendor Key
                                </h5>
                                <span>
                                  Use this vendor key to make Merchant Payments
                                  in the iCash production server.
                                </span>
                              </td>
                              <td>
                                <div className="text-center">
                                  <button
                                    className="btn badge badge-pill badge-soft-success font-size-13"
                                    style={{
                                      backgroundImage: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                      borderColor: '#ffff',
                                      color: '#ffff',
                                    }}
                                  >
                                    {localStorage.getItem(
                                      'vendor_key_production',
                                    )}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </Tooltip>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default connect()(Dashboard)
