import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { connect } from 'react-redux'
import { responseWallet } from '../../store/wallet/action'
import 'toastr/build/toastr.min.css'
import { errorResponse } from '../../helpers/errorConfig'
import axios from '../../axios'
import { Table } from 'reactstrap'
import moment from 'moment'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class Topup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      skip: 0,
      limit: 15,
      transactions: [],
    }
  }
  componentDidMount() {
    this.getTransactions(this.state.skip, this.state.limit)
  }

  getTransactions = async (skip, limit) => {
    try {
      let transaction = await axios.get(
        `api/merchant/get-merchant-fund-documentation?skip=${skip}&&limit=${limit}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      let { data } = transaction
      console.log('data', data)
      this.setState({
        transactions: data,
      })
    } catch (e) {
      console.log(e)
      let response = errorResponse(e)
      console.log(response)
    }
    this.setState({
      loading: false,
    })
  }

  onPaginate = async (type) => {
    const { skip, limit } = this.state
    await this.setState({
      loading: true,
      transactions: [],
    })
    let temp = skip
    temp =
      type === 'back'
        ? parseInt(temp) - parseInt(limit)
        : parseInt(temp) + parseInt(limit)

    await this.getTransactions(temp, limit)
    this.setState({
      skip: temp,
    })
  }

  render() {
    const { transactions, loading, skip, limit } = this.state
    let number = skip
    const renderCols = (item, index) => {
      number++
      return (
        <tr key={index}>
          <td>{number}</td>
          <td>{moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss')}</td>
          <td>{item.amount / 100}</td>
          <td>{item.remark}</td>
          <td>{item.status}</td>
          <td>
            {item.status_changed_at
              ? moment(item.status_changed_at).format('YYYY-MM-DD hh:mm:ss')
              : 'None'}
          </td>
        </tr>
      )
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Home"
              breadcrumbItem="Balance Request History"
            />

            <Row className="justify-content-center">
              <Col md={10} lg={10} xl={10}>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2" style={{ marginTop: 16 }}>
                        <Table responsive bordered size="sm">
                          <thead>
                            <tr>
                              <th>S.N</th>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Remarks</th>
                              <th>Status</th>
                              <th>Updated At</th>
                            </tr>
                          </thead>

                          {loading ? (
                            <tbody>
                              <tr>
                                <td colSpan={9}>
                                  <div className="loading" />
                                </td>
                              </tr>
                            </tbody>
                          ) : transactions.length === 0 ? (
                            <tbody>
                              <tr>
                                <td colSpan={9}>No Results found.</td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {transactions &&
                                transactions.map((item, index) =>
                                  renderCols(item, index),
                                )}
                            </tbody>
                          )}
                        </Table>
                        {!loading ? (
                          <div className="text-center">
                            <nav
                              className="d-inline-block"
                              aria-label="Page navigation example"
                            >
                              <ul className="justify-content-center pagination pagination-sm">
                                <li
                                  className={
                                    skip === 0
                                      ? 'disabled page-item'
                                      : 'active page-item'
                                  }
                                >
                                  <button
                                    disabled={skip === 0 ? true : false}
                                    className="prev page-link"
                                    onClick={() => this.onPaginate('back')}
                                  >
                                    {'Back'}
                                  </button>
                                </li>
                                &nbsp;
                                <li
                                  className={
                                    transactions.length < limit
                                      ? 'disabled page-item'
                                      : 'active page-item'
                                  }
                                >
                                  <button
                                    disabled={
                                      transactions.length < limit ? true : false
                                    }
                                    className="next page-link"
                                    onClick={() => this.onPaginate('front')}
                                  >
                                    {'Next'}
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        ) : null}
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    wallet: state.Wallet.wallet,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    responseWallet: () => dispatch(responseWallet()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topup)
