const INIT_STATE = {
	notifications: [],
	unread_count: 0,
};

const KYC = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'UPDATE_NOTIFICATIONS':
			let count = action.values;
			const found = count.filter((element) => element.isRead === false);
			return {
				...state,
				notifications: action.values,
				unread_count: found.length,
			};
		case 'MARK_AS_READ':
			let temp = state.notifications;
			temp[action.values].isRead = true;
			return {
				...state,
				notifications: temp,
				unread_count: state.unread_count - 1,
			};
		default:
			return state;
	}
};

export default KYC;
