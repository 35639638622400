import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import axios from '../../axios'
import { errorResponse } from '../../helpers/errorConfig'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import toastr from 'toastr'
import { connect } from 'react-redux'
import { responseWallet } from '../../store/wallet/action'
import SweetAlert from '../SweetAlert'
import { phoneNumberValidation } from '../../helpers/validations'
import 'toastr/build/toastr.min.css'
import avatarImg from '../../assets/person.png'
import { colors } from '../../helpers/color'
//import PaymentInfo from '../PaymentInfo'
import { withNamespaces } from 'react-i18next'
import TransactionPin from '../TransactionPin'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class Topup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pin: false,
      postData: null,
      alert: false,
      userDetails: null,
    }
  }
  handleSubmit = async (e, values) => {
    e.preventDefault()
    this.setState({
      loading: true,
      postData: values,
    })
    try {
      let check = await axios.post(
        `api/check-user`,
        { mobile_no: values.mobile },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      this.setState({
        userDetails: check.data.data,
      })
    } catch (e) {
      errorResponse(e)
    }
    this.setState({
      loading: false,
    })
  }

  submit = (e) => {
    e.preventDefault()
    if (localStorage.getItem('transaction_pin') === 'true') {
      this.setState({
        pin: true,
      })
    } else {
      this.setState({
        alert: true,
      })
    }
  }

  sendFunds = async () => {
    this.setState({
      loading: true,
    })
    try {
      let send = await axios.post('api/send-fund', this.state.postData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      this.props.responseWallet()
      this.reset()
      toastr.success('Salary sent successfully', 'Success')
      this.props.history.push('/single-transaction/' + send.data.transactionId)
    } catch (e) {
      errorResponse(e)
    }
    this.setState({
      loading: false,
    })
  }

  checkPin = (result) => {
    if (result) {
      this.setState({
        pin: false,
      })
      this.sendFunds()
    }
  }

  reset = () => {
    this.form.reset()
    this.setState({
      pin: false,
      postData: null,
      alert: false,
      userDetails: null,
    })
  }
  render() {
    const phoneValid = (value) => {
      if (phoneNumberValidation(value)) {
        if (value === localStorage.getItem('mobile_no')) {
          return 'You can not Send Salary to yourself.'
        } else {
          return true
        }
      } else {
        return 'Invalid phone number'
      }
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Salary Distribution" />

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Spin indicator={antIcon} spinning={this.state.loading}>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2" style={{ marginTop: 10 }}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={
                            this.state.postData
                              ? this.submit
                              : this.handleSubmit
                          }
                          ref={(c) => (this.form = c)}
                        >
                          <div className="form-group">
                            <AvField
                              name="mobile"
                              label="Mobile Number"
                              type="text"
                              placeholder="Enter Mobile Number"
                              disabled={this.state.postData ? true : false}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter a Mobile Number',
                                },
                                pattern: {
                                  value: '^[0-9]+$',
                                  errorMessage:
                                    'Mobile number must be composed only with numbers',
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage:
                                    'Mobile number must be of 10 digits',
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage:
                                    'Mobile number must be of 10 digits',
                                },
                                phoneValid,
                              }}
                              helpMessage="Please enter a registered iCash Mobile Number"
                            />
                          </div>

                          <div className="form-group">
                            <AvField
                              name="amount"
                              label="Amount"
                              className="form-control"
                              placeholder="Enter Amount to Send"
                              type="text"
                              disabled={this.state.postData ? true : false}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    'Please enter the amount to Send Salary',
                                },
                                min: {
                                  value: 10,
                                  errorMessage:
                                    'Amount to sent must be greater or equal to Rs. 10',
                                },
                                max: {
                                  value: this.props.wallet,
                                  errorMessage:
                                    'Your amount must be less or equal to your wallet balance',
                                },
                                pattern: {
                                  value: '^[0-9]+$',
                                  errorMessage:
                                    'Amount must be a natural number',
                                },
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <AvField
                              name="purpose"
                              label="Purpose"
                              className="form-control"
                              placeholder="Enter Purpose"
                              type="text"
                              disabled={true}
                              value="Salary Distribution"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter your purpose',
                                },
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <AvField
                              name="remark"
                              label="Remarks"
                              className="form-control"
                              placeholder="Enter your Remark"
                              type="text"
                              disabled={this.state.postData ? true : false}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Please enter your remark',
                                },
                              }}
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              style={{
                                backgroundImage:
                                  localStorage.getItem('agent') === 'ACCEPTED'
                                    ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                    : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                borderColor: '#ffff',
                              }}
                            >
                              {this.state.postData
                                ? 'Send Salary'
                                : 'Check User'}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Spin>
              </Col>
              {this.state.userDetails ? (
                <Col md={4} lg={4} xl={4}>
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col
                          xs="12"
                          style={{
                            backgroundImage:
                              localStorage.getItem('agent') === 'ACCEPTED'
                                ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                          }}
                        >
                          <div className="text-primary p-3">
                            <h5 style={{ color: 'white' }}>
                              {this.props.t('User Information')}
                            </h5>
                            <p style={{ color: 'white' }}>
                              {this.props.t(
                                'Please confirm the user before sending salary.',
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="12">
                          <center>
                            <div className="avatar-md profile-user-wid mb-4">
                              <img
                                src={
                                  this.state.userDetails.avatar
                                    ? this.state.userDetails.avatar
                                    : avatarImg
                                }
                                //style={{ marginLeft: 110 }}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                            <h5 className="font-size-15 text-truncate">
                              {this.state.userDetails.name}
                            </h5>
                            <p className="text-muted mb-0 text-truncate">
                              {this.state.userDetails.mobile_no}
                            </p>
                            <p className="text-muted mb-0 text-truncate">
                              {this.state.userDetails.email}
                            </p>
                          </center>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
            </Row>
            {this.state.alert ? (
              <SweetAlert
                confirm={() => {
                  this.setState({
                    alert: false,
                  })
                  this.sendFunds()
                }}
                cancel={() => {
                  this.setState({
                    alert: false,
                  })
                }}
                message={`Send Rs.${this.state.postData.amount} to ${this.state.userDetails.name}?`}
              />
            ) : null}
            <TransactionPin
              visible={this.state.pin}
              closeModal={() =>
                this.setState({
                  pin: false,
                })
              }
              checkPin={this.checkPin}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    wallet: state.Wallet.wallet,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    responseWallet: () => dispatch(responseWallet()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(Topup))
