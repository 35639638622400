export const updateNotifications = (values) => {
	return (dispatch, getState) => {
		dispatch({ type: 'UPDATE_NOTIFICATIONS', values });
	};
};

export const markAsRead = (values) => {
	return (dispatch, getState) => {
		dispatch({ type: 'MARK_AS_READ', values });
	};
};


