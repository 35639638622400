import React, { Component } from 'react'
import { Collapse } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import classname from 'classnames'
//i18n
import { withNamespaces } from 'react-i18next'
import { colors } from '../../helpers/color'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    var matchingMenuItem = null
    var ul = document.getElementById('navigation')
    var items = ul.getElementsByTagName('a')
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active')
    const parent = item.parentElement
    if (parent) {
      parent.classList.add('active') // li
      const parent2 = parent.parentElement
      parent2.classList.add('active') // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add('active') // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add('active') // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add('active') // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add('active') // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav" style={{ backgroundColor: '#ffff' }}>
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/dashboard"
                    >
                      <i
                        className="bx bx-home-circle mr-2"
                        style={{
                          color:
                            localStorage.getItem('agent') === 'ACCEPTED'
                              ? colors.agent
                              : colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {' '}
                        {this.props.t('Home')}
                      </a>
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/load-funds"
                      className="nav-link dropdown-toggle arrow-none"
                      style={{ color: 'black' }}
                    >
                      <i
                        className="bx bx-wallet-alt mr-2"
                        style={{
                          color: colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {this.props.t('Load Funds')}
                      </a>
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/qr-code"
                      className="nav-link dropdown-toggle arrow-none"
                      style={{ color: 'black' }}
                    >
                      <i
                        className="bx bx-scan mr-2"
                        style={{
                          color:
                            localStorage.getItem('agent') === 'ACCEPTED'
                              ? colors.agent
                              : colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {this.props.t('QR Code')}
                      </a>
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/bank-deposit"
                      className="nav-link dropdown-toggle arrow-none"
                      style={{ color: 'black' }}
                    >
                      <i
                        className="bx bxs-bank mr-2"
                        style={{
                          color:
                            localStorage.getItem('agent') === 'ACCEPTED'
                              ? colors.agent
                              : colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {this.props.t('Bank Transfer')}
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      to="/transactions"
                      className="nav-link dropdown-toggle arrow-none"
                      style={{ color: 'black' }}
                    >
                      <i
                        className="bx bx-file mr-2"
                        style={{
                          color:
                            localStorage.getItem('agent') === 'ACCEPTED'
                              ? colors.agent
                              : colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {this.props.t('Statement')}
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ appState: !this.state.appState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                      style={{ color: 'black' }}
                    >
                      <i
                        className="bx bx-transfer mr-2"
                        style={{
                          color:
                            localStorage.getItem('agent') === 'ACCEPTED'
                              ? colors.agent
                              : colors.blue,
                        }}
                      ></i>
                      <a href="/#" style={{ color: 'black' }}>
                        {this.props.t('Balance Request')}
                      </a>
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/request-balance" className="dropdown-item">
                        {this.props.t('Request Balance')}
                      </Link>
                      <Link to="/request-history" className="dropdown-item">
                        {this.props.t('Balance Request History')}
                      </Link>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withNamespaces()(Navbar))
