import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AvForm, AvField } from 'availity-reactstrap-validation'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import axios from '../../axios'
import { colors } from '../../helpers/color'
import SweetAlert from '../SweetAlert'
import { errorResponse } from '../../helpers/errorConfig'
import { withNamespaces } from 'react-i18next'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class EnterAmount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      postData: null,
      alert: false,
    }
  }
  handleSubmit = async (values) => {
    this.setState({
      loading: true,
    })
    try {
      values.particulars = 'icash-particulars'
      values.amount = values.amount * 100
      let load = await axios.post(`api/nchl/process-load-transaction`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      console.log('load', load.data)
      let link = `${
        process.env.REACT_APP_API_URL
      }api/nchl/pay-with-connectIPS?transaction_id=${
        load.data.data.transaction_id
      }&reference_id=${
        load.data.data.reference_id
      }&access_token=${localStorage.getItem('access_token')}`
      window.location = link
    } catch (e) {
      errorResponse(e)
      this.setState({
        loading: false,
      })
    }
  }

  submit = (e, values) => {
    e.preventDefault()
    this.setState({
      alert: true,
      postData: values,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t('Home')}
              breadcrumbItem={this.props.t('Connect IPS')}
            />
            <Spin indicator={antIcon} spinning={this.state.loading}>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Spin indicator={antIcon} spinning={this.state.loading}>
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2" style={{ marginTop: 10 }}>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={this.submit}
                          >
                            <div className="form-group">
                              <AvField
                                name="amount"
                                label={this.props.t('Amount')}
                                className="form-control"
                                placeholder={this.props.t(
                                  'Enter Amount to Load',
                                )}
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: this.props.t(
                                      'Please enter the amount to be loaded',
                                    ),
                                  },
                                  min: {
                                    value: 10,
                                    errorMessage: this.props.t(
                                      'Amount to be loaded must be greater or equal to Rs. 10',
                                    ),
                                  },
                                  pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage: this.props.t(
                                      'Amount must be a natural number',
                                    ),
                                  },
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <AvField
                                name="remarks"
                                label={this.props.t('Purpose/ Remark')}
                                className="form-control"
                                placeholder={this.props.t(
                                  'Enter Purpose/ Remark',
                                )}
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: this.props.t(
                                      'Please enter the amount to be loaded',
                                    ),
                                  },
                                }}
                              />
                            </div>

                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                style={{
                                  backgroundImage:
                                    localStorage.getItem('agent') === 'ACCEPTED'
                                      ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                                      : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                                  borderColor: '#ffff',
                                }}
                              >
                                {this.props.t('Load Amount')}
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Spin>
                </Col>
              </Row>
            </Spin>
            {this.state.alert ? (
              <SweetAlert
                confirm={() => {
                  this.setState({
                    alert: false,
                  })
                  this.handleSubmit(this.state.postData)
                }}
                cancel={() => {
                  this.setState({
                    alert: false,
                  })
                }}
                message={`${this.props.t('Are you sure you want to load')} Rs.${
                  this.state.postData.amount
                }?`}
              />
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withNamespaces()(EnterAmount)
