import React, { Component } from 'react';
import { Row, Col, Alert, Card, CardBody, Container, CardTitle } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../../axios';
// import images
import logoImg from '../../assets/images/i-cash-logo.svg';
import { phoneNumberValidation } from '../../helpers/validations';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ForgetPasswordPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			phase: 0,
			error: null,
			success: null,
			access_token: null,
		};
	}

	// handleValidSubmit
	handleValidSubmit = async (e, values) => {
		e.preventDefault();
		this.setState({
			loading: true,
			error: null,
			success: null,
		});
		try {
			let sendOtp = await axios.post(`api/password/reset-request`, {
				mobile: values.mobile_no,
			});
			console.log('sendOtp', sendOtp.data);
			this.setState({
				success: sendOtp.data.message,
				phase: 1,
			});
		} catch (e) {
			if (e.response.data && e.response.data.message) {
				this.setState({
					error: e.response.data.message,
				});
			} else {
				this.setState({
					error: 'Something went wrong, please try again later',
				});
			}
			console.log('e', e.response);
		}
		this.setState({
			loading: false,
		});
	};

	changePassword = async (e, values) => {
		e.preventDefault();
		this.setState({
			loading: true,
			success: null,
			error: null,
		});
		try {
			await axios.post(
				`api/password/reset`,
				{ password: values.password },
				{ headers: { Authorization: 'Bearer ' + this.state.access_token } }
			);
			this.setState({
				success: 'Your Password has been reset successfully.',
			});
			this.props.history.push('/login');
		} catch (e) {
			if (e.response.data && e.response.data.message) {
				this.setState({
					error: e.response.data.message,
				});
			} else {
				this.setState({
					error: 'Password could not be changed, please try again later',
				});
			}
		}
		this.setState({
			loading: false,
		});
	};

	verifyOtp = async (e, values) => {
		e.preventDefault();
		this.setState({
			loading: true,
			success: null,
			error: null,
		});
		try {
			let verify = await axios.post('api/password/verify-otp', {
				code: values.otp_code,
			});
			console.log('verify', verify.data);
			if (verify.data.access_token) {
				this.setState({
					success: 'OTP Verified successfully',
					access_token: verify.data.access_token,
					phase: 2,
				});
			} else {
				this.setState({
					error: 'OTP not verified, invalid OTP code.',
				});
			}
		} catch (e) {
			console.log('e', e.response);
			if (e.response.data && e.response.data.message) {
				this.setState({
					error: e.response.data.message,
				});
			} else {
				this.setState({
					error: 'Password could not be changed, please try again later',
				});
			}
		}
		this.setState({
			loading: false,
		});
	};

	render() {
		const phoneValid = (value) => {
			if (phoneNumberValidation(value)) {
				return true;
			} else {
				return 'Invalid phone number';
			}
		};
		return (
			<React.Fragment>
				<div className="account-pages my-3 pt-sm-3">
					<Container>
						<center>
							<a href="/">
								<img src={logoImg} alt={'icash_logo'} style={{ marginBottom: 30, width: 160 }} />
							</a>
						</center>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Spin spinning={this.state.loading} indicator={antIcon}>
									<Card className="overflow-hidden">
										<CardBody>
											<CardTitle>
												<h4>Forgot Password</h4>
												<p>
													{this.state.phase === 0
														? 'Enter your registered phone number.'
														: this.state.phase === 1
														? 'Verify your OTP'
														: 'Enter a new password.'}
												</p>
											</CardTitle>

											<div className="p-2">
												{this.state.error ? (
													<Alert color="danger" style={{ marginTop: '13px' }}>
														{this.state.error}
													</Alert>
												) : null}
												{this.state.success ? (
													<Alert color="success" style={{ marginTop: '13px' }}>
														{this.state.success}
													</Alert>
												) : null}

												<AvForm
													className="form-horizontal mt-4"
													onValidSubmit={
														this.state.phase === 0
															? this.handleValidSubmit
															: this.state.phase === 1
															? this.verifyOtp
															: this.changePassword
													}
												>
													{this.state.phase === 0 ? (
														<div className="form-group">
															<AvField
																name="mobile_no"
																label="Mobile Number"
																type="text"
																placeholder="Enter Mobile Number"
																validate={{
																	required: {
																		value: true,
																		errorMessage: 'Please enter a Mobile Number',
																	},
																	pattern: {
																		value: '^[0-9]+$',
																		errorMessage:
																			'Mobile number must be composed only with numbers',
																	},
																	minLength: {
																		value: 10,
																		errorMessage:
																			'Mobile number must be of 10 digits',
																	},
																	maxLength: {
																		value: 10,
																		errorMessage:
																			'Mobile number must be of 10 digits',
																	},
																	phoneValid,
																}}
															/>
														</div>
													) : this.state.phase === 1 ? (
														<div className="form-group">
															<AvField
																name="otp_code"
																label="OTP (One Time Password)"
																type="text"
																placeholder="Enter OTP"
																validate={{
																	required: {
																		value: true,
																		errorMessage: 'Please enter an OTP code',
																	},
																	pattern: {
																		value: '^[0-9]+$',
																		errorMessage:
																			'OTP must be composed only with numbers',
																	},
																}}
															/>
														</div>
													) : (
														<div>
															<div className="form-group">
																<AvField
																	name="password"
																	label="Password"
																	type="password"
																	placeholder="Enter Password"
																	validate={{
																		required: {
																			value: true,
																			errorMessage: 'Please enter a Password',
																		},
																		pattern: {
																			value: '.*[0-9].*',
																			errorMessage:
																				'Password must be composed of alphabets and at least one number',
																		},
																		minLength: {
																			value: 6,
																			errorMessage:
																				'Password must be at least 6 characters',
																		},
																	}}
																/>
															</div>
															<div className="form-group">
																<AvField
																	name="password_confirmation"
																	label="Confirm Password"
																	type="password"
																	placeholder="Confirm Password"
																	validate={{
																		required: {
																			value: true,
																			errorMessage:
																				'Please confirm your Password',
																		},
																		match: {
																			value: 'password',
																			errorMessage: 'Passwords do not match',
																		},
																	}}
																/>
															</div>
														</div>
													)}

													<Row className="form-group">
														<Col className="text-right">
															<button
																className="btn btn-primary w-md waves-effect waves-light"
																type="submit"
																style={{
																	backgroundImage:
																		'linear-gradient(to right,#006BCF,  #00D0F5 )',
																	borderColor: '#ffff',
																}}
															>
																{this.state.phase === 0
																	? 'Send OTP'
																	: this.state.phase === 1
																	? 'Verify OTP'
																	: 'Change Password'}
															</button>
														</Col>
													</Row>
												</AvForm>
											</div>
										</CardBody>
									</Card>
								</Spin>

								<div className="mt-5 text-center">
									<p>
										Go back to{' '}
										<Link to="login" className="font-weight-medium text-primary">
											Login
										</Link>{' '}
									</p>
									<p>
										© {new Date().getFullYear()} iCash
										 {/* Powered by{' '}
										<b>Galaxy International Pvt. Ltd.</b> */}
										{/* <a
											href="http://silkinnovation.com.np"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#7967ad' }}
										>
											Silk Innovation
										</a> */}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(connect()(ForgetPasswordPage));
