import React, { Component } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Row, Col } from 'reactstrap'
import moment from 'moment'
import { connect } from 'react-redux'
import { updateKYCForm } from '../../store/kyc/action'

const adbs = require('ad-bs-converter')
class General extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bs_year: null,
      bs_month: null,
      bs_day: null,
      date_of_birth: null,
    }
  }

  setNepaliDate = (e) => {
    const { value } = e.target

    if (moment(value).get('year') > 1900 && moment(value).get('year') < 2008) {
      let nepaliDate = adbs.ad2bs(moment(value).format('YYYY/MM/DD')).en
      console.log('nepali_date', nepaliDate)
      this.setState({
        bs_year: nepaliDate.year,
        bs_month: nepaliDate.month,
        bs_day: nepaliDate.day,
      })
    } else {
      this.setState({
        bs_year: null,
        bs_month: null,
        bs_day: null,
      })
    }
  }
  bsChange = async (e) => {
    const { name, value } = e.target
    await this.setState({
      [name]: value,
    })
    if (
      this.state.bs_year !== null &&
      this.state.bs_month !== null &&
      this.state.bs_day !== null
    ) {
      let englishDate = adbs.bs2ad(
        moment(
          this.state.bs_year +
            '/' +
            this.state.bs_month +
            '/' +
            this.state.bs_day,
        ).format('YYYY/MM/DD'),
      )
      //console.log('english', englishDate)
      let month = null
      let day = null
      if (englishDate.month.toString().length === 1) {
        month = '0' + englishDate.month
      } else {
        month = englishDate.month
      }
      if (englishDate.day.toString().length === 1) {
        day = '0' + englishDate.day
      } else {
        day = englishDate.day
      }
      let finalEnglish = englishDate.year + '-' + month + '-' + day
      this.setState({
        date_of_birth: moment(finalEnglish)._i,
      })
    }
  }

  yearLoop = () => {
    let year = []
    for (let i = 1990; i < 2064; i++) {
      year.push(<option value={i}>{i}</option>)
    }
    return year
  }
  dayLoop = () => {
    let day = []
    for (let i = 1; i < 33; i++) {
      day.push(<option value={i}>{i}</option>)
    }
    return day
  }

  handleSubmit = (e, values) => {
    e.preventDefault()
    let send = {
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      date_of_birth: values.date_of_birth,
      gender: values.gender,
      occupation: values.occupation,
    }
    this.props.updateKYCForm(send)
    this.props.next()
  }
  render() {
    const dateChange = (value) => {
      var years = moment().diff(value, 'years', 'months')
      if (years >= 16) {
        return true
      } else {
        return 'You must be at least 16 years old.'
      }
    }
    const selectValid = (value) => {
      if (value === '0') {
        return 'This field is required'
      } else {
        return true
      }
    }

    //console.log('date', this.state.date_of_birth)
    return (
      <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
        <Row>
          <Col lg="4">
            <div>
              <AvField
                name="first_name"
                label="First Name"
                type="text"
                placeholder="Enter your First Name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please enter your First Name',
                  },
                  pattern: {
                    value: '^[A-Za-z]+$',
                    errorMessage:
                      'First Name should only contain alphabetical characters with no spaces',
                  },
                }}
              />
            </div>
          </Col>
          <Col lg="4">
            <div>
              <AvField
                name="middle_name"
                label="Middle Name (Optional)"
                type="text"
                placeholder="Enter your Middle Name (Optional)"
                validate={{
                  pattern: {
                    value: '^[A-Za-z]+$',
                    errorMessage:
                      'Middle Name should only contain alphabetical characters with no spaces',
                  },
                }}
              />
            </div>
          </Col>
          <Col lg="4">
            <div>
              <AvField
                name="last_name"
                label="Last Name"
                type="text"
                placeholder="Enter your Last Name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please enter your Last Name',
                  },
                  pattern: {
                    value: '^[A-Za-z]+$',
                    errorMessage:
                      'Last Name should only contain alphabetical characters with no spaces',
                  },
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="3">
            <div>
              <AvField
                name="date_of_birth"
                label="Date of Birth (A.D)"
                type="date"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Date of Birth is required',
                  },
                  dateChange,
                }}
                onChange={this.setNepaliDate}
                value={this.state.date_of_birth}
              />
            </div>
          </Col>
          {/* english date */}

          <Col lg="3">
            <div>
              <AvField
                type="select"
                name="bs_year"
                label="Year (B.S)"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your B.S Year',
                  },
                  selectValid,
                }}
                onChange={this.bsChange}
                value={this.state.bs_year}
              >
                <option value={0}>Select Year</option>
                {this.yearLoop()}
              </AvField>
            </div>
          </Col>
          <Col lg="3">
            <div>
              <AvField
                type="select"
                name="bs_month"
                label="Month (B.S)"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your B.S month',
                  },
                  selectValid,
                }}
                onChange={this.bsChange}
                value={this.state.bs_month}
              >
                <option value={0}>Select Month</option>
                <option value={1}>Biasakh</option>
                <option value={2}>Jesth</option>
                <option value={3}>Ashad</option>
                <option value={4}>Shrawan</option>
                <option value={5}>Bhadra</option>
                <option value={6}>Ashoj</option>
                <option value={7}>Kartik</option>
                <option value={8}>Mangsir</option>
                <option value={9}>Poush</option>
                <option value={10}>Magh</option>
                <option value={11}>Falgun</option>
                <option value={12}>Chaitra</option>
              </AvField>
            </div>
          </Col>
          <Col lg="3">
            <div>
              <AvField
                type="select"
                name="bs_day"
                label="Date (B.S)"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your B.S date',
                  },
                  selectValid,
                }}
                onChange={this.bsChange}
                value={this.state.bs_day}
              >
                <option value={0}>Select Day</option>
                {this.dayLoop()}
              </AvField>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="gender"
                label="Gender"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your gender',
                  },
                  selectValid,
                }}
              >
                <option value={0}>Select Gender</option>
                <option value={'m'}>Male</option>
                <option value={'f'}>Female</option>
                <option value={'o'}>Other</option>
              </AvField>
            </div>
          </Col>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="occupation"
                label="Occupation"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your Occupation',
                  },
                  selectValid,
                }}
              >
                <option value={0}>Select Occupation</option>
                <option value="Salaried">Salaried</option>
                <option value="Self-Employed">Self-Employed</option>
                <option value="Retired">Retired</option>
                <option value="Police/Army">Police/Army</option>
                <option value="House Wife">House Wife</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Student">Student</option>
                <option value="Others">Others</option>
              </AvField>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="3">
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                style={{
                  backgroundImage:
                    'linear-gradient(to right,#006BCF,  #00D0F5 )',
                  borderColor: '#ffff',
                }}
                disabled={this.props.activeTabProgress === 1 ? true : false}
              >
                Previous
              </button>
            </div>
          </Col>
          <Col lg="6"></Col>
          <Col lg="3">
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                style={{
                  backgroundImage:
                    'linear-gradient(to right,#006BCF,  #00D0F5 )',
                  borderColor: '#ffff',
                }}
                disabled={this.props.activeTabProgress === 4 ? true : false}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>
      </AvForm>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateKYCForm: (values) => dispatch(updateKYCForm(values)),
  }
}

export default connect('', mapDispatchToProps)(General)
