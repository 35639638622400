import { takeEvery, fork, put, all } from 'redux-saga/effects'
import { call } from 'redux-saga/effects'
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { loginSuccess, apiError } from './actions'

import axios from '../../../axios'

const sendEmailVerification = async () =>
  await axios
    .post(
      'api/email/resend',
      { email: localStorage.getItem('email') },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
    )
    .then((res) => console.log(res))
    .catch((error) => error)

function* loginUser({ payload: { user, history } }) {
  try {
    console.log('login', user)
    localStorage.setItem('access_token', user.access_token)
    localStorage.setItem('phone_verified', user.user.phone_verified_at)
    localStorage.setItem('email_verified', user.user.email_verified_at)
    localStorage.setItem('name', user.user.name)
    localStorage.setItem('email', user.user.email)
    localStorage.setItem('mobile_no', user.user.mobile_no)
    localStorage.setItem(
      'vendor_key_production',
      user.user.merchant.vendor_key_production,
    )
    localStorage.setItem(
      'vendor_key_sandbox',
      user.user.merchant.vendor_key_sandbox,
    )
    localStorage.setItem('merchant_type', user.user.merchant?.merchant_type)
    localStorage.setItem('pin', user.user.pin)
    if (user.user.kyc_status) {
      localStorage.setItem('kyc_status', user.user.kyc_status)
    }
    localStorage.setItem('login_pin', user.user.pin_status.login_pin)
    localStorage.setItem(
      'transaction_pin',
      user.user.pin_status.transaction_pin,
    )
    if (user.user.avatar) {
      localStorage.setItem('avatar', user.user.avatar)
    } else {
      localStorage.setItem('avatar', null)
    }

    var valid = new Date(user.user.email_verified_at).getTime()

    yield put(loginSuccess(user.user))
    if (user.access_token && user.user.email_verified_at && valid > 0) {
      history.push('/dashboard')
    } else {
      history.push('/email-verification')
      yield call(sendEmailVerification)
    }
  } catch (error) {
    console.log(error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('access_token')
    localStorage.removeItem('name')
    localStorage.removeItem('phone_verified')
    localStorage.removeItem('email_verified')
    localStorage.removeItem('email')
    localStorage.removeItem('mobile_no')
    localStorage.removeItem('pin')
    localStorage.removeItem('kyc')
    localStorage.removeItem('kyc_status')
    localStorage.removeItem('login_pin')
    localStorage.removeItem('transaction_pin')
    localStorage.removeItem('avatar')
    localStorage.removeItem('vendor_key_production')
    localStorage.removeItem('vendor_key_sandbox')
    localStorage.removeItem('merchant_type')
    history.push('/login')
  } catch (error) {
    yield put(apiError(error))
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)])
}

export default authSaga
