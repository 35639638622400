import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  Container,
} from 'reactstrap'

import classnames from 'classnames'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import General from './general'
import Relationship from './relationship'
import Address from './address'
import Documents from './document'
import Company from './company'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from '../../axios'
import { Redirect } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class FormWizard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 20,
      loading: false,
    }
    this.toggleTab.bind(this)
    this.toggleTabProgress.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        })
      }
    }
  }
  next = () => {
    this.setState({
      activeTabProgress: this.state.activeTabProgress + 1,
      progressValue: this.state.progressValue + 25,
    })
  }

  previous = () => {
    this.setState({
      activeTabProgress: this.state.activeTabProgress - 1,
      progressValue: this.state.progressValue - 25,
    })
  }

  uploadKYC = async () => {
    this.setState({
      loading: true,
    })
    try {
      let temp = this.props.kyc
      if (localStorage.getItem('kyc_status') === 'REJECTED') {
        await axios.put(`api/merchant/kyc`, temp, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        })
      } else {
        await axios.post(`api/merchant/kyc`, temp, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        })
      }
      toastr.success('Your KYC Form has been submitted', 'Success')
      localStorage.setItem('kyc_status', 'PROCESSING')
      this.props.history.push('/dashboard')
      window.location.reload(true)
    } catch (e) {
      toastr.error(
        'There seems to be a problem, please try again later',
        'Error',
      )
      console.log('err', e)
    }

    this.setState({
      loading: false,
    })
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        this.setState({
          activeTabProgress: tab,
        })

        if (tab === 1) {
          this.setState({ progressValue: 20 })
        }
        if (tab === 2) {
          this.setState({ progressValue: 40 })
        }
        if (tab === 3) {
          this.setState({ progressValue: 60 })
        }
        if (tab === 4) {
          this.setState({ progressValue: 80 })
        }
        if (tab === 5) {
          this.setState({ progressValue: 80 })
        }
      }
    }
  }

  render() {
    const from = { pathname: '/dashboard' }
    if (
      localStorage.getItem('kyc_status') === 'VALID' ||
      localStorage.getItem('kyc_status') === 'PROCESSING'
    ) {
      return <Redirect to={from} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Home" breadcrumbItem="KYC" />
            <Spin spinning={this.state.loading} indicator={antIcon}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div id="progrss-wizard" className="twitter-bs-wizard">
                        <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 1,
                              })}
                            >
                              <span className="step-number mr-2">01</span>
                              General Information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 2,
                              })}
                            >
                              <span className="step-number mr-2">02</span>
                              <span>Relationship</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 3,
                              })}
                            >
                              <span className="step-number mr-2">03</span>
                              Address Details
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 4,
                              })}
                            >
                              <span className="step-number mr-2">04</span>
                              Documents
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTabProgress === 5,
                              })}
                            >
                              <span className="step-number mr-2">05</span>
                              Company Details
                            </NavLink>
                          </NavItem>
                        </ul>

                        <div id="bar" className="mt-4">
                          <Progress
                            color="success"
                            striped
                            animated
                            value={this.state.progressValue}
                          />
                          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                        </div>
                        <TabContent
                          activeTab={this.state.activeTabProgress}
                          className="twitter-bs-wizard-tab-content"
                        >
                          <TabPane tabId={1}>
                            <General
                              activeTabProgress={this.state.activeTabProgress}
                              next={this.next}
                            />
                          </TabPane>
                          <TabPane tabId={2}>
                            <Relationship
                              activeTabProgress={this.state.activeTabProgress}
                              next={this.next}
                              previous={this.previous}
                            />
                          </TabPane>
                          <TabPane tabId={3}>
                            <Address
                              activeTabProgress={this.state.activeTabProgress}
                              next={this.next}
                              previous={this.previous}
                            />
                          </TabPane>
                          <TabPane tabId={4}>
                            <Documents
                              activeTabProgress={this.state.activeTabProgress}
                              next={this.next}
                              previous={this.previous}
                              //uploadKYC={this.uploadKYC}
                            />
                          </TabPane>
                          <TabPane tabId={5}>
                            <Company
                              activeTabProgress={this.state.activeTabProgress}
                              next={this.next}
                              previous={this.previous}
                              uploadKYC={this.uploadKYC}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    kyc: state.KYC.kyc,
  }
}

export default connect(mapStateToProps)(FormWizard)
