import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from '../../store/actions'

// Other Layout related Component
import Navbar from './Navbar'
import Header from './Header'
import Footer from './Footer'
//request components
import axios from '../../axios'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { loginSuccess } from '../../store/auth/login/actions'
import { updateWallet } from '../../store/wallet/action'
import { getCashbacks } from '../../store/cashbacks/action'
import { updateNotifications } from '../../store/notifications/action'
import { colors } from '../../helpers/color'
import EmailVerification from '../../pages/EmailVerification'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color:
        localStorage.getItem('agent') === 'ACCEPTED'
          ? colors.agent
          : colors.blue,
    }}
    spin
  />
)

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpened: false,
      loading: true,
    }
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this)
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    this.props.toggleRightSidebar()
  }

  async componentDidMount() {
    //await this.notificationListener();
    await this.designOptions()
    await this.currentInfo()
    this.setState({
      loading: false,
    })
  }

  currentInfo = async () => {
    try {
      let current = await axios.get('api/current-user-info', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      })
      console.log(current)
      localStorage.setItem(
        'phone_verified',
        current.data.data.phone_verified_at,
      )
      localStorage.setItem(
        'email_verified',
        current.data.data.email_verified_at,
      )
      localStorage.setItem('name', current.data.data.name)
      localStorage.setItem('email', current.data.data.email)
      localStorage.setItem('mobile_no', current.data.data.mobile_no)
      localStorage.setItem(
        'vendor_key_production',
        current.data.data.merchant.vendor_key_production,
      )
      localStorage.setItem(
        'vendor_key_sandbox',
        current.data.data.merchant.vendor_key_sandbox,
      )
      localStorage.setItem(
        'merchant_type',
        current.data.data.merchant?.merchant_type,
      )
      localStorage.setItem('pin', current.data.data.pin)
      localStorage.setItem('login_pin', current.data.data.pin_status.login_pin)
      localStorage.setItem(
        'transaction_pin',
        current.data.data.pin_status.transaction_pin,
      )
      if (current.data.data.kyc_status) {
        localStorage.setItem('kyc_status', current.data.data.kyc_status)
      }
      if (current.data.data.avatar) {
        localStorage.setItem('avatar', current.data.data.avatar)
      } else {
        localStorage.setItem('avatar', null)
      }
      this.props.loginSuccess(current.data.data)
      this.props.updateWallet(current.data.data.balance)
    } catch (e) {
      console.log('error', e)
    }
  }

  designOptions = () => {
    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = this.props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = 'iCash | ' + currentage

    this.props.changeLayout('horizontal')
    this.props.changeTopbarTheme('light')
    this.props.changeLayoutWidth('fluid')
  }
  /**
   * Opens the menu - mobile
   */
  openMenu = (e) => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }
  render() {
    var valid = new Date(localStorage.getItem('email_verified')).getTime()
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
        {valid > 0 ? (
          <Spin indicator={antIcon} spinning={this.state.loading}>
            <div id="layout-wrapper">
              <Header
                theme={'light'}
                isMenuOpened={this.state.isMenuOpened}
                toggleRightSidebar={this.toggleRightSidebar}
                openLeftMenuCallBack={this.openMenu}
              />
              <Navbar menuOpen={this.state.isMenuOpened} />

              <div className="main-content">{this.props.children}</div>
              <Footer />
            </div>
          </Spin>
        ) : (
          <EmailVerification />
        )}
      </React.Fragment>
    )
  }
}
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    user: state.Login.user,
  }
}
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
  loginSuccess,
  updateWallet,
  getCashbacks,
  updateNotifications,
})(withRouter(Layout))
