import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ position: 'relative' }}>
        <Container fluid={false}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © iCash.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                iCash Pvt. Ltd.
                {/* <a
                  href="http://silkinnovation.com.np"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#7967ad" }}
                >
                  
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
