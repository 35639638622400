import React, { Component } from 'react'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { updateKYCForm } from '../../store/kyc/action'

class Address extends Component {
  constructor(props) {
    super(props)
    this.state = {
      districts: {
        'Province No. 1': [
          'Bhojpur',
          'Dhankuta',
          'Ilam',
          'Jhapa',
          'Khotang',
          'Morang',
          'Okhaldhunga',
          'Panchthar',
          'Sankhuwasabha',
          'Solukhumbu',
          'Sunsari',
          'Taplejung',
          'Terhathum',
          'Udayapur',
        ],
        'Province No. 2': [
          'Bara',
          'Parsa',
          'Dhanusa',
          'Mahottari',
          'Rautahat',
          'Saptari',
          'Sarlahi',
          'Siraha',
        ],
        'Province No. 3': [
          'Bhaktapur',
          'Chitwan',
          'Dhading',
          'Dolakha',
          'Kathmandu',
          'Kavrepalanchok',
          'Lalitpur',
          'Makwanpur',
          'Nuwakot',
          'Ramechhap',
          'Rasuwa',
          'Sindhuli',
          'Sindhupalchok',
        ],
        'Gandaki Pradesh': [
          'Baglung',
          'Gorkha',
          'Kaski',
          'Lamjung',
          'Manang',
          'Mustang',
          'Myagdi',
          'Nawalparasi (East)',
          'Nawalparasi (West)',
          'Parbat',
          'Syangja',
          'Tanahun',
        ],
        'Province No. 5': [
          'Arghakhanchi',
          'Banke',
          'Bardiya',
          'Dang Deukhuri',
          'Rukum (East)',
          'Gulmi',
          'Kapilvastu',
          'Palpa',
          'Pyuthan',
          'Rolpa',
          'Rupandehi',
        ],
        'Karnali Pradesh': [
          'Dailekh',
          'Dolpa',
          'Humla',
          'Jajarkot',
          'Jumla',
          'Kalikot',
          'Mugu',
          'Salyan',
          'Surkhet',
          'Rukum (West)',
        ],
        'Sudurpashchim Pradesh': [
          'Achham',
          'Baitadi',
          'Bajhang',
          'Bajura',
          'Dadeldhura',
          'Darchula',
          'Doti',
          'Kailali',
          'Kanchanpur',
        ],
      },
      municipalities: [
        {
          district: 'Achham',
          municipalities: [
            'Kamalbazar',
            'Mangalsen',
            'Panchadewal Binayak',
            'Sanphebagar',
          ],
        },
        {
          district: 'Arghakhanchi',
          municipalities: ['Bhumekasthan', 'Sandhikharka', 'Sitganga'],
        },
        {
          district: 'Baglung',
          municipalities: ['Baglung', 'Dhorpatan', 'Galkot', 'Jaimuni'],
        },
        {
          district: 'Baitadi',
          municipalities: ['Dasharathchanda', 'Melauli', 'Patan', 'Purchaudi'],
        },
        {
          district: 'Bajhang',
          municipalities: ['Bungal', 'Jaya Prithivi'],
        },
        {
          district: 'Bajura',
          municipalities: ['Badimalika', 'Budhiganga', 'Budhinanda', 'Tribeni'],
        },
        {
          district: 'Banke',
          municipalities: ['Nepalganj', 'Kohalpur'],
        },
        {
          district: 'Bara',
          municipalities: [
            'Jitpur Simara',
            'Kalaiya',
            'Kolhabi',
            'Mahagadhimai',
            'Nijgadh',
            'Pacharauta',
            'Simroungadh',
          ],
        },
        {
          district: 'Bardiya',
          municipalities: [
            'Bansgadhi',
            'Barbardiya',
            'Gulariya',
            'Madhuwan',
            'Rajapur',
            'Thakurbaba',
          ],
        },
        {
          district: 'Bhaktapur',
          municipalities: [
            'Bhaktapur',
            'Changunarayan',
            'Madhyapur Thimi',
            'Suryabinayak',
          ],
        },
        {
          district: 'Bhojpur',
          municipalities: ['Bhojpur', 'Shadananda'],
        },
        {
          district: 'Chitwan',
          municipalities: [
            'Bharatpur',
            'Kalika',
            'Khairahani',
            'Madi',
            'Rapti',
            'Ratnangar',
          ],
        },
        {
          district: 'Dadeldhura',
          municipalities: ['Amargadhi', 'Parashuram'],
        },
        {
          district: 'Dailekh',
          municipalities: [
            'Aathabis',
            'Chamunda Bindrasaini',
            'Dullu',
            'Narayan',
          ],
        },
        {
          district: 'Dang Deukhuri',
          municipalities: ['Ghorahi', 'Tulsipur', 'Lamahi'],
        },
        {
          district: 'Darchula',
          municipalities: ['Mahakali', 'Shailyashikhar'],
        },
        {
          district: 'Dhading',
          municipalities: ['Dhunibesi', 'Nilakantha'],
        },
        {
          district: 'Dhankuta',
          municipalities: ['Dhankuta', 'Mahalaxmi', 'Pakhribas'],
        },
        {
          district: 'Dhanusa',
          municipalities: [
            'Janakpur',
            'Bidehi',
            'Chhireshwornath',
            'Dhanusadham',
            'Ganeshman Charnath',
            'Hansapur',
            'Kamala',
            'Mithila',
            'Mithila Bihari',
            'Nagarain',
            'Sabaila',
            'Sahidnagar',
          ],
        },
        {
          district: 'Dolakha',
          municipalities: ['Bhimeshwor', 'Jiri'],
        },
        {
          district: 'Dolpa',
          municipalities: ['Thuli Bheri', 'Tripurasundari'],
        },
        {
          district: 'Doti',
          municipalities: ['Dipayal Silgadhi', 'Shikhar'],
        },
        {
          district: 'Gorkha',
          municipalities: ['Gorkha', 'Palungtar'],
        },
        {
          district: 'Gulmi',
          municipalities: ['Musikot', 'Resunga'],
        },
        {
          district: 'Humla',
          municipalities: ['Humla'],
        },
        {
          district: 'Ilam',
          municipalities: ['Deumai', 'Ilam', 'Mai', 'Suryodaya'],
        },
        {
          district: 'Jajarkot',
          municipalities: ['Bheri', 'Chhedagad', 'Tribeni Nalagad'],
        },
        {
          district: 'Jhapa',
          municipalities: [
            'Arjundhara',
            'Bhadrapur',
            'Birtamod',
            'Damak',
            'Gauradhaha',
            'Kankai',
            'Mechinagar',
            'Shivasataxi',
          ],
        },
        {
          district: 'Jumla',
          municipalities: ['Chandannath'],
        },
        {
          district: 'Kavrepalanchok',
          municipalities: [
            'Banepa',
            'Dhulikhel',
            'Mandandeupur',
            'Namobuddha',
            'Panauti',
            'Panchkhal',
          ],
        },
        {
          district: 'Kailali',
          municipalities: [
            'Dhangadhi',
            'Bhajani',
            'Gauriganga',
            'Ghodaghodi',
            'Godawari',
            'Lamkichuha',
            'Tikapur',
          ],
        },
        {
          district: 'Kalikot',
          municipalities: ['Khandachakra', 'Raskot', 'Tilagufa'],
        },
        {
          district: 'Kanchanpur',
          municipalities: [
            'Bedkot',
            'Belauri',
            'Bhimdatta',
            'Krishnapur',
            'Mahakali',
            'Punarbas',
            'Suklaphanta',
          ],
        },
        {
          district: 'Kapilvastu',
          municipalities: [
            'Banganga',
            'Buddhabhumi',
            'Kapilbastu',
            'Krishnanagar',
            'Maharajgunj',
            'Shivaraj',
          ],
        },
        {
          district: 'Kaski',
          municipalities: ['Pokhara Lekhnath'],
        },
        {
          district: 'Kathmandu',
          municipalities: [
            'Kathmandu',
            'Budhanilakantha',
            'Chandragiri',
            'Dakshinkali',
            'Gokarneshwor',
            'Kageshwori Manahara',
            'Kirtipur',
            'Nagarjun',
            'Shankharapur',
            'Tarakeshwar',
            'Tokha',
          ],
        },
        {
          district: 'Khotang',
          municipalities: ['Halesi Tuwachung', 'Rupakot Majhuwagadhi'],
        },
        {
          district: 'Lalitpur',
          metropolises: ['Lalitpur'],
          municipalities: ['Godawari', 'Mahalaxmi'],
        },
        {
          district: 'Lamjung',
          municipalities: [
            'Besishahar',
            'Madhyanepal',
            'Rainas',
            'Sundarbazar',
          ],
        },
        {
          district: 'Mahottari',
          municipalities: [
            'Aurahi',
            'Balwa',
            'Bardibas',
            'Bhangaha',
            'Gaushala',
            'Jaleswar',
            'Loharpatti',
            'Manra Sisawa',
            'Matihani',
            'Ramgopalpur',
          ],
        },
        {
          district: 'Makwanpur',
          municipalities: ['Hetauda', 'Thaha'],
        },
        {
          district: 'Manang',
          municipalities: [],
        },
        {
          district: 'Morang',
          municipalities: [
            'Biratnagar',
            'Belbari',
            'Letang',
            'Patahri Shanishchare',
            'Rangeli',
            'Ratuwamai',
            'Sundarharaicha',
            'Sunwarshi',
            'Uralabari',
          ],
        },
        {
          district: 'Mugu',
          municipalities: ['Chhayanath Rara'],
        },
        {
          district: 'Mustang',
          municipalities: ['Mustang'],
        },
        {
          district: 'Myagdi',
          municipalities: ['Beni'],
        },
        {
          district: 'Nawalparasi (East)',
          municipalities: ['Devchuli', 'Gaidakot', 'Kawaswoti', 'Madhyabindu'],
        },
        {
          district: 'Nawalparasi (West)',
          municipalities: ['Bardaghat', 'Ramgram', 'Sunwal'],
        },
        {
          district: 'Nuwakot',
          municipalities: ['Belkotgadhi', 'Bidur'],
        },
        {
          district: 'Okhaldhunga',
          municipalities: ['Siddhicharan'],
        },
        {
          district: 'Palpa',
          municipalities: ['Rampur', 'Tansen'],
        },
        {
          district: 'Panchthar',
          municipalities: ['Phidim'],
        },
        {
          district: 'Parsa',
          municipalities: ['Birgunj', 'Bahudaramai', 'Parsagadi', 'Pokhariya'],
        },
        {
          district: 'Parbat',
          municipalities: ['Kushma', 'Phalebas'],
        },
        {
          district: 'Pyuthan',
          municipalities: ['Pyuthan', 'Sworgadwary'],
        },
        {
          district: 'Ramechhap',
          municipalities: ['Manthali', 'Ramechhap'],
        },
        {
          district: 'Rasuwa',
          municipalities: ['Rasuwa'],
        },
        {
          district: 'Rautahat',
          municipalities: [
            'Baudhimai',
            'Brindaban',
            'Chandrapur',
            'Devahi Gonahi',
            'Gadhimai',
            'Garuda',
            'Gaur',
            'Gujara',
            'Ishanath',
            'Katahariya',
            'Madav Narayan',
            'Maulapur',
            'Paroha',
            'Phatuwa Bijayapur',
            'Rajdevi',
            'Rajpur',
          ],
        },
        {
          district: 'Rolpa',
          municipalities: ['Rolpa'],
        },
        {
          district: 'Rukum (East)',
          municipalities: ['Rukum (East)'],
        },
        {
          district: 'Rukum (West)',
          municipalities: ['Aathbiskot', 'Chaurjahari', 'Musikot'],
        },
        {
          district: 'Rupandehi',
          municipalities: [
            'Butwal',
            'Devdaha',
            'Lumbini Sanskritik',
            'Sainamaina',
            'Siddharthanagar',
            'Tilottama',
          ],
        },
        {
          district: 'Salyan',
          municipalities: ['Bagchaur', 'Bangad Kupinde', 'Sharada'],
        },
        {
          district: 'Sankhuwasabha',
          municipalities: [
            'Chainpur',
            'Dharmadevi',
            'Khandbari',
            'Madi',
            'Panchakhapan',
          ],
        },
        {
          district: 'Saptari',
          municipalities: [
            'Bodebarsaien',
            'Dakneshwori',
            'Hanumannagar Kankalani',
            'Kanchanrup',
            'Khadak',
            'Rajbiraj',
            'Saptakoshi',
            'Shambhunath',
            'Surunga',
          ],
        },
        {
          district: 'Sarlahi',
          municipalities: [
            'Bagmati',
            'Balara',
            'Barahathawa',
            'Godaita',
            'Haripur',
            'Haripurwa',
            'Hariwan',
            'Ishworpur',
            'Kabilasi',
            'Lalbandi',
            'Malangawa',
          ],
        },
        {
          district: 'Sindhuli',
          municipalities: ['Dudhouli', 'Kamalamai'],
        },
        {
          district: 'Sindhupalchok',
          municipalities: ['Barhabise', 'Chautara Sangachokgadhi', 'Melamchi'],
        },
        {
          district: 'Siraha',
          municipalities: [
            'Dhangadhimai',
            'Golbazar',
            'Kalyanpur',
            'Karjanha',
            'Lahan',
            'Mirchaiya',
            'Siraha',
            'Sukhipur',
          ],
        },
        {
          district: 'Solukhumbu',
          municipalities: ['Solududhakunda'],
        },
        {
          district: 'Sunsari',
          municipalities: [
            'Dharan',
            'Itahari',
            'Barah',
            'Duhabi',
            'Inarwa',
            'Ramdhuni',
          ],
        },
        {
          district: 'Surkhet',
          municipalities: [
            'Bheriganga',
            'Birendranagar',
            'Gurbhakot',
            'Lekbesi',
            'Panchpuri',
          ],
        },
        {
          district: 'Syangja',
          municipalities: [
            'Bhirkot',
            'Chapakot',
            'Galyang',
            'Putalibazar',
            'Waling',
          ],
        },
        {
          district: 'Tanahun',
          municipalities: ['Bhanu', 'Bhimad', 'Byas', 'Shuklagandaki'],
        },
        {
          district: 'Taplejung',
          municipalities: ['Phungling'],
        },
        {
          district: 'Terhathum',
          municipalities: ['Laligurans', 'Myanglung'],
        },
        {
          district: 'Udayapur',
          municipalities: ['Belaka', 'Chaudandigadhi', 'Katari', 'Triyuga'],
        },
      ],
      selectedProvince: null,
      selectedDistrict: null,
      munList: [],
      tempProvince: null,
      tempDistrict: null,
      munListTemp: [],
      checked: false,
      district: '0',
      municipality: '0',
      ward_no: '0',
      tmp_province: '0',
      tmp_district: '0',
      tmp_municipality: '0',
      tmp_ward_no: '',
    }
  }
  handleSubmit = (e, values) => {
    e.preventDefault()
    values.zone = 'asd'
    values.tmp_zone = 'asd'
    this.props.updateKYCForm(values)
    this.props.next()
  }

  provinceChange = (e) => {
    const { value } = e.target
    if (value !== '0') {
      this.form._inputs.district.value = '0'
      this.form._inputs.municipality.value = '0'
      this.form._inputs.ward_no.value = ''
      this.setState({
        selectedProvince: value,
      })
    }
  }
  provinceChangeTemp = (e) => {
    const { value } = e.target
    if (value !== '0') {
      this.form._inputs.tmp_district.value = '0'
      this.form._inputs.tmp_municipality.value = '0'
      this.form._inputs.tmp_ward_no.value = ''
      this.setState({
        tempProvince: value,
        checked: false,
      })
    }
  }

  districtChange = (e) => {
    const { value } = e.target
    if (value !== '0') {
      this.form._inputs.municipality.value = '0'
      this.form._inputs.ward_no.value = ''
      const found = this.state.municipalities.find(
        (element) => element.district === value,
      )
      let temp = found.municipalities
      this.setState({
        selectedDistrict: value,
        munList: temp,
      })
    }
  }
  districtChangeTemp = (e) => {
    const { value } = e.target
    if (value !== '0') {
      this.form._inputs.tmp_municipality.value = '0'
      this.form._inputs.tmp_ward_no.value = ''
      const found = this.state.municipalities.find(
        (element) => element.district === value,
      )
      let temp = found.municipalities
      this.setState({
        districtsTemp: value,
        munListTemp: temp,
        checked: false,
      })
    }
  }

  checked = async () => {
    await this.setState({
      checked: !this.state.checked,
    })
    if (this.state.checked) {
      this.form._inputs.tmp_district.value = this.form._inputs.district.value
      this.setState({
        tmp_province: this.form._inputs.province.value,
        tmp_district: this.form._inputs.district.value,
        tmp_municipality: this.form._inputs.municipality.value,
        tmp_ward_no: this.form._inputs.ward_no.value,
      })
    } else {
      this.setState({
        tmp_province: '0',
        tmp_district: '0',
        tmp_municipality: '0',
        tmp_ward_no: '',
      })
    }
  }
  render() {
    const selectValid = (value) => {
      if (value === '0') {
        return 'This field is required'
      } else {
        return true
      }
    }
    let selected = this.state.selectedProvince
    let districts =
      this.state.selectedProvince &&
      this.state.districts[selected].map((district) => {
        return <option value={district}>{district}</option>
      })
    let tempSelected = this.state.tempProvince
    let districtsTemp =
      this.state.tempProvince &&
      this.state.districts[tempSelected].map((district) => {
        return <option value={district}>{district}</option>
      })

    let munList =
      this.state.munList &&
      this.state.munList.map((mun) => {
        return <option value={mun}>{mun}</option>
      })
    let munListTemp =
      this.state.munListTemp &&
      this.state.munListTemp.map((mun) => {
        return <option value={mun}>{mun}</option>
      })
    return (
      <AvForm
        className="form-horizontal"
        onValidSubmit={this.handleSubmit}
        ref={(c) => (this.form = c)}
      >
        <h4>Permanent Address</h4>
        <Row>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="province"
                label="Province"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your Province',
                  },
                  selectValid,
                }}
                onChange={this.provinceChange}
              >
                <option value={0}>Select Province</option>
                <option value="Province No. 1">Province No. 1</option>
                <option value="Province No. 2">Province No. 2</option>
                <option value="Province No. 3">Province No. 3</option>
                <option value="Gandaki Pradesh">Gandaki Pradesh</option>
                <option value="Province No. 5">Province No. 5</option>
                <option value="Karnali Pradesh">Karnali Pradesh</option>
                <option value="Sudurpashchim Pradesh">
                  Sudurpashchim Pradesh
                </option>
              </AvField>
            </div>
          </Col>

          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="district"
                label="District"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your District',
                  },
                  selectValid,
                }}
                onChange={this.districtChange}
                value={this.state.district}
              >
                <option value={0}>Select District</option>

                {districts}
              </AvField>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="municipality"
                label="Municipality"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your Municipality',
                  },
                  selectValid,
                }}
                value={this.state.municipality}
                //onChange={this.provinceChange}
              >
                <option value={0}>Select Municipality</option>
                {munList}
              </AvField>
            </div>
          </Col>

          <Col lg="6">
            <div className="form-group">
              <AvField
                name="ward_no"
                label="Ward Number"
                type="text"
                placeholder="Enter Ward Number"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please enter Ward Number',
                  },
                  pattern: {
                    value: '^[0-9]+$',
                    errorMessage:
                      'Ward Number must be composed only with numbers',
                  },
                  minLength: {
                    value: 1,
                    errorMessage: 'Ward number must be of maximum 2 digits',
                  },
                  maxLength: {
                    value: 2,
                    errorMessage: 'Ward number must be of maximum 2 digits',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <br />
        <h4>Temporary Address</h4>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={this.state.checked}
            onChange={this.checked}
          />
          <label className="form-check-label" htmlFor="defaultCheck1">
            Same as Permanent Address ?
          </label>
        </div>

        <Row>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="tmp_province"
                label="Province"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your Province',
                  },
                  selectValid,
                }}
                onChange={this.provinceChangeTemp}
                value={this.state.tmp_province}
              >
                <option value={0}>Select Province</option>
                <option value="Province No. 1">Province No. 1</option>
                <option value="Province No. 2">Province No. 2</option>
                <option value="Province No. 3">Province No. 3</option>
                <option value="Gandaki Pradesh">Gandaki Pradesh</option>
                <option value="Province No. 5">Province No. 5</option>
                <option value="Karnali Pradesh">Karnali Pradesh</option>
                <option value="Sudurpashchim Pradesh">
                  Sudurpashchim Pradesh
                </option>
              </AvField>
            </div>
          </Col>

          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="tmp_district"
                label="District"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your District',
                  },
                  selectValid,
                }}
                onChange={this.districtChangeTemp}
                value={this.state.tmp_district}
              >
                {this.state.checked ? (
                  <option value={this.state.tmp_district}>
                    {this.state.tmp_district}
                  </option>
                ) : null}
                <option value={0}>Select District</option>

                {districtsTemp}
              </AvField>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <div>
              <AvField
                type="select"
                name="tmp_municipality"
                label="Municipality"
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please select your Municipality',
                  },
                  selectValid,
                }}
                value={this.state.tmp_municipality}
                //onChange={this.provinceChange}
              >
                {this.state.checked ? (
                  <option value={this.state.tmp_municipality}>
                    {this.state.tmp_municipality}
                  </option>
                ) : null}
                <option value={0}>Select Municipality</option>
                {munListTemp}
              </AvField>
            </div>
          </Col>

          <Col lg="6">
            <div className="form-group">
              <AvField
                name="tmp_ward_no"
                label="Ward Number"
                type="text"
                placeholder="Enter Ward Number"
                value={this.state.tmp_ward_no}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Please enter Ward Number',
                  },
                  pattern: {
                    value: '^[0-9]+$',
                    errorMessage:
                      'Ward Number must be composed only with numbers',
                  },
                  minLength: {
                    value: 1,
                    errorMessage: 'Ward number must be of maximum 2 digits',
                  },
                  maxLength: {
                    value: 2,
                    errorMessage: 'Ward number must be of maximum 2 digits',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                style={{
                  backgroundImage:
                    'linear-gradient(to right,#006BCF,  #00D0F5 )',
                  borderColor: '#ffff',
                }}
                disabled={this.props.activeTabProgress === 1 ? true : false}
                onClick={this.props.previous}
              >
                Previous
              </button>
            </div>
          </Col>
          <Col lg="6"></Col>
          <Col lg="3">
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                style={{
                  backgroundImage:
                    'linear-gradient(to right,#006BCF,  #00D0F5 )',
                  borderColor: '#ffff',
                }}
                disabled={this.props.activeTabProgress === 4 ? true : false}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>
      </AvForm>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateKYCForm: (values) => dispatch(updateKYCForm(values)),
  }
}

export default connect('', mapDispatchToProps)(Address)
