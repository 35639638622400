import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import { vendorErrorData } from './urlConfig';
function errorResponse(error) {
	let errorResponse = error.response;
	if (errorResponse && errorResponse.data) {
		if (errorResponse.data.errors) {
			Object.keys(errorResponse.data.errors).forEach((errors) => {
				console.log(errorResponse.data.errors[errors][0]);
        toastr.error(
          // 'Vendor Error Code: 014', 
        errorResponse.data.errors[errors][0]);
			});
		} else if (errorResponse.data.message) {
			if (errorResponse.data.vendor_error_code) {
				toastr.error(
					// "Vendor Error Code: " + errorResponse.data.vendor_error_code,
					vendorErrorData[errorResponse.data.vendor_error_code]
				);
			} else if (!errorResponse.data.vendor_error_code) {
				toastr.error(
					// "System Error Code: 2008",
					errorResponse.data.message
				);
			} else {
				toastr.error(
					// "System Error Code: 2008",
					'Payment Failed. Please try again later.'
				);
			}
		} else {
			toastr.error(
				// "System Error Code: 2008",
				'Payment Failed. Please try again later.'
			);
		}
	} else {
		toastr.error(
			// "System Error Code: 2008",
			'Payment Failed. Please try again later.'
		);
	}
}

export { errorResponse };
