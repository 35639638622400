import React, { Component } from 'react'
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Button, Empty } from 'antd'
import moment from 'moment'
import axios from '../../axios'
import { colors } from '../../helpers/color'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import bank from '../../assets/icons/bank_transfer.png'

class Transactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionList: [],
      loading: true,
      skip: 0,
      limit: 15,
      pagination: false,
      loadSpin: false,
    }
  }
  componentDidMount() {
    this.getTransactions()
  }

  getTransactions = async () => {
    try {
      let transactions = await axios.get(
        `api/transaction/filter?sort=DESC&limit=${this.state.limit}&skip=${this.state.skip}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      console.log('transactions', transactions.data)
      this.setState({
        transactionList: [...this.state.transactionList, ...transactions.data],
        loading: false,
        pagination: transactions.data.length === 0 ? false : true,
        loadSpin: false,
      })
    } catch (e) {
      console.log('err', e)
    }
  }

  navigate = (id) => {
    this.props.history.push(`/single-transaction/${id}`)
    //alert(id);
  }
  loadTransactions = async () => {
    await this.setState({
      loadSpin: true,
      skip: this.state.skip + 15,
    })
    try {
      await this.getTransactions()
    } catch (e) {
      this.setState({ pagination: true, loadSpin: false })
      console.log(e)
    }
  }
  render() {
    const transactions =
      this.state.transactionList &&
      this.state.transactionList.map((tran, index) => {
        return (
          <CardBody>
            <CardTitle
              className="mb-4"
              style={{
                backgroundImage:
                  localStorage.getItem('agent') === 'ACCEPTED'
                    ? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
                    : `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
                padding: 10,
                color: 'white',
              }}
            >
              {moment(tran.date).format('dddd, MMMM Do YYYY')}
            </CardTitle>

            <div className="table-responsive">
              <table className="table-centered table-hover table">
                <tbody>
                  {tran.data.map((list) => {
                    let image
                    if (list.vendor === 'TBANK') {
                      image = '/icons/Wallet-icon.png'
                    } else if (list.vendor && list.serviceType === 'CASHBACK') {
                      image = '/icons/cashback.png'
                    } else if (list.vendor && list.serviceType === 'REFERRAL') {
                      image = '/icons/favicon.png'
                    } else if (list.vendor === 'NTC') {
                      if (
                        list.serviceType === 'ADSLU' ||
                        list.serviceType === 'ADSLV'
                      ) {
                        image = '/icons/adsl.png'
                      } else if (list.serviceType === 'NTFTTH') {
                        image = '/icons/ntc_ftth.jpg'
                      } else if (list.serviceType === 'NTWIMAX') {
                        image = '/icons/wimax.jpg'
                      } else {
                        image = '/icons/ntc.jpg'
                      }
                    } else if (list.vendor === 'UTL') {
                      image = '/icons/utl.png'
                    } else if (list.vendor === 'NCELL') {
                      image = '/icons/ncell.jpg'
                    } else if (list.vendor === 'SMARTCELL') {
                      image = '/icons/smartcell.png'
                    } else if (list.vendor === 'VIANET') {
                      image = '/icons/vianet.png'
                    } else if (list.vendor === 'WORLDLINK') {
                      image = '/icons/worldlink.jpg'
                    } else if (list.vendor === 'WEBSURFER') {
                      image = '/icons/websurfer.png'
                    } else if (list.vendor === 'SUBISU') {
                      image = '/icons/subisu.jpeg'
                    } else if (list.vendor === 'ARROWNET') {
                      image = '/icons/arrownet.jpg'
                    } else if (
                      list.vendor === 'Dpaisa' ||
                      list.vendor === 'Transfer Funds'
                    ) {
                      image = '/icons/send_request.svg'
                    } else if (list.vendor === 'Recieved Funds') {
                      image = '/icons/friend-request.png'
                    } else if (list.vendor === 'KHANEPANI') {
                      image = '/icons/khanepani.png'
                    } else if (list.vendor === 'NEPAL-WATER') {
                      image = '/icons/tap.svg'
                    } else if (list.vendor === 'NEA') {
                      image = '/icons/nea.jpg'
                    } else if (list.vendor === 'SIMTV') {
                      image = '/icons/simtv.png'
                    } else if (list.vendor === 'DISHHOME') {
                      image = '/icons/dishhome.png'
                    } else if (list.vendor === 'SKY') {
                      image = '/icons/sky.jpeg'
                    } else if (list.vendor === 'MEROTV') {
                      image = '/icons/merotv.jpeg'
                    } else if (list.serviceType === 'MERCHANT') {
                      image = '/icons/favicon.png'
                    } else if (list.serviceType === 'BANK-TRANSFER') {
                      image = bank
                    } else {
                      image = '/icons/Wallet-icon.png'
                    }

                    const formatString = (str) => {
                      try {
                        var newStr = str.replace(/_/g, ' ')
                        const finalSentence = newStr.replace(
                          /(^\w{1})|(\s+\w{1})/g,
                          (letter) => letter.toUpperCase(),
                        )
                        // const result = finalSentence.replace(/([A-Z])/g, ' $1')
                        // const finalResult =
                        //   result.charAt(0).toUpperCase() + result.slice(1)
                        return finalSentence
                      } catch (e) {
                        return str
                      }
                    }

                    return (
                      <tr
                        onClick={() => this.navigate(list.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>
                          <div>
                            <img class="avatar-sm" src={image} alt="" />
                          </div>
                        </td>
                        <td>
                          <h4 className="text-truncate font-size-14 m-0">
                            {list.vendor === 'REVENUE'
                              ? formatString(list.request_detail.wallet_service)
                              : list.vendor}
                          </h4>

                          {list.vendor === 'NTC' || list.vendor === 'SKY' ? (
                            <div>
                              <span>Service Type: {list.serviceType}</span>
                              <br />
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <span>
                            <b>Payment Details:</b>
                            <br />
                            {list?.request_detail &&
                              Object.keys(list.request_detail).map(
                                (key, index) => {
                                  if (
                                    key === 'additional' ||
                                    key === 'save_data' ||
                                    key === 'wallet_service' ||
                                    key === 'amount' ||
                                    key === 'bank_id'
                                  ) {
                                    return null
                                  }
                                  return (
                                    <>
                                      <span>
                                        {formatString(key)}:{' '}
                                        {list.request_detail[key]}
                                      </span>
                                      <br />
                                    </>
                                  )
                                },
                              )}
                            {!list?.request_detail && (
                              <div>
                                <span>Account: {list.account}</span>
                                <br />
                                <span>Service Type: {list.serviceType}</span>
                                <br />
                              </div>
                            )}
                          </span>
                        </td>
                        <td>
                          <div className="text-center">
                            <button
                              className={
                                list.type === 'Load' ||
                                list.serviceType === 'MERCHANT' ||
                                list.vendor === 'REVENUE'
                                  ? 'btn badge badge-pill badge-soft-success font-size-13'
                                  : 'btn badge badge-pill badge-soft-danger font-size-13'
                              }
                              style={{ float: 'right' }}
                            >
                              Rs. {list.amount / 100}
                            </button>
                            <br />
                            <span style={{ float: 'right', marginTop: 10 }}>
                              {moment(list.createdAt).format('h:mm:ss a')}
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        )
      })
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Statement" />
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card outline className="border">
                  {this.state.transactionList.length === 0 ? (
                    <Empty
                      description={'Transaction statement not found.'}
                      style={{ padding: 20 }}
                    />
                  ) : (
                    transactions
                  )}
                </Card>
                {this.state.pagination === true ? (
                  this.state.transactionList.length === 0 ? null : (
                    <center>
                      <Button
                        loading={this.state.loadSpin}
                        onClick={this.loadTransactions}
                      >
                        Load More
                      </Button>
                    </center>
                  )
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Transactions
