import React, { Component } from 'react';
import { Modal, Alert } from 'reactstrap';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../../axios';
import { colors } from '../../helpers/color';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: null,
		};
	}

	onConfirm = async (e, values) => {
		//e.presist();
		e.preventDefault();
		try {
			this.setState({
				loading: true,
				error: null,
			});
			console.log('vale', values);
			let check = await axios.post('api/check-pin', values, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
			});
			console.log('pinCheck', check.data);
			if (check.data.status) {
				//this.props.form.resetFields();
				this.props.checkPin(check.data.status);
			} else {
				this.setState({
					error: 'Invalid Transaction PIN',
				});
			}
			this.setState({
				loading: false,
			});
		} catch (e) {
			console.log('e', e);
			this.setState({
				error: e.response.data.message,
				loading: false,
			});
		}
	};

	render() {
		return (
			<Modal isOpen={this.props.visible} toggle={this.props.visible}>
				<div className="modal-header">
					<h5 className="modal-title mt-0" id="myModalLabel">
						Confirm Your Transaction PIN
					</h5>
					<button
						type="button"
						onClick={() => {
							this.setState({
								error: null,
							});
							this.props.closeModal();
						}}
						className="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<Spin spinning={this.state.loading} indicator={antIcon}>
					<div className="modal-body">
						<div className="p-2" style={{ marginTop: 10 }}>
							<AvForm
								className="form-horizontal"
								onValidSubmit={this.onConfirm}
								ref={(c) => (this.form = c)}
							>
								{this.state.error && this.state.error ? (
									<Alert color="danger">{this.state.error}</Alert>
								) : null}
								<div className="form-group">
									<AvField
										name="pin"
										label="Transaction PIN"
										type="password"
										placeholder="Enter Transaction PIN"
										validate={{
											required: {
												value: true,
												errorMessage: 'Please enter a Transaction PIN',
											},
										}}
									/>
								</div>
								<div className="mt-3">
									<button
										className="btn btn-primary btn-block waves-effect waves-light"
										type="submit"
										style={{
											backgroundImage:
												localStorage.getItem('agent') === 'ACCEPTED'
													? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
													: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
											borderColor: '#ffff',
										}}
									>
										Confirm PIN
									</button>
								</div>
							</AvForm>
						</div>
					</div>
				</Spin>
			</Modal>
		);
	}
}

export default ChangePassword;
