import React, { Component } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, CardTitle } from 'reactstrap';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// action
import { loginUser, apiError } from '../../store/actions';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../../axios';

// import images
import logoImg from '../../assets/images/i-cash-logo.svg';

import { emailValidation } from '../../helpers/validations';
import MobileRegister from './MobileRegister';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: null,
			success: null,
			mobile_no:null,
			phase:"otp-verification"
		};
	}

	// handleValidSubmit
	handleValidSubmit = async (event, values) => {
		event.preventDefault();
		this.setState({
			loading: true,
		});
		try {
			let register = await axios.post(
				`api/merchant/register`,
				{
					name: values.name,
					email: values.email,
					password: values.password,
					fcm_token: '1234567890',
					mobile_no: this.state.mobile_no,
					gender: "O",
				},
			);
			this.props.loginUser(register.data, this.props.history);
			this.setState({
				success: 'User successfully registered.',
			});
		} catch (e) {
			console.log('re_error', e.response);
			if (e.response.data) {
				this.setState({
					error: e.response.data,
				});
			} else {
				this.setState({
					error: { message: 'Registration failed. Please try again later' },
				});
			}
		}
		this.setState({
			loading: false,
		});
	};

	completeMobileVerification = (value) => {
		this.setState({
			mobile_no:value,
			phase:"registration"
		})
	}

	render() {
		const emailValid = (value) => {
			if (emailValidation(value)) {
				return true;
			} else {
				return 'Invalid email address';
			}
		};
		return (
			<React.Fragment>
				{ this.state.phase ==="otp-verification" ?
				<MobileRegister
					completeMobileVerification={this.completeMobileVerification}
				/>
				:
				<div className="account-pages my-3 pt-sm-3">
					<Container>
						<center>
							<a href="/">
								<img src={logoImg} alt={'icash_logo'} style={{ marginBottom: 30, width: 160 }} />
							</a>
						</center>
						<Row className="justify-content-center">
							<Col md={10} lg={10} xl={10}>
								<Spin indicator={antIcon} spinning={this.state.loading}>
									<Card className="overflow-hidden">
										<CardBody>
											<CardTitle>
												<h4>Register into a new Account</h4>
											</CardTitle>
											<div className="p-2">
												<AvForm
													className="form-horizontal"
													onValidSubmit={this.handleValidSubmit}
												>
													{this.state.success && this.state.success ? (
														<Alert color="success">{this.state.success}</Alert>
													) : null}
													{this.state.error && 
														<Alert color="danger">{this.state.error.message}</Alert>
													}
													<Row style={{ marginTop: 30 }}>
													<Col md="6">
															<div>
																<AvField
																	name="name"
																	label="Merchant Name"
																	type="text"
																	placeholder="Enter your Merchant Name"
																	validate={{
																		required: {
																			value: true,
																			errorMessage:
																				'Please enter your Merchant Name',
																		},
																		pattern: {
																			value: '^[A-Za-z .]+$',
																			errorMessage:
																				'Merchant Name should only contain alphabetical characters',
																		},
																	}}
																/>
															</div>
														</Col>
														{/* <Col lg="6">
															<div className="form-group">
																<AvField
																	name="mobile_no"
																	label="Mobile Number"
																	type="text"
																	placeholder="Enter Mobile Number"
																	validate={{
																		required: {
																			value: true,
																			errorMessage:
																				'Please enter a Mobile Number',
																		},
																		pattern: {
																			value: '^[0-9]+$',
																			errorMessage:
																				'Mobile number must be composed only with numbers',
																		},
																		minLength: {
																			value: 10,
																			errorMessage:
																				'Mobile number must be of 10 digits',
																		},
																		maxLength: {
																			value: 10,
																			errorMessage:
																				'Mobile number must be of 10 digits',
																		},
																		phoneValid,
																	}}
																/>
															</div>
														</Col> */}
														<Col md="6">
															<div>
																<AvField
																	name="email"
																	label="Email"
																	type="email"
																	placeholder="Enter your Email Address"
																	validate={{
																		required: {
																			value: true,
																			errorMessage:
																				'Please enter your Email Address',
																		},
																		emailValid,
																	}}
																/>
															</div>
														</Col>
													</Row>
													<Row>
														<Col md="6">
															<div className="form-group">
																<AvField
																	name="password"
																	label="Password"
																	type="password"
																	placeholder="Enter Password"
																	validate={{
																		required: {
																			value: true,
																			errorMessage: 'Please enter a Password',
																		},
																		pattern: {
																			value: '.*[0-9].*',
																			errorMessage:
																				'Password must be composed of alphabets and at least one number',
																		},
																		minLength: {
																			value: 6,
																			errorMessage:
																				'Password must be at least 6 characters',
																		},
																	}}
																/>
															</div>
														</Col>
														<Col md="6">
															<div className="form-group">
																<AvField
																	name="password_confirmation"
																	label="Confirm Password"
																	type="password"
																	placeholder="Confirm Password"
																	validate={{
																		required: {
																			value: true,
																			errorMessage:
																				'Please confirm your Password',
																		},
																		match: {
																			value: 'password',
																			errorMessage: 'Passwords do not match',
																		},
																	}}
																/>
															</div>
														</Col>
													</Row>

													<div className="mt-4">
														<button
															className="btn btn-primary btn-block waves-effect waves-light"
															type="submit"
															style={{
																backgroundImage:
																	'linear-gradient(to right,#006BCF,  #00D0F5 )',
																borderColor: '#ffff',
															}}
														>
															Register
														</button>
													</div>

													<div className="mt-4 text-center">
														<p className="mb-0">
															By registering you agree to the iCash{' '}
															<Link to="#" className="text-primary">
																Terms of Use
															</Link>
														</p>
													</div>
												</AvForm>
											</div>
										</CardBody>
									</Card>
								</Spin>
								<div className="mt-5 text-center">
									<p>
										Already have an account ?{' '}
										<Link to="/login" className="font-weight-medium text-primary">
											{' '}
											Login
										</Link>{' '}
									</p>
									<p>
										© {new Date().getFullYear()} iCash 
										{/* Powered by{' '}
										<b>Galaxy International Pvt. Ltd.</b> */}
										{/* <a
											href="http://silkinnovation.com.np"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#7967ad' }}
										>
											Silk Innovation
										</a> */}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				}
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { error } = state.Login;
	return { error };
};

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Register));
