import React, { Component } from 'react'
import { Container } from 'reactstrap'
import ProfileCard from '../Dashboard/WelcomeComp'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
const adbs = require('ad-bs-converter')

class PagesStarter extends Component {
  dateConverter = (value) => {
    let nepaliDate = adbs.ad2bs(moment(value).format('YYYY/MM/DD')).en
    let month = null
    let day = null
    if (nepaliDate.month.toString().length === 1) {
      month = '0' + nepaliDate.month
    } else {
      month = nepaliDate.month
    }
    if (nepaliDate.day.toString().length === 1) {
      day = '0' + nepaliDate.day
    } else {
      day = nepaliDate.day
    }
    return nepaliDate.year + '-' + month + '-' + day
  }
  render() {
    console.log('state', this.props.kyc)
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Profile" />
            <Row className="justify-content-center">
              <Col
                md={this.props.kyc ? 4 : 10}
                lg={this.props.kyc ? 4 : 10}
                xl={this.props.kyc ? 4 : 10}
              >
                <ProfileCard page={'profile'} />
                {this.props.kyc ? (
                  <div className="table-responsive">
                    <table className="table table-nowrap table-centered mb-0">
                      <tr>
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            Passport Photo
                          </h5>
                          <br />
                          <span>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                'storage/img/kyc/' +
                                this.props.kyc.p_photo
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  'storage/img/kyc/' +
                                  this.props.kyc.p_photo
                                }
                                style={{ width: 180 }}
                                alt="passport_photo"
                              />
                            </a>
                          </span>
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            Identity Document Front
                          </h5>
                          <br />
                          <span>
                            <a
                              href={
                                process.env.REACT_APP_API_URL +
                                'storage/img/kyc/' +
                                this.props.kyc.id_photo_front
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  'storage/img/kyc/' +
                                  this.props.kyc.id_photo_front
                                }
                                style={{ width: 180 }}
                                alt="passport_front"
                              />
                            </a>
                          </span>
                        </td>
                        <td>
                          <div className="text-center">
                            <h5 className="text-truncate font-size-14 m-0">
                              Identity Document Back
                            </h5>
                            <br />
                            <span>
                              <a
                                href={
                                  process.env.REACT_APP_API_URL +
                                  'storage/img/kyc/' +
                                  this.props.kyc.id_photo_back
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    'storage/img/kyc/' +
                                    this.props.kyc.id_photo_back
                                  }
                                  style={{ width: 180 }}
                                  alt="passport_back"
                                />
                              </a>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : null}
              </Col>
              {this.props.kyc ? (
                <Col md={6} lg={6} xl={6}>
                  <Card
                    outline
                    color={
                      localStorage.getItem('agent') === 'ACCEPTED'
                        ? 'danger'
                        : 'primary'
                    }
                    className="border"
                  >
                    <CardBody>
                      <CardTitle className="mb-4">Personal Details</CardTitle>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Full Name
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.middle_name
                                      ? this.props.kyc.first_name +
                                        ' ' +
                                        this.props.kyc.middle_name +
                                        ' ' +
                                        this.props.kyc.last_name
                                      : this.props.kyc.first_name +
                                        ' ' +
                                        this.props.kyc.last_name}
                                  </h6>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Date of Birth
                                </h5>
                              </td>

                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.date_of_birth} A.D
                                  </h6>
                                  <span>
                                    {this.dateConverter(
                                      this.props.kyc.date_of_birth,
                                    )}{' '}
                                    B.S
                                  </span>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Permanent Address
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.district}-
                                    {this.props.kyc.ward_no},{' '}
                                    {this.props.kyc.municipality},{' '}
                                    {this.props.kyc.province}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Temporary Address
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.tmp_district}-
                                    {this.props.kyc.tmp_ward_no},{' '}
                                    {this.props.kyc.tmp_municipality},{' '}
                                    {this.props.kyc.tmp_province}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Father's Name
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.fathers_name}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Mother's Name
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.mothers_name}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Grand Father's Name
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.grand_fathers_name}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            {this.props.kyc.spouse_name ? (
                              <tr>
                                <td>
                                  <h5 className="text-truncate font-size-14 m-0">
                                    Spouse's Name
                                  </h5>
                                </td>
                                <td>
                                  <div className="text-center">
                                    <h6 className="text-truncate font-size-14 m-0">
                                      {this.props.kyc.spouse_name}
                                    </h6>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Occupation
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.occupation}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Identity Type
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.document_type}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Identity Number
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.id_no}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Identity Document Issued Date
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {moment(
                                      this.props.kyc.c_issued_date,
                                    ).format('YYYY-MM-DD')}{' '}
                                    A.D
                                  </h6>
                                  <span>
                                    {this.dateConverter(
                                      this.props.kyc.c_issued_date,
                                    )}{' '}
                                    B.S
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Identity Document Issued Place
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.c_issued_from}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
            </Row>
            <Row className="justify-content-center">
              {this.props.kyc ? (
                <Col md={10} lg={10} xl={10}>
                  <Card outline color={'primary'} className="border">
                    <CardBody>
                      <CardTitle className="mb-4">
                        Merchant Company/ Business Information
                      </CardTitle>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Business/ Company Name
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.company_name}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Business/ Company Address
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.company_address}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Business/ Company VAT/ PAN Number
                                </h5>
                              </td>
                              <td>
                                <div className="text-center">
                                  <h6 className="text-truncate font-size-14 m-0">
                                    {this.props.kyc.company_vat_pin_number}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Company Logo
                                </h5>
                                <br />
                                <span>
                                  <a
                                    href={localStorage.getItem('avatar')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={localStorage.getItem('avatar')}
                                      style={{ width: 180 }}
                                      alt="passport_photo"
                                    />
                                  </a>
                                </span>
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Company Registration Document
                                </h5>
                                <br />
                                <span>
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      'storage/img/kyc/' +
                                      this.props.kyc.company_document
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        'storage/img/kyc/' +
                                        this.props.kyc.company_document
                                      }
                                      style={{ width: 180 }}
                                      alt="passport_front"
                                    />
                                  </a>
                                </span>
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14 m-0">
                                  Company Vat Document
                                </h5>
                                <br />
                                <span>
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      'storage/img/kyc/' +
                                      this.props.kyc.company_vat_document
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        'storage/img/kyc/' +
                                        this.props.kyc.company_vat_document
                                      }
                                      style={{ width: 180 }}
                                      alt="passport_front"
                                    />
                                  </a>
                                </span>
                              </td>
                              {this.props.kyc
                                .company_tax_clearance_document && (
                                <td>
                                  <h5 className="text-truncate font-size-14 m-0">
                                    Company Tax Clearance Document
                                  </h5>
                                  <br />
                                  <span>
                                    <a
                                      href={
                                        process.env.REACT_APP_API_URL +
                                        'storage/img/kyc/' +
                                        this.props.kyc
                                          .company_tax_clearance_document
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          'storage/img/kyc/' +
                                          this.props.kyc
                                            .company_tax_clearance_document
                                        }
                                        style={{ width: 180 }}
                                        alt="passport_front"
                                      />
                                    </a>
                                  </span>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    kyc: state.Login.user.kyc,
  }
}

export default connect(mapStateToProps)(PagesStarter)
