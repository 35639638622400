import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, CardTitle } from 'reactstrap';

// Redux
import { withRouter, Link } from 'react-router-dom';

// import images
import logoImg from '../../assets/images/i-cash-logo.svg';
import { Redirect } from 'react-router-dom';
import axios from '../../axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { errorResponse } from '../../helpers/errorConfig';
import toastr from 'toastr';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class EmailVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			seconds: 30,
			resend: true,
			phase: 0,
		};
	}
	// componentDidMount() {
	// 	console.log(localStorage.getItem('email_verified'))
	// 	if (localStorage.getItem('email_verified') === "null") {
	// 		this.sendVerificationEmail();
	// 	}
	// }

	sendVerificationEmail = async () => {
		this.setState({
			loading: true,
			error: null,
			success: null,
		});
		try {
			await axios.post(
				`api/email/resend`,
				{ email: localStorage.getItem('email') },
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				}
			);
			toastr.success("Verification has been sent to your email address, please check your to verify your account")
			this.setState({
				phase: 1,
				resend: false
			});
			await this.setTime();
		} catch (e) {
			console.log('erroe', e);
			errorResponse(e);
		}
		this.setState({
			loading: false,
		});
	};
	setTime = () => {
		this.myInterval = setInterval(() => {
			const { seconds } = this.state;

			if (seconds > 0) {
				this.setState(({ seconds }) => ({
					seconds: seconds - 1,
				}));
			}
			if (seconds === 0) {
				this.setState({
					resend: true,
				});
				clearInterval(this.myInterval);
			}
		}, 1000);
	};

	render() {
		var valid = new Date(localStorage.getItem('email_verified')).getTime();
		if (valid > 0) {
			return <Redirect to={'/dashboard'} />;
		}
		if (!localStorage.getItem('access_token')) {
			return <Redirect to={'/login'} />;
		}
		return (
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/logout" className="text-dark">
						Logout
					</Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<center>
							<Link to="/">
								<img src={logoImg} alt={'icash_logo'} style={{ marginBottom: 30, width: 120 }} />
							</Link>
						</center>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Spin spinning={this.state.loading} indicator={antIcon}>
									<Card className="overflow-hidden">
										<CardBody>
											<CardTitle>
												<h4>Verify your email address</h4>
											</CardTitle>
											<div className="p-2" style={{ marginTop: 30 }}>
												<p className="mb-4">
													1. Click the verification link that has been sent to your email. <br />
													2. Reload the page to continue.
												</p>
												<div className="d-flex justify-content-end">
													<button
														className="btn btn-primary w-md waves-effect waves-light"
														onClick={this.sendVerificationEmail}
														style={{
															backgroundImage:
																'linear-gradient(to right,#006BCF,  #00D0F5 )',
															borderColor: '#ffff',
														}}
														disabled={!this.state.resend}
													>
														{`Resend Verification Email  ${!this.state.resend ? `(At ${this.state.seconds})` : ""}`}
													</button>
												</div>
											</div>
										</CardBody>
									</Card>
								</Spin>
								<div className="mt-5 text-center">
									<p>
										© {new Date().getFullYear()} iCash. Powered by{' '}
										<b>Galaxy International Pvt. Ltd.</b>
										{/* <a
											href="http://silkinnovation.com.np"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#7967ad' }}
										>
											Silk Innovation
										</a> */}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(EmailVerification);
