import React from 'react'
import logoLight from '../../assets/images/i-cash-logo.svg';
import Phone from '../../assets/icons/phone1.png';
import Email from '../../assets/icons/email1.png'
import QRCode from 'react-qr-code';
import { colors } from '../../helpers/color';
//import { currentUser } from '../../../../constants/defaultValues';

class PrintComponent extends React.Component {
    render() {
        const headingOneStyle = {
            fontSize: "54px",
            color: "#132c5e",
            fontWeight: "900"
        }

        const headingOneStyleTwo = {
            fontSize: "64px",
            color: "#132c5e",
            fontWeight: "700",
            textAlign:"center"
        }

        const contactNoStyle = { 
            backgroundColor:"#9acce7",
            borderColor: '#ffff',
            fontSize: "32px",
            color: "#132c5e",
            borderRadius: "16px",
        }
        const bottomDivParagraphStyle = {
            fontSize: "28px",
            margin: "0",
            color: "#fff"
        }
        const footerStyle = {
            backgroundImage: `linear-gradient(to right, ${colors.graLight},${colors.graDark} )`,
            borderColor: '#ffff',
        }

        return (
            <div className="d-flex flex-column justify-content-center align-items-center pt-4">
                <h1 className="mt-4" style={headingOneStyle}>नेपाल राष्ट्र बैंकबाट अनुमति प्राप्त</h1>
                <img height="170" className="mt-4 mb-5" src={logoLight} alt="logo" />
                <div className="mt-5 mb-5">
                    <QRCode 
                        value={`{"number":"${localStorage.getItem('mobile_no')}", "service":"iCash", "type":"merchant"}`} 
                        size={600}
                    />
                </div>
                <h1 className="mt-5" style={headingOneStyleTwo}>{localStorage.getItem("name")}</h1>
                <div style={contactNoStyle} className="py-1 px-5">
                    Contact No : {localStorage.getItem("mobile_no")}
                </div>
                <div style={footerStyle}
                    className="fixed-bottom d-flex justify-content-between py-2 px-4">
                    <div className="d-flex align-items-center ml-5">
                        <img width="100" height="100" src={Phone} alt="phone" />
                        <p style={{ fontSize: "28px", margin: "0", color: "#fff" }}>01-5908686</p>
                    </div>
                    <div className="d-flex align-items-center mr-5">
                        <img width="100" height="100" src={Email} alt="email" />
                        <p style={bottomDivParagraphStyle}>merchant@icash.com.np</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrintComponent
