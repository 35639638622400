import React, { Component } from 'react';
import { Container, Row, Col, CardBody, Card, Alert } from 'reactstrap';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../../axios';
import { colors } from '../../helpers/color';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			success: null,
			error: null,
		};
	}

	handleSubmit = async (e, values) => {
		e.preventDefault();
		this.setState({
			loading: true,
			success: null,
			error: false,
		});
		try {
			let change = await axios.post(
				`api/password/change`,
				{ old_password: values.oldPassword, password: values.password },
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				}
			);
			console.log('change', change.data);
			this.setState({
				success: 'Your password has been changed successfully',
			});
			this.form.reset();
		} catch (e) {
			console.log('change_error', e.response);
			if (e.response.data && e.response.data.message) {
				this.setState({
					error: e.response.data.message,
				});
			}
		}
		this.setState({
			loading: false,
		});
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumbs */}
						<Breadcrumbs title="Homer" breadcrumbItem="Change Password" />
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Spin indicator={antIcon} spinning={this.state.loading}>
									<Card className="overflow-hidden">
										<CardBody className="pt-0">
											<div className="p-2" style={{ marginTop: 10 }}>
												<AvForm
													className="form-horizontal"
													onValidSubmit={this.handleSubmit}
													ref={(c) => (this.form = c)}
												>
													{this.state.success && this.state.success ? (
														<Alert color="success">{this.state.success}</Alert>
													) : null}
													{this.state.error && this.state.error ? (
														<Alert color="danger">{this.state.error}</Alert>
													) : null}
													<div className="form-group">
														<AvField
															name="oldPassword"
															label="Old Password"
															type="password"
															placeholder="Enter Old Password"
															validate={{
																required: {
																	value: true,
																	errorMessage: 'Please enter a Old Password',
																},
															}}
														/>
													</div>
													<div className="form-group">
														<AvField
															name="password"
															label="New Password"
															type="password"
															placeholder="Enter New Password"
															validate={{
																required: {
																	value: true,
																	errorMessage: 'Please enter a New Password',
																},
																pattern: {
																	value: '.*[0-9].*',
																	errorMessage:
																		'Password must be composed of alphabets and at least one number',
																},
																minLength: {
																	value: 6,
																	errorMessage:
																		'Password must be at least 6 characters',
																},
															}}
														/>
													</div>
													<div className="form-group">
														<AvField
															name="confirm"
															label="Confirm New Password"
															type="password"
															placeholder="Confirm New Password"
															validate={{
																required: {
																	value: true,
																	errorMessage: 'Please confirm your New Password',
																},
																match: {
																	value: 'password',
																	errorMessage: 'Passwords do not match',
																},
															}}
														/>
													</div>

													<div className="mt-3">
														<button
															className="btn btn-primary btn-block waves-effect waves-light"
															type="submit"
															style={{
																backgroundImage:
																	localStorage.getItem('agent') === 'ACCEPTED'
																		? `linear-gradient(to right,${colors.agentGraDark}, ${colors.agentGraLight} )`
																		: `linear-gradient(to right,${colors.graDark}, ${colors.graLight} )`,
																borderColor: '#ffff',
															}}
														>
															Change Password
														</button>
													</div>
												</AvForm>
											</div>
										</CardBody>
									</Card>
								</Spin>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default ChangePassword;
