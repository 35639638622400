const initState = {
  wallet: 0,
  loading: true,
};

const walletReducer = (state = initState, action) => {
  switch (action.type) {
    case "RESPONSE_WALLET":
      return {
        ...state,
        wallet: action.balance,
      };
    case "RESPONSE_WALLET_ERROR":
      console.log("response wallet error");
      return state;
    default:
      return state;
  }
};

export default walletReducer;
