import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
//Import Image
import bank from '../../assets/icons/bank_transfer.png'

import axios from '../../axios'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

class InvoiceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      singleTransaction: {},
      loading: true,
    }
  }
  componentDidMount() {
    this.getTransaction()
  }
  getTransaction = async () => {
    try {
      let single = await axios.get(
        `api/transactions/${this.props.match.params.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        },
      )
      console.log('single', single.data)
      this.setState({
        singleTransaction: single.data.data,
      })
    } catch (e) {
      //Notification("error", "Couldn't get Transaction!!");
      console.log('error', e)
    }
    this.setState({
      loading: false,
    })
  }

  //Print the Invoice
  printInvoice = () => {
    window.print()
  }

  render() {
    var image

    const formatString = (str) => {
      try {
        var newStr = str.replace(/_/g, ' ')
        const finalSentence = newStr.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase(),
        )
        // const result = finalSentence.replace(/([A-Z])/g, ' $1')
        // const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
        return finalSentence
      } catch (e) {
        return str
      }
    }

    if (this.state.singleTransaction) {
      if (this.state.singleTransaction.vendor === 'TBANK') {
        image = '/icons/Wallet-icon.png'
      } else if (
        this.state.singleTransaction.vendor &&
        this.state.singleTransaction.serviceType === 'CASHBACK'
      ) {
        image = '/icons/cashback.png'
      } else if (
        this.state.singleTransaction.vendor &&
        this.state.singleTransaction.serviceType === 'REFERRAL'
      ) {
        image = '/icons/favicon.png'
      } else if (this.state.singleTransaction.vendor === 'NTC') {
        if (
          this.state.singleTransaction.serviceType === 'ADSLU' ||
          this.state.singleTransaction.serviceType === 'ADSLV'
        ) {
          image = '/icons/adsl.png'
        } else if (this.state.singleTransaction.serviceType === 'NTFTTH') {
          image = '/icons/ntc_ftth.jpg'
        } else if (this.state.singleTransaction.serviceType === 'NTWIMAX') {
          image = '/icons/wimax.jpg'
        } else {
          image = '/icons/ntc.jpg'
        }
      } else if (this.state.singleTransaction.vendor === 'UTL') {
        image = '/icons/utl.png'
      } else if (this.state.singleTransaction.vendor === 'NCELL') {
        image = '/icons/ncell.jpg'
      } else if (this.state.singleTransaction.vendor === 'SMARTCELL') {
        image = '/icons/smartcell.png'
      } else if (this.state.singleTransaction.vendor === 'VIANET') {
        image = '/icons/vianet.png'
      } else if (this.state.singleTransaction.vendor === 'WORLDLINK') {
        image = '/icons/worldlink.jpg'
      } else if (this.state.singleTransaction.vendor === 'WEBSURFER') {
        image = '/icons/websurfer.png'
      } else if (this.state.singleTransaction.vendor === 'SUBISU') {
        image = '/icons/subisu.jpeg'
      } else if (this.state.singleTransaction.vendor === 'ARROWNET') {
        image = '/icons/arrownet.jpg'
      } else if (
        this.state.singleTransaction.vendor === 'Dpaisa' ||
        this.state.singleTransaction.vendor === 'Transfer Funds'
      ) {
        image = '/icons/send_request.svg'
      } else if (this.state.singleTransaction.vendor === 'Recieved Funds') {
        image = '/icons/friend-request.png'
      } else if (this.state.singleTransaction.vendor === 'KHANEPANI') {
        image = '/icons/khanepani.png'
      } else if (this.state.singleTransaction.vendor === 'NEPAL-WATER') {
        image = '/icons/tap.svg'
      } else if (this.state.singleTransaction.vendor === 'NEA') {
        image = '/icons/nea.jpg'
      } else if (this.state.singleTransaction.vendor === 'SIMTV') {
        image = '/icons/simtv.png'
      } else if (
        this.state.singleTransaction.vendor === 'DISH' ||
        this.state.singleTransaction.vendor === 'DISHHOME'
      ) {
        image = '/icons/dishhome.png'
      } else if (this.state.singleTransaction.vendor === 'SKY') {
        image = '/icons/sky.jpeg'
      } else if (this.state.singleTransaction.vendor === 'MEROTV') {
        image = '/icons/merotv.jpeg'
      } else if (this.state.singleTransaction.serviceType === 'MERCHANT') {
        image = '/icons/favicon.png'
      } else if (this.state.singleTransaction.serviceType === 'BANK-TRANSFER') {
        image = bank
      } else {
        image = '/icons/Wallet-icon.png'
      }
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Payment Invoice" />
            <Spin spinning={this.state.loading} indicator={antIcon}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-right font-size-16">
                          Transaction ID# {this.state.singleTransaction.uuid}
                        </h4>
                        <div>
                          <img class="avatar-sm" src={image} alt="" />
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong>Paid to Account:</strong>
                            <br />
                            {this.state.singleTransaction.account}
                          </address>
                          {this.state.singleTransaction.epin ? (
                            <address>
                              <strong>
                                NTC VOUCHER PIN (use this one time PIN to
                                recharge):
                              </strong>
                              <br />
                              <h4 style={{ color: 'green' }}>
                                {this.state.singleTransaction.epin.code}
                              </h4>
                            </address>
                          ) : null}
                        </Col>
                        <Col xs="6" className="text-right">
                          <address>
                            <strong>Transaction Date:</strong>
                            <br />
                            {moment(
                              this.state.singleTransaction.createdAt,
                            ).format('MMMM Do YYYY, h:mm:ss a')}
                          </address>
                        </Col>
                      </Row>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Payment summary
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th style={{ width: '70px' }}>S.N.</th>
                              <th>Service Name</th>
                              <th>Service Type</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>01</td>
                              <td>
                                {this.state.singleTransaction.vendor ===
                                'REVENUE'
                                  ? formatString(
                                      this.state.singleTransaction
                                        .request_detail.wallet_service,
                                    )
                                  : this.state.singleTransaction.vendor}
                              </td>
                              <td>
                                {this.state.singleTransaction.serviceType}
                              </td>
                              <td className="text-right">
                                Rs. {this.state.singleTransaction.amount / 100}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" className="border-0 text-right">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-right">
                                <h4 className="m-0">
                                  Rs.{' '}
                                  {this.state.singleTransaction.amount / 100}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="table-responsive">
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 font-weight-bold">
                            Payment Details
                          </h3>
                        </div>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              {!this.state.loading &&
                                this.state.singleTransaction &&
                                this.state.singleTransaction?.request_detail &&
                                Object.keys(
                                  this.state.singleTransaction?.request_detail,
                                ).map((key, index) => {
                                  if (
                                    key === 'additional' ||
                                    key === 'save_data' ||
                                    key === 'wallet_service' ||
                                    key === 'amount' ||
                                    key === 'bank_id'
                                  ) {
                                    return null
                                  }
                                  return <th>{formatString(key)}</th>
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {!this.state.loading &&
                                this.state.singleTransaction &&
                                this.state.singleTransaction?.request_detail &&
                                Object.keys(
                                  this.state.singleTransaction?.request_detail,
                                ).map((key, index) => {
                                  if (
                                    key === 'additional' ||
                                    key === 'save_data' ||
                                    key === 'wallet_service' ||
                                    key === 'amount' ||
                                    key === 'bank_id'
                                  ) {
                                    return null
                                  }
                                  return (
                                    <td>
                                      {formatString(
                                        this.state.singleTransaction
                                          .request_detail[key],
                                      )}
                                    </td>
                                  )
                                })}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-print-none">
                        <div className="float-right">
                          <Link
                            to="#"
                            onClick={this.printInvoice}
                            className={
                              localStorage.getItem('agent') === 'ACCEPTED'
                                ? 'btn btn-danger waves-effect waves-light mr-2'
                                : 'btn btn-success waves-effect waves-light mr-2'
                            }
                          >
                            <i className="fa fa-print"></i>
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default InvoiceDetail
